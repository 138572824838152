import { filter } from 'lodash';
import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Pagination
} from '@mui/material';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import DropDown from 'src/layouts/dashboard/DropDown';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import {
  UserListHead,
  AdminModal,
  AdminEditModal,
  AdminDetailModal,
  TerminateModal
} from '../sections/@dashboard/user';
import pallete from '../theme/palette';
import { api } from '../Api';
import { Decryption, Encryption } from 'src/functions/function';
import { chatUrl } from '../Api';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Agents() {
  const self = JSON.parse(localStorage.getItem('user'));
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: 'firstname', label: t('Agents.table.0'), alignRight: false },
    { id: 'email', label: t('Agents.table.1'), alignRight: false },
    { id: 'phone', label: t('Agents.table.2'), alignRight: false },
    { id: 'status', label: t('Agents.table.3'), alignRight: false },
    { id: 'action', label: t('Agents.table.4'), alignRight: false }
  ];
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'add', 'edit', 'terminate', 'resend access'];

  function checkRender() {
    let check = 'agents';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0].permission_types.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');

  function getUsers(page) {
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_APP));
    user?.role != 3 &&
      formdata.append('company_id', Encryption(user?.id, process.env.REACT_APP_SECRET_APP));
    search?.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));
    // filterRole?.length > 0 && filterRole != 'All' && formdata.append('role_filter', filterRole);
    // status?.length > 0 && status != 'All' && formdata.append('status_filter', status);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_agents, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          list.length = 0;
          let decryptedData = JSON.parse(Decryption(result.data));
          setlist(decryptedData);
          handleClose();
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setloadingTable(false);
      });
  }

  const handleClose = () => setOpen(false);
  useEffect(() => {
    setloading(true);
    getUsers();
  }, []);
  const [open, setOpen] = React.useState(false);
  const [user, setuser] = useState(null);

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('');

  const isUserNotFound = list.length === 0;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  const [adminModal, setadminModal] = useState(false);
  const handleAdminClose = () => setadminModal(false);

  const [adminEditModal, setEditadminModal] = useState(false);
  const handleEditAdminClose = () => {
    // setEditadminModal(!adminEditModal);
    setadminModal(!adminModal);
  };

  const refreshAdmin = () => {
    setadminModal(false);
    setEditadminModal(false);
    setterminate(false);
    getUsers(page);
    setuser(null);
  };

  const [loading, setloading] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);
  const [terminate, setterminate] = useState(false);

  const handleTerminateClose = () => setterminate(false);

  const [selectedIndex, setselectedIndex] = useState(null);
  const [deleting, setdeleting] = useState(false);

  function ActionRealtor(id, status, index) {
    setselectedIndex(index);
    setdeleting(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('realtor_id', Encryption(id, process.env.REACT_APP_SECRET_APP));
    formdata.append('status', Encryption(status, process.env.REACT_APP_SECRET_APP));
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.approveRealtor, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        console.log(result_);
        const result = JSON.parse(result_);
        if (result.success === true) {
          getUsers(page);
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setdeleting(false);
        setselectedIndex(false);
      });
  }

  const [ReasonModal, setReasonModal] = useState(false);
  const [reason, setreason] = useState('');
  const [changeRole, setChangeRole] = useState(false);
  const [currentRole, setcurrentRole] = useState('');
  const [updatedRole, setupdatedRole] = useState(null);
  const [roleLoader, setroleLoader] = useState(false);

  function ChangeRole() {
    setroleLoader(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');

    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('user_id', Encryption(user?.id, process.env.REACT_APP_SECRET_APP));

    formdata.append(
      'role_id',
      Encryption(JSON.parse(updatedRole)?.id, process.env.REACT_APP_SECRET_APP)
    );

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.user_roles, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          setupdatedRole(null);
          setChangeRole(false);
          setuser(null);
          setcurrentRole('');
          getUsers();
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setroleLoader(false);
      });
  }

  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getUsers(value);
  };

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      setloadingTable(true);
      getUsers();
    }
  };
  return (
    <Page title={`Agents | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* DETAIL MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <AdminDetailModal handleClose={handleClose} details={user} />
          </Box>
        </Modal>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Agents.name')}
          </Typography>
          {permission.includes('add') && (
            <Button
              onClick={() => {
                setuser(null);
                setadminModal(true);
              }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {t('Agents.add')}
            </Button>
          )}
        </Stack>
        {/* AGENT MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={adminModal}
          onClose={handleAdminClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="admin_modal">
            <AdminModal handleClose={handleAdminClose} refresh={refreshAdmin} data={user} />
          </Box>
        </Modal>

        {/* ADMIN Edit MODAL */}
        {/* <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={adminEditModal}
          onClose={handleEditAdminClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="admin_modal">
            <AdminEditModal data={user} handleClose={handleEditAdminClose} refresh={refreshAdmin} />
          </Box>
        </Modal> */}

        {/* TERMINATE MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={terminate}
          onClose={handleAdminClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TerminateModal handleClose={handleTerminateClose} refresh={refreshAdmin} user={user} />
          </Box>
        </Modal>
        {/* DIALOG REASON */}
        <Dialog
          open={ReasonModal}
          onClose={() => setReasonModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Termination Reason'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{reason}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setReasonModal(false)} autoFocus>
              Okay
            </Button>
          </DialogActions>
        </Dialog>
        {/* CHANGE ROLE */}
        <Dialog
          open={changeRole}
          onClose={() => setChangeRole(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle variant="h5" id="alert-dialog-title">
            {'Change Role'}
          </DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <Box style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
              <Typography>Current Role: &nbsp;</Typography>
              <Typography style={{ marginBottom: 10, fontWeight: 'bold' }}>
                {' '}
                {currentRole && JSON.parse(currentRole).role_name}
              </Typography>
            </Box>
            <DropDown
              setRole={(val) => {
                setupdatedRole(JSON.stringify(val));
              }}
              fullWidth
              dontAppend
            />
          </DialogContent>
          <DialogActions>
            {updatedRole && (
              <LoadingButton
                disabled={JSON.parse(updatedRole).id != JSON.parse(currentRole).id ? false : true}
                variant="contained"
                loading={roleLoader}
                onClick={() => ChangeRole()}
              >
                Update
              </LoadingButton>
            )}

            <Button variant="contained" onClick={() => setChangeRole(false)} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
          >
            <Searchbar
              placeholder={t('Agents.search') + '...'}
              width={'95%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              style={{ width: '5%', marginLeft: '2.5%' }}
              variant="contained"
              loading={loadingTable}
              onClick={() => {
                setloadingTable(true);
                getUsers();
              }}
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                />
                <TableBody>
                  {list.map((row, index) => {
                    const { id, firstname, lastname, avatar, email, seen, status, contact } = row;

                    const isItemSelected = selected.indexOf(firstname) !== -1;

                    return (
                      <TableRow
                        hover
                        style={{
                          backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
                          cursor: seen == '0' ? 'pointer' : 'default',
                          height: 100
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          padding="normal"
                          style={{ maxWidth: 180 }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            style={{ maxWidth: 250 }}
                          >
                            <Avatar alt={firstname} src={avatar + '?v=' + Date.now()} />
                            <Box style={{ display: 'flex', width: '90%' }}>
                              <Typography variant="subtitle2">
                                {firstname} {lastname}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 250 }}>
                          <Typography noWrap>{email}</Typography>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          {contact ? contact : 'N/A'}
                        </TableCell>

                        <TableCell align="left">
                          <Label
                            style={{ height: 30, paddingLeft: 30, paddingRight: 30, width: 100 }}
                            variant="ghost"
                            color={
                              (status === 'active' && 'success') ||
                              (status === 'terminated' && 'error') ||
                              'error'
                            }
                          >
                            <Typography
                              style={{
                                textTransform: 'capitalize',
                                fontWeight: 'bold',
                                fontSize: 12
                              }}
                            >
                              {status}
                            </Typography>
                          </Label>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            flexDirection: 'column',

                            minWidth: 150
                          }}
                        >
                          <Box m={0} p={0} style={{ flexDirection: 'column', display: 'flex' }}>
                            {status == 'active' && (
                              <>
                                {permission.includes('terminate') && (
                                  <LoadingButton
                                    startIcon={
                                      <Iconify
                                        color={pallete.primary.main}
                                        icon="ic:baseline-delete"
                                      />
                                    }
                                    style={{ marginTop: 5, width: 100 }}
                                    variant="outlined"
                                    loading={selectedIndex == index && deleting}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      ActionRealtor(id, 'deleted', index);
                                    }}
                                  >
                                    {t('Agents.buttons.0')}
                                  </LoadingButton>
                                )}
                              </>
                            )}
                          </Box>

                          <Box m={0} p={0} style={{ flexDirection: 'column', display: 'flex' }}>
                            {permission.includes('edit') && (
                              <Button
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  setuser(row);
                                  handleEditAdminClose();
                                }}
                                style={{ marginTop: 5, width: 100 }}
                                variant="outlined"
                                component={RouterLink}
                                to="#"
                                startIcon={
                                  <Iconify color={pallete.primary.main} icon="tabler:edit" />
                                }
                              >
                                {t('Agents.buttons.1')}
                              </Button>
                            )}
                            {self?.id !== id && (
                              <Button
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  //CHAT URL
                                  window.open(`${chatUrl}${self?.id}/${id}`);
                                }}
                                style={{ marginTop: 5, width: 100 }}
                                variant="outlined"
                                component={RouterLink}
                                to="#"
                                startIcon={
                                  <Iconify
                                    color={pallete.primary.main}
                                    icon="material-symbols:chat-outline"
                                  />
                                }
                              >
                                {t('Agents.buttons.2')}
                              </Button>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !loadingTable && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
