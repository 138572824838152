import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { api } from '../../../Api';
// component
import Iconify from '../../../components/Iconify';
import { Decryption, Encryption } from 'src/functions/function';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [alert, setalert] = useState({ visible: false, message: '' });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmiting, setisSubmiting] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      LoginFuction();
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const LoginFuction = () => {
    setisSubmiting(true);

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    // const raw = {
    //   email: values.email,
    //   password: values.password
    // };

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email: Encryption(values.email, process.env.REACT_APP_SECRET_KEY),
        password: Encryption(values.password, process.env.REACT_APP_SECRET_KEY)
      })
    };

    fetch(api.login, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          localStorage.setItem('token', result.data.token);
          getSideBar(result.data.token, result.path);
          localStorage.setItem('user', Decryption(result.user));
        } else {
          setalert({ visible: true, message: result.message });
          //   setalert({ visible: true, message: Object.values(result?.errors)[0][0] });
        }
      })
      .catch((error) => {
        console.log('error', error);
        setisSubmiting(false);
      })
      .finally(() => {
        setisSubmiting(false);
      });
  };

  function getSideBar(token, path) {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.sideBar, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          //    console.log(result.data, 'YE CHECK KRW');
          let decryptedData = Decryption(result.data);
          console.log(decryptedData, 'YE CHECK KRW');
          localStorage.setItem('permissions', decryptedData);
          setTimeout(() => {
            navigate(path, { replace: true });
          }, 1000);
        } else {
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setisSubmiting(false);
      });
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            className="custom-background"
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}
          <div></div>
          <Link component={RouterLink} variant="subtitle2" to="/forgot" underline="hover">
            Forgot password?
          </Link>
        </Stack>
        {alert.visible && (
          <Alert
            onClose={() => {
              setalert({ visible: false, message: '' });
            }}
            severity="error"
            style={{ fontSize: 12, marginBottom: 20 }}
          >
            {alert.message}
          </Alert>
        )}
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmiting}
        >
          Login
        </LoadingButton>
        {/* <LoadingButton
          style={{ marginTop: 10 }}
          fullWidth
          size="large"
          type="submit"
          variant="outlined"
          onClick={() => {
            navigate('/signup');
          }}
        >
          Sign up
        </LoadingButton> */}
      </Form>
    </FormikProvider>
  );
}
