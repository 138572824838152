import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import FrequencyDropDown from 'src/layouts/dashboard/FrequencyDropDown';
import TypeDropDown from 'src/layouts/dashboard/TypeDropDown';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Link,
  CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import pallete from '../theme/palette';
import format from 'date-fns/format';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { RejectOfferModal } from '../sections/@dashboard/products';
import { api } from '../Api';
import OfferDescription from '../sections/@dashboard/products/offerDescription';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'businessowner', label: 'Username', alignRight: false },
  { id: 'businessname', label: 'Business', alignRight: false },
  { id: 'offer_name', label: 'Offer', alignRight: false },
  { id: 'offer_type', label: 'Type', alignRight: false },
  { id: 'link', label: 'Link', alignRight: false },
  { id: 'action', label: 'Status', alignRight: false }
];

// ----------------------------------------------------------------------

function FormatDate(date) {
  const myArray = date.split(' ');

  const formatedDate = format(Date.parse(myArray[0]), 'MM-dd-yyyy');
  return formatedDate;
}

function offerReturn(offer) {
  if (
    (offer.offer_type === 'Percentage' || offer.offer_type === 'Amount') &&
    offer.offer_frequency !== 'Custom' &&
    offer.offer_frequency !== 'Ends On'
  ) {
    return (
      (offer.offer_type === 'Percentage'
        ? offer.offer_value + '% off'
        : '$' + offer.offer_value + ' off') +
      ' ' +
      (offer.offer_item !== '' && offer.offer_item) +
      ' ' +
      offer.offer_frequency
    );
  } else if (
    (offer.offer_type === 'Percentage' || offer.offer_type === 'Amount') &&
    offer.offer_frequency === 'Custom'
  ) {
    return (
      (offer.offer_type === 'Percentage'
        ? offer.offer_value + '% off '
        : '$' + offer.offer_value + ' off ') +
      (offer.offer_item !== '' && offer.offer_item) +
      ' Valid From ' +
      FormatDate(offer.start_date) +
      ' To ' +
      FormatDate(offer.end_date)
    );
  } else if (
    (offer.offer_type === 'Percentage' || offer.offer_type === 'Amount') &&
    offer.offer_frequency === 'Ends On'
  ) {
    return (
      (offer.offer_type === 'Percentage'
        ? offer.offer_value + '% off'
        : '$' + offer.offer_value + ' off') +
      ' ' +
      ' Until ' +
      FormatDate(offer.end_date)
    );
  } else if (
    offer.offer_type !== 'Percentage' &&
    offer.offer_type !== 'Amount' &&
    offer.offer_type !== 'Custom' &&
    offer.offer_frequency !== 'Ends On' &&
    offer.offer_frequency !== 'Custom'
  )
    return (
      offer.offer_type +
      (offer.offer_item !== '' && ' ' + offer.offer_item + ' ') +
      ' ' +
      offer.offer_frequency
    );
  else if (
    offer.offer_type !== 'Percentage' &&
    offer.offer_type !== 'Amount' &&
    offer.offer_type !== 'Custom' &&
    offer.offer_frequency === 'Ends On'
  )
    return (
      offer.offer_type +
      (offer.offer_item !== '' && ' ' + offer.offer_item + ' ') +
      ' Until ' +
      FormatDate(offer.end_date)
    );
  else if (
    offer.offer_type !== 'Percentage' &&
    offer.offer_type !== 'Amount' &&
    offer.offer_type !== 'Custom' &&
    offer.offer_frequency === 'Custom'
  )
    return (
      offer.offer_type +
      (offer.offer_item !== '' && ' ' + offer.offer_item + ' ') +
      ' Valid From ' +
      FormatDate(offer.start_date) +
      ' To ' +
      FormatDate(offer.end_date)
    );
  else if (offer.offer_type === 'Custom' && offer.offer_frequency === 'Custom')
    return (
      offer.offer_item +
      ' Valid From ' +
      FormatDate(offer.start_date) +
      ' To ' +
      FormatDate(offer.end_date)
    );
  else if (offer.offer_type === 'Custom' && offer.offer_frequency === 'Ends On')
    return offer.offer_item + ' Until ' + FormatDate(offer.end_date);
  else if (
    offer.offer_type === 'Custom' &&
    offer.offer_frequency !== 'Ends on' &&
    offer.offer_frequency !== 'Custom'
  )
    return offer.offer_item + ' ' + offer.offer_frequency;
}
export default function Offers() {
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'approve', 'decline'];
  const [loading, setloading] = useState(true);
  function checkRender() {
    let check = 'offers';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0].permission_types.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const detailClose = () => setDetail(false);

  const [detail, setDetail] = React.useState(false);
  const [details, setdetails] = useState(null);
  function openDetail(det) {
    setdetails(det);
    setDetail(true);
  }
  const [searchLoading, setsearchLoading] = useState();

  const [search, setsearch] = useState('');
  const [frequency, setfrequency] = useState('');
  const [type, settype] = useState('');
  const [count, setcount] = useState(null);
  function getBusinesses() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    search.length > 0 && formdata.append('search', search);
    type.length > 0 && type != 'All' && formdata.append('offer_type', type);
    frequency.length > 0 && frequency != 'All' && formdata.append('offer_frequency', frequency);
    if (!alloffers) {
      active && formdata.append('status_active', 'active');
      pending && formdata.append('status_pending', 'pending');
      decline && formdata.append('status_declined', 'declined');
      byme && formdata.append('approved_by_me', 1);
      online && formdata.append('online', 'online');
      instore && formdata.append('in_store', 'in_store');
    }
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.getOffers, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        setcount(result.counts);
        if (result.success === true) {
          setlist(result.data);
        } else {
          setlist([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setsearchLoading(false);
        setloading(false);
      });
  }

  useEffect(() => {
    getBusinesses();
  }, []);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isUserNotFound = list.length === 0;

  function offerApprove(id, status) {
    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    console.log('offerid', id);
    console.log('status', status);
    formdata.append('offerid', id);
    formdata.append('status', status);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.approveOffers, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          getBusinesses();
        }
      })
      .catch((error) => console.log('error', error));
  }
  const [offer, setoffer] = useState(null);
  const [rejectModal, setrejectModal] = useState(false);
  function openRejectModal(row) {
    setoffer(row);
    setrejectModal(true);
  }

  const closeReject = () => {
    setrejectModal(false);
  };

  const RejectRefresh = () => {
    setrejectModal(false);
    getBusinesses();
  };

  const Buttonstyle = {
    width: '12.5%',
    height: 50,
    marginTop: 5,
    color: 'black',
    boxShadow: 'none',
    fontSize: 14
  };

  const [alloffers, setalloffers] = useState(true);
  const [pending, setpending] = useState(false);
  const [active, setactive] = useState(false);
  const [decline, setdecline] = useState(false);
  const [byme, setbyme] = useState(false);
  const [online, setonline] = useState(false);
  const [instore, setinstore] = useState(false);

  function filterSetter(type) {
    if (type == 'all offers') {
      setalloffers(!alloffers);
      setpending(false);
      setactive(false);
      setdecline(false);
      setbyme(false);
      setonline(false);
      setinstore(false);
    } else {
      setalloffers(false);
    }
  }

  const style = {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  const refresh = () => {
    setDetail(false);
    getBusinesses();
  };

  return (
    <Page title={`Offers | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* OFFER DESCRIPTION */}
        <Modal
          style={style}
          open={detail}
          onClose={detailClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, p: 0, maxWidth: '60%', minWidth: '50%' }}>
            <OfferDescription
              handleClose={detailClose}
              details={details}
              permission={permission}
              refresh={refresh}
            />
          </Box>
        </Modal>
        {/* OFFER  */}
        <Modal style={style} open={rejectModal} onClose={closeReject}>
          <Box sx={boxStyle}>
            <RejectOfferModal handleClose={closeReject} details={offer} refresh={RejectRefresh} />
            {/* <SendNotification handleClose={handleClose} userid={userid} /> */}
          </Box>
        </Modal>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Offers
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New User
          </Button> */}
        </Stack>

        <Box
          pb={2}
          style={{
            flexDirection: 'row',
            display: 'flex',
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
        >
          <Button
            onClick={() => {
              filterSetter('all offers');
              setalloffers(!alloffers);
            }}
            variant="contained"
            component={RouterLink}
            to="#"
            style={{
              ...Buttonstyle,
              backgroundColor: alloffers ? pallete.primary.main : 'silver'
            }}
          >
            All Offers ({count?.count_all_offer})
          </Button>
          <Button
            onClick={() => {
              filterSetter('pending');
              setpending(!pending);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: pending ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Pending ({count?.count_pending_offer})
          </Button>
          <Button
            onClick={() => {
              filterSetter('active');
              setactive(!active);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: active ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Active ({count?.count_active_offer})
          </Button>
          <Button
            onClick={() => {
              filterSetter('delete');
              setdecline(!decline);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: decline ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Declined ({count?.count_declined_offer})
          </Button>
          {/* <Button
            onClick={() => {
              filterSetter('online');
              setonline(!online);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: online ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Online ({count?.count_online_businessess})
          </Button> */}
          {/* <Button
            onClick={() => {
              filterSetter('star');
              setstar(!star);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: star ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            4 Stars or under ({count?.count_rating_businessess})
          </Button> */}
          <Button
            onClick={() => {
              filterSetter('online');
              setonline(!online);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: online ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Online ({count?.count_online_offer})
          </Button>
          <Button
            onClick={() => {
              filterSetter('instore');
              setinstore(!instore);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: instore ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            In-Store ({count?.count_in_store_offer})
          </Button>
          <Button
            onClick={() => {
              filterSetter('byme');
              setbyme(!byme);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: byme ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            By me ({count?.count_approved_me_offer})
          </Button>
        </Box>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between'
            }}
          >
            <Searchbar
              width={'44%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
            />
            <TypeDropDown
              setFunction={(val) => {
                settype(val.name);
              }}
              width={'24%'}
            />
            <FrequencyDropDown
              setFunction={(val) => {
                setfrequency(val.name);
              }}
              width={'24%'}
            />
            <LoadingButton
              style={{ width: '4%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                getBusinesses();
              }}
            >
              <Iconify icon="material-symbols:search" sx={{ width: 30, height: 30 }} />
            </LoadingButton>
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                <TableBody>
                  {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, status, user, link, businessowner, businessname, seen } = row;

                    const name = row.offer_name;

                    return (
                      <TableRow
                        onClick={() => {}}
                        style={{
                          backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
                          cursor: seen == '0' ? 'pointer' : 'default'
                        }}
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell
                          style={{ maxWidth: 200 }}
                          component="th"
                          scope="row"
                          padding="normal"
                        >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={user.avatar} />
                            <Typography
                              style={{ width: '60%' }}
                              variant="subtitle2"
                              flexWrap={'wrap'}
                            >
                              {businessowner}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell
                          style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                          component="th"
                          scope="row"
                          padding="normal"
                        >
                          <Typography variant="subtitle2">{businessname}</Typography>
                        </TableCell>
                        <TableCell
                          style={{ width: '15%' }}
                          component="th"
                          scope="row"
                          padding="normal"
                        >
                          <Typography variant="subtitle2" wrap="true">
                            {offerReturn(row)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{ minWidth: 100 }}
                          component="th"
                          scope="row"
                          padding="normal"
                        >
                          <Typography variant="subtitle2" wrap="true">
                            {name}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="normal">
                          {link === '' && 'N/A'}{' '}
                          {link !== '' && (
                            <Link
                              style={{ width: 130, flexWrap: 'wrap', display: 'flex' }}
                              align="left"
                              onClick={() =>
                                window.open(
                                  link.includes('https://', 'HTTPS://', 'http://', 'HTTP://')
                                    ? link
                                    : 'https://' + link,
                                  ''
                                )
                              }
                            >
                              <Typography style={{ width: 150 }} variant="subtitle2" noWrap>
                                {link}
                              </Typography>
                            </Link>
                          )}
                        </TableCell>

                        <TableCell style={{ position: 'relative' }}>
                          <div
                            style={{
                              position: 'absolute',
                              backgroundColor:
                                status === 'active'
                                  ? 'green'
                                  : status === 'pending'
                                  ? pallete.primary.main
                                  : 'red',
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingBottom: 2,
                              paddingTop: 2,
                              top: 0,
                              right: 0,
                              borderBottomLeftRadius: 10,
                              color: 'white',
                              textTransform: 'capitalize'
                            }}
                          >
                            {status}
                          </div>
                          <Box
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              display: 'flex',
                              minHeight: 100
                            }}
                          >
                            {status === 'pending' && permission.includes('decline') && (
                              <>
                                {permission.includes('decline') && (
                                  <Button
                                    onClick={() => openRejectModal(id)}
                                    variant="outlined"
                                    size="small"
                                    color={'error'}
                                    component={RouterLink}
                                    to="#"
                                    style={{ marginRight: 5 }}
                                  >
                                    Decline
                                  </Button>
                                )}
                                {permission.includes('approve') && (
                                  <Button
                                    onClick={() => offerApprove(id, 'active')}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    component={RouterLink}
                                    to="#"
                                  >
                                    Approve
                                  </Button>
                                )}
                                <Iconify
                                  onClick={() => openDetail(row)}
                                  style={{ marginLeft: 5 }}
                                  color={pallete.primary.main}
                                  icon="bi:info-square-fill"
                                  width={30}
                                  height={30}
                                />
                              </>
                            )}
                            {status === 'active' && (
                              <>
                                <Label style={{ height: 31 }} variant="ghost" color="success">
                                  Approved
                                </Label>
                                {permission.includes('decline') && (
                                  <Button
                                    onClick={() => openRejectModal(id)}
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    component={RouterLink}
                                    to="#"
                                    style={{ marginLeft: 5 }}
                                  >
                                    Decline
                                  </Button>
                                )}
                                <Iconify
                                  onClick={() => openDetail(row)}
                                  style={{ marginLeft: 5 }}
                                  color={pallete.primary.main}
                                  icon="bi:info-square-fill"
                                  width={30}
                                  height={30}
                                />
                              </>
                            )}
                            {status === 'declined' && (
                              <>
                                <Label style={{ height: 31 }} variant="ghost" color={'error'}>
                                  Declined
                                </Label>
                                <Iconify
                                  onClick={() => openDetail(row)}
                                  style={{ marginLeft: 5 }}
                                  color={pallete.primary.main}
                                  icon="bi:info-square-fill"
                                  width={30}
                                  height={30}
                                />
                              </>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
