import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Checkbox,
  Typography,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { api } from 'src/Api';
import DropDown from 'src/layouts/dashboard/DropDown';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AdminModal({ handleClose, refresh, data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [checkBox, setcheckBox] = useState(false);
  const [checkerror, setcheckerror] = useState(false);
  const [loading, setloading] = useState(false);
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required(t('AddAgent.required.0')),
    lastName: Yup.string().required(t('AddAgent.required.1')),
    email: Yup.string().email(t('AddAgent.required.5')).required(t('AddAgent.required.2')),
    password: Yup.string().required(t('AddAgent.required.3')),
    confirmpassword: Yup.string().required(t('AddAgent.required.4'))
  });

  const formik = useFormik({
    initialValues: {
      firstName: data?.firstname,
      lastName: data?.lastname,
      email: data?.email,
      password: '',
      confirmpassword: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => AddAdmin()
  });
  useEffect(() => {
    if (checkBox === true) {
      setcheckerror(false);
    }
  }, [checkBox]);
  //  navigate('/dashboard', { replace: true });

  const [roleId, setroleId] = useState(null);
  // function AddAdmin() {
  //   setloading(true);
  //   if (roleId == null) {
  //     setcheckerror(true);
  //     setloading(false);
  //     return;
  //   }
  //   // if (checkBox === false) {
  //   //   setcheckerror(true);
  //   //   setloading(false);
  //   //   return;
  //   // }
  //   if (formik.values.password !== formik.values.confirmpassword) {
  //     setalert({ visible: true, message: 'Password and Confirm Password Mismatched' });
  //     setloading(false);
  //     return;
  //   }
  //   var myHeaders = new Headers();
  //   const token = localStorage.getItem('token');
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   myHeaders.append('Authorization', 'Bearer ' + token);
  //   var formdata = new FormData();
  //   formdata.append('role', 1);
  //   formdata.append('firstname', formik.values.firstName);
  //   formdata.append('lastname', formik.values.lastName);
  //   formdata.append('email', formik.values.email);
  //   formdata.append('password', formik.values.password);
  //   formdata.append('confirm_password', formik.values.confirmpassword);
  //   formdata.append('company_id', user.id);

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: 'follow'
  //   };

  //   fetch(api.createAgent, requestOptions)
  //     .then((response) => response.text())
  //     .then((result_) => {
  //       const result = JSON.parse(result_);

  //       if (result.success === true) {
  //         refresh();
  //         //   RoleAssign(result.user.id);
  //       } else {
  //         setalert({ visible: true, message: result.errors.email[0] });
  //       }
  //     })
  //     .catch((error) => {
  //       setloading(false);
  //       console.log('error', error);
  //     })
  //     .finally(() => {
  //       setloading(false);
  //     });
  // }

  // function RoleAssign(userid) {
  //   var myHeaders = new Headers();
  //   const token = localStorage.getItem('token');
  //   myHeaders.append('Authorization', 'Bearer ' + token);
  //   var formdata = new FormData();
  //   // formdata.append('user_id', userid);
  //   // formdata.append('role_id', roleId);

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: 'follow'
  //   };

  //   fetch(api.assignRole, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.success == true) {
  //         refresh();
  //       } else {
  //         console.log(result);
  //       }
  //     })
  //     .catch((error) => console.log('error', error))
  //     .finally(() => {
  //       setloading(false);
  //     });
  // }

  function AddAdmin() {
    // if (documentType === null) {
    //   setalert({ visible: true, message: 'Document type is required' });
    //   return;
    // }
    // if (file === null) {
    //   setalert({ visible: true, message: 'Please select document to upload' });
    //   return;
    // }
    var myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', 'Bearer ' + token);
    var formdata = new FormData();
    data && formdata.append('id', Encryption(data?.id, process.env.REACT_APP_SECRET_KEY));
    formdata.append('role', Encryption(1, process.env.REACT_APP_SECRET_KEY));
    formdata.append(
      'firstname',
      Encryption(formik.values.firstName, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append(
      'lastname',
      Encryption(formik.values.lastName, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append('email', Encryption(formik.values.email, process.env.REACT_APP_SECRET_KEY));
    if (data == null) {
      formdata.append(
        'password',
        Encryption(formik.values.password, process.env.REACT_APP_SECRET_KEY)
      );
      formdata.append(
        'confirm_password',
        Encryption(formik.values.confirmpassword, process.env.REACT_APP_SECRET_KEY)
      );
    }

    formdata.append('company_id', Encryption(user.id, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(data ? api.company_realtor_edit : api.createAgent, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);

        if (result.success === true) {
          refresh();
        } else {
          setalert({ visible: true, message: result.errors });
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error', error);
      })
      .finally(() => setloading(false));
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const passwordGenerator = () => {
    const result = (Math.random() + 1).toString(36).substring(0);
    // alert(result);
    formik.values.password = result;
    formik.values.confirmpassword = result;
    formik.validateField();
  };
  const label = { inputProps: { 'aria-label': 'Master Admin Rights' } };
  const [alert, setalert] = useState({ visible: false, message: '' });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('AddAgent.fields.0')}
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label={t('AddAgent.fields.1')}
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t('AddAgent.fields.2')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          {!data && (
            <>
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={t('AddAgent.fields.3')}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextField
                value={formik.values.confirmpassword}
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                label={t('AddAgent.fields.4')}
                {...getFieldProps('confirmpassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setshowConfirmPassword((prev) => !prev)}
                      >
                        <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.confirmpassword && errors.confirmpassword)}
                helperText={touched.confirmpassword && errors.confirmpassword}
              />
              <Button
                sx={{
                  marginRight: 1
                }}
                onClick={passwordGenerator}
                color="primary"
                variant="outlined"
              >
                {t('AddAgent.buttons.0')}
              </Button>
            </>
          )}

          {alert.visible && (
            <>
              <Alert
                onClose={() => {
                  setalert({ visible: false, message: '' });
                }}
                severity="error"
              >
                {Object.values(alert?.message)[0][0]}
                {/* {alert.message} */}
                {/* {Object.entries(result.errors).map(([key, value]) => (
                  <ListItem key={key}>
                    <ListItemText primary={value[0]} />
                  </ListItem>
                ))} */}
              </Alert>
            </>
          )}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <LoadingButton
              sx={{ marginRight: 2 }}
              size="large"
              onClick={() => AddAdmin()}
              variant="contained"
              loading={loading}
            >
              {t('AddAgent.buttons.1')}
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined">
              {t('AddAgent.buttons.2')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
