import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

// components
import Page from '../components/Page';
import StatusDropDown from 'src/layouts/dashboard/StatusDropDown';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserModal } from '../sections/@dashboard/user';
//
import pallete from '../theme/palette';
import { api } from '../Api';
import { Decryption, Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import { chatUrl } from '../Api';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function User() {
  const self = JSON.parse(localStorage.getItem('user'));
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'firstname', label: t('Company.table.0'), alignRight: false },
    { id: 'email', label: t('Company.table.1'), alignRight: false },
    { id: 'contact', label: t('Company.table.2'), alignRight: false },
    { id: 'address', label: t('Company.table.3'), alignRight: false },
    { id: 'num_of_realtors', label: t('Company.table.4'), alignRight: false },
    { id: 'action', label: t('Company.table.5'), alignRight: false }
  ];

  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'upload', 'approve', 'decline', 'edit'];

  function checkRender() {
    let check = 'companies';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );

    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [status, setstatus] = useState(null);
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);

  function getCompany() {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    search.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_KEY));
    status &&
      status != 'All' &&
      formdata.append('status', Encryption(status, process.env.REACT_APP_SECRET_KEY));
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.getCompany, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          list.length = 0;
          let decryptedData = JSON.parse(Decryption(result.data));
          setlist(decryptedData);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    getCompany();
  }, []);
  const [open, setOpen] = React.useState(false);
  const [user, setuser] = useState(null);

  function openModal(user) {
    setuser(user);
    handleOpen();
  }

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (event, property) => {
    if (property !== 'action') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isUserNotFound = list.length === 0;
  const style = {
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main,
    overflow: 'hidden'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  //FILTERS

  function refresh() {
    getCompany();
    handleClose();
  }

  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getCompany(value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setsearchLoading(true);
      setPage(1);
      getCompany(1);
    }
  };
  return (
    <Page title={`Companies | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Modal
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, style }} className="admin_modal_pa">
            <UserModal
              handleClose={handleClose}
              details={user}
              permission={permission}
              refresh={refresh}
            />
          </Box>
        </Modal>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Company.name')}
          </Typography>
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
            className="top_column"
          >
            <Searchbar
              placeholder={t('Company.search') + '...'}
              width={'50%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />
            <StatusDropDown
              placeholder={t('Company.status')}
              type={'user'}
              width={'25%'}
              setFunction={(val) => {
                setstatus(val);
              }}
            />
            <LoadingButton
              endIcon={<Iconify icon="material-symbols:search" />}
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getCompany(1);
              }}
              className="width_full_status"
            />
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {list.map((row) => {
                    const {
                      id,
                      firstname,
                      lastname,
                      address,
                      country,
                      contact,
                      avatar,
                      email,
                      seen,
                      num_of_realtors,
                      allowed_realtors,
                      total_realtors_count,
                      active_realtors_count
                    } = row;

                    const isItemSelected = selected.indexOf(firstname) !== -1;

                    return (
                      <TableRow
                        hover
                        onClick={() => {}}
                        style={{
                          backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
                          cursor: seen == '0' ? 'pointer' : 'default'
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell scope="row" padding="normal" style={{ width: 150 }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            style={{ width: 150 }}
                          >
                            <Avatar alt={firstname} src={avatar + '?v=' + Date.now()} />
                            <Box style={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                              <Typography variant="subtitle2" flexWrap={'wrap'}>
                                {firstname} {lastname}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 150 }}>
                          <Typography noWrap>{email}</Typography>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          {contact ? contact : 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 200 }}>
                          {address !== '' ? address : 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 300 }}>
                          <Typography> No of Agents : {num_of_realtors}</Typography>
                          <Typography> Allowed Agent : {allowed_realtors}</Typography>
                          <Typography> Total Agents : {total_realtors_count}</Typography>
                          <Typography> Active Agent : {active_realtors_count}</Typography>
                        </TableCell>

                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          <Button
                            size="small"
                            onClick={() => openModal(row)}
                            style={{ justifyContent: 'flex-start', marginTop: 5, width: 85 }}
                            variant="outlined"
                            component={RouterLink}
                            to="#"
                            startIcon={
                              <Iconify color={pallete.primary.main} icon="bi:info-square-fill" />
                            }
                          >
                            {t('Company.buttons.0')}
                          </Button>
                          {self?.id !== id && (
                            <Button
                              size="small"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                //CHAT URL
                                window.open(`${chatUrl}${self?.id}/${id}`);
                              }}
                              style={{
                                justifyContent: 'flex-start',
                                marginTop: 5,
                                width: 85
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={
                                <Iconify
                                  color={pallete.primary.main}
                                  icon="material-symbols:chat-outline"
                                />
                              }
                            >
                              {t('Agents.buttons.2')}
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
