import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import { api } from 'src/Api';

export default function DropDown({ setRole, fullWidth, error, dontAppend }) {
  const [roles, setroles] = useState([]);
  const [reasondrop, setReasondrop] = React.useState(null);

  function getRoles() {
    const myHeaders = new Headers();

    const token = localStorage.getItem('token');

    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };
    console.log('fetching');
    fetch(api.getRoleNames, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          dontAppend
            ? setroles(result.data)
            : setroles([{ id: 0, role_name: 'All' }, ...result.data]);
        }
      })
      .catch((error) => console.log('error', error));
  }

  const handleChange = (event) => {
    setRole(event.target.value);
    setReasondrop(event.target.value);
  };

  useEffect(() => {
    getRoles();
  }, []);
  
  return (
    <TextField
      error={error}
      color={error ? 'error' : 'primary'}
      fullWidth
      style={{ width: !fullWidth && '20%', marginLeft: !fullWidth && 15 }}
      select
      value={reasondrop}
      label={'Role'}
      onChange={handleChange}
    >
      {roles.map((option) => (
        <MenuItem key={option.id} value={option}>
          {option.role_name}
        </MenuItem>
      ))}
    </TextField>
  );
}
