import { filter } from 'lodash';
import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import CityDropdown from 'src/layouts/dashboard/CityDropdown';

import { LoadingButton } from '@mui/lab';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  CircularProgress,
  Link
} from '@mui/material';
import pallete from '../theme/palette';
import format from 'date-fns/format';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
//

import { api } from '../Api';
import PostDescription from '../sections/@dashboard/posts/PostDescription';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user', label: 'Username', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'tags', label: 'Tags', alignRight: false },
  { id: 'action', label: 'Status', alignRight: false }
];

// ----------------------------------------------------------------------

export default function Posts() {
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'approve', 'decline'];
  const [loading, setloading] = useState(true);
  function checkRender() {
    let check = 'i want';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0].permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [searchLoading, setsearchLoading] = useState(false);
  const detailClose = (check) => {
    check && getPosts();
    setDetail(false);
  };

  const [detail, setDetail] = React.useState(false);
  const [details, setdetails] = useState(null);
  function openDetail(det) {
    setdetails(det);
    setDetail(true);
  }

  function getPosts() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('post_type', 'admin');
    search.length > 0 && formdata.append('search', search);
    city && formdata.append('city', city);
    if (!allIwant) {
      active && formdata.append('status_active', 'active');
      pending && formdata.append('status_pending', 'pending');
      decline && formdata.append('status_declined', 'declined');
      deleted && formdata.append('status_deleted', 'deleted');
      expire && formdata.append('status_expired', 'expired');
      urgent && formdata.append('urgent', '1');
      byme && formdata.append('approved_by_me', 1);
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.getPosts, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        setcount(result.counts);
        if (result.success === true) {
          setlist(result.data);
        } else {
          setlist([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  useEffect(() => {
    getPosts();
  }, []);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (event, property) => {
    if (property !== 'action') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isUserNotFound = list.length === 0;
  const style = {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  function postApprove(id, status) {
    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    console.log('offerid', id);
    console.log('status', status);
    formdata.append('id', id);
    formdata.append('status', status);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.postApprove, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        console.log(result_);
        const result = JSON.parse(result_);
        if (result.success === true) {
          getPosts();
        }
      })
      .catch((error) => console.log('error', error));
  }

  const [count, setcount] = useState(null);
  const [city, setcity] = useState(null);
  const [allIwant, setallIwant] = useState(true);
  const [active, setactive] = useState(false);
  const [pending, setpending] = useState(false);
  const [expire, setexpire] = useState(false);
  const [deleted, setdeleted] = useState(false);
  const [decline, setdecline] = useState(false);

  const [byme, setbyme] = useState(false);
  const [urgent, seturgent] = useState(false);

  function filterSetter(type) {
    if (type == 'all offers') {
      setallIwant(!allIwant);
      setpending(false);
      setactive(false);
      setexpire(false);
      setbyme(false);
      seturgent(false);
      setdeleted(false);
      setdecline(false);
    } else {
      setallIwant(false);
    }
  }

  const Buttonstyle = {
    width: '13%',
    height: 50,
    marginTop: 5,
    color: 'black',
    boxShadow: 'none',
    fontSize: 13
  };

  return (
    <Page title={`I Wants | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* I WANT DESCRIPTION */}
        <Modal
          style={style}
          open={detail}
          onClose={detailClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, p: 0, maxWidth: '60%', minWidth: '50%' }}>
            <PostDescription
              handleClose={detailClose}
              details={details}
              permission={permission}
              refresh={() => {
                detailClose(true);
              }}
            />
          </Box>
        </Modal>
        {/* I WANT DESCRIPTION END */}

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            I Wants
          </Typography>
        </Stack>
        <Box
          pb={2}
          style={{
            flexDirection: 'row',
            display: 'flex',
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
        >
          <Button
            onClick={() => {
              filterSetter('all offers');
              setallIwant(!allIwant);
            }}
            variant="contained"
            component={RouterLink}
            to="#"
            style={{
              ...Buttonstyle,
              backgroundColor: allIwant ? pallete.primary.main : 'silver'
            }}
          >
            All I Want ({count?.count_all_posts})
          </Button>
          <Button
            onClick={() => {
              filterSetter('active');
              setactive(!active);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: active ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Active ({count?.count_active_posts})
          </Button>
          <Button
            onClick={() => {
              filterSetter('pending');
              setpending(!pending);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: pending ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Pending ({count?.count_pending_posts})
          </Button>
          <Button
            onClick={() => {
              filterSetter('decline');
              setdecline(!decline);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: decline ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Declined ({count?.count_declined_posts})
          </Button>

          <Button
            onClick={() => {
              filterSetter('delete');
              setexpire(!expire);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: expire ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Expired ({count?.count_expired_posts})
          </Button>

          <Button
            onClick={() => {
              filterSetter('urgent');
              seturgent(!urgent);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: urgent ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Urgent ({count?.count_urgent_posts})
          </Button>
          <Button
            onClick={() => {
              filterSetter('byme');
              setbyme(!byme);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: byme ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            By me ({count?.count_approved_me_posts})
          </Button>
        </Box>
        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between'
            }}
          >
            <Searchbar
              width={'50%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
            />
            <CityDropdown
              setFunction={(val) => {
                setcity(val);
              }}
              Width={'30%'}
              dontAppend
            />

            <LoadingButton
              endIcon={<Iconify icon="material-symbols:search" />}
              style={{ width: '17%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                getPosts();
              }}
            >
              Search
            </LoadingButton>
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                />
                <TableBody>
                  {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, status, link, businessowner, title, description, seen } = row;
                    const user = row.user[0];
                    const tags = row.tags !== '' ? JSON.parse(row.tags).tagsArray : [];
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        onClick={() => {}}
                        style={{
                          backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
                          cursor: seen == '0' ? 'pointer' : 'default'
                        }}
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          padding="normal"
                          style={{ width: '15%' }}
                        >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={user.firstname} src={user.avatar} />
                            <Typography
                              style={{ width: 120 }}
                              variant="subtitle2"
                              flexWrap={'wrap'}
                            >
                              {user.firstname + ' ' + user.lastname}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            width: '15%',
                            textTransform: 'capitalize'
                          }}
                          component="th"
                          scope="row"
                          padding="normal"
                        >
                          <Typography variant="subtitle2" noWrap>
                            {title}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{ width: '20%' }}
                          component="th"
                          scope="row"
                          padding="normal"
                        >
                          <Typography variant="subtitle2" wrap="true">
                            {description}
                          </Typography>
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          padding="normal"
                          style={{ width: '20%' }}
                        >
                          {tags?.map((item, index) => {
                            return (
                              <Label
                                key={index}
                                style={{
                                  height: 31,
                                  marginTop: 2,
                                  marginRight: 3,
                                  backgroundColor: '#FDD96E',
                                  borderWidth: 3,
                                  color: 'black'
                                }}
                                variant="ghost"
                                //  color="success"
                              >
                                {item}
                              </Label>
                            );
                          })}
                          {tags.length == 0 && 'N/A'}
                        </TableCell>

                        <TableCell style={{ position: 'relative', minHeight: 400 }}>
                          <div
                            style={{
                              position: 'absolute',
                              backgroundColor:
                                status === 'active'
                                  ? 'green'
                                  : status === 'pending'
                                  ? pallete.primary.main
                                  : 'red',
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingBottom: 2,
                              paddingTop: 2,
                              top: 0,
                              right: 0,
                              borderBottomLeftRadius: 10,
                              color: 'white',
                              textTransform: 'capitalize'
                            }}
                          >
                            {status}
                          </div>
                          <Box
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              display: 'flex',
                              minHeight: 80
                            }}
                          >
                            {status === 'pending' && (
                              <>
                                {permission.includes('decline') && (
                                  <Button
                                    onClick={() => postApprove(id, 'declined')}
                                    variant="outlined"
                                    size="small"
                                    color={'error'}
                                    component={RouterLink}
                                    to="#"
                                    style={{ marginRight: 5 }}
                                  >
                                    Decline
                                  </Button>
                                )}
                                {permission.includes('approve') && (
                                  <Button
                                    onClick={() => postApprove(id, 'active')}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    component={RouterLink}
                                    to="#"
                                  >
                                    Approve
                                  </Button>
                                )}

                                <Iconify
                                  onClick={() => openDetail(row)}
                                  style={{ marginLeft: 5 }}
                                  color={pallete.primary.main}
                                  icon="bi:info-square-fill"
                                  width={30}
                                  height={30}
                                />
                              </>
                            )}
                            {status === 'active' && (
                              <>
                                <Label style={{ height: 31 }} variant="ghost" color="success">
                                  Approved
                                </Label>
                                {permission.includes('decline') && (
                                  <Button
                                    onClick={() => postApprove(id, 'declined')}
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    component={RouterLink}
                                    to="#"
                                    style={{ marginLeft: 5 }}
                                  >
                                    Decline
                                  </Button>
                                )}

                                <Iconify
                                  onClick={() => openDetail(row)}
                                  style={{ marginLeft: 5 }}
                                  color={pallete.primary.main}
                                  icon="bi:info-square-fill"
                                  width={30}
                                  height={30}
                                />
                              </>
                            )}
                            {status === 'declined' && (
                              <>
                                <Label style={{ height: 31 }} variant="ghost" color={'error'}>
                                  Declined
                                </Label>
                                <Iconify
                                  onClick={() => openDetail(row)}
                                  style={{ marginLeft: 5 }}
                                  color={pallete.primary.main}
                                  icon="bi:info-square-fill"
                                  width={30}
                                  height={30}
                                />
                              </>
                            )}
                            {status === 'expired' && (
                              <>
                                <Label style={{ height: 31 }} variant="ghost" color={'error'}>
                                  Expired
                                </Label>
                                <Iconify
                                  onClick={() => openDetail(row)}
                                  style={{ marginLeft: 5 }}
                                  color={pallete.primary.main}
                                  icon="bi:info-square-fill"
                                  width={30}
                                  height={30}
                                />
                              </>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
