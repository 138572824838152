// material
import { useState, useEffect } from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';

// components
import Page from '../components/Page';
import { TotalBusinesses } from '../sections/@dashboard/app';
import { useTranslation } from 'react-i18next';

import { api } from 'src/Api';
import { Decryption } from 'src/functions/function';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [list, setlist] = useState(false);
  // const [loading, setloading] = useState(true);
  const { t } = useTranslation();
  function getList() {
    const myHeaders = new Headers();
    console.log('hitting');
    const token = localStorage.getItem('token');

    myHeaders.append('Authorization', `Bearer ${token}`);

    // var formdata = new FormData();

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      // body: formdata,
      redirect: 'follow'
    };

    fetch(api.getDashboard, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        //  setloading(false);
        const result = JSON.parse(result_);
        let decryptedata = JSON.parse(Decryption(result.data));
        console.log(decryptedata, 'YE DKHO');
        setlist(decryptedata);
      })
      .catch((error) => {
        // setloading(false);
        console.log('error', error);
      });
  }
  useEffect(() => {
    getList();
  }, []);
  return (
    <Page title={`Dashboard | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container maxWidth="xl">
        <Box
          sx={{ pb: 5 }}
          onClick={() => {
            // if (localStorage.getItem('language') == 'en') {
            //   i18n.changeLanguage('zh');
            //   localStorage.setItem('language', 'zh');
            // } else {
            //   i18n.changeLanguage('en');
            //   localStorage.setItem('language', 'en');
            // }
          }}
        >
          <Typography variant="h4">{t('dashboard.welcome')}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Typography p={2} variant="h4">
            {t('dashboard.dashboard')}
          </Typography>

          {/* {list && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <AppTotalUsers total={list} />
            </Grid>
          )} */}
          {/* <Typography p={2} variant="h4">
            Properties:
          </Typography> */}
          <Grid item xs={12} sm={12} md={12}>
            <TotalBusinesses total={list} />
          </Grid>
          {/* <Typography p={2} variant="h4">
            Business Offers:
          </Typography>
          <Grid item xs={12} sm={12} md={12}>
            <TotalOffers total={list} />
          </Grid> */}
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid> */}

        {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid> */}

        {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
      </Container>
    </Page>
  );
}
