import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Businesses from './pages/Properties';
import Offers from './pages/Offers';
import Admin from './pages/Agents';
import Rolepermission from './pages/Rolepermission';
import User from './pages/User';
import NotFound from './pages/Page404';
import Requests from './pages/Inquiry';
import Reports from './pages/Reports';
import Documents from './pages/Documents';
import HelpCenter from './pages/HelpCenter';
import Posts from './pages/Posts';
import Company from './pages/Company';
import Contact from './pages/Contact';
import Forgot from './pages/Forgot';
import ChangePassword from './pages/ChangePassword';

// ----------------------------------------------------------------------

export default function Router() {
  const localPerm = JSON.parse(localStorage.getItem('permissions'));

  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'app', element: <Navigate to="/dashboard/app" /> },
        { path: '/', element: <Navigate to="/login" /> },

        { path: 'login', element: <Login /> },
        // { path: 'signup', element: <Register /> },
        { path: 'forgot', element: <Forgot /> },
        { path: 'changePassword', element: <ChangePassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'user',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'users')?.length >
            0 ? (
              <User />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'companies',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'companies')?.length >
            0 ? (
              <Company />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'app',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'dashboard')?.length >
            0 ? (
              <DashboardApp />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'properties',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'properties')
              ?.length > 0 ? (
              <Businesses />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'roles',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'role/permission')
              ?.length > 0 ? (
              <Rolepermission />
            ) : (
              <Navigate to="/404" />
            )
        },
        // { path: 'roles', element: <Rolepermission /> },
        {
          path: 'offers',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'offers')?.length >
            0 ? (
              <Offers />
            ) : (
              <Navigate to="/404" />
            )
        },
        // { path: 'offers', element: <Offers /> },
        {
          path: 'posts',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'i want')?.length >
            0 ? (
              <Posts />
            ) : (
              <Navigate to="/404" />
            )
        },
        // { path: 'posts', element: <Posts /> },
        {
          path: 'agents',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'agents')?.length >
            0 ? (
              <Admin />
            ) : (
              <Navigate to="/404" />
            )
        },
        // { path: 'posts', element: <Posts /> },
        {
          path: 'register-buyer',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'register buyer')
              ?.length > 0 ? (
              <Contact />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'enquiry',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'enquiry')?.length >
            0 ? (
              <Requests />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'reports',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'reports')?.length >
            0 ? (
              <Reports />
            ) : (
              <Navigate to="/404" />
            )
        },
        // { path: 'requests', element: <Requests /> },
        {
          path: 'documents',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'documents')?.length >
            0 ? (
              <Documents />
            ) : (
              <Navigate to="/404" />
            )
        },
        // { path: 'documents', element: <Documents /> },
        {
          path: 'helpcenter',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'help center')
              ?.length > 0 ? (
              <HelpCenter />
            ) : (
              <Navigate to="/404" />
            )
        }

        // { path: 'helpcenter', element: <HelpCenter /> }

        // { path: 'blog', element: <Blog /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
