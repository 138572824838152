import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Avatar,
  MenuItem,
  Link
} from '@mui/material';

import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink } from 'react-router-dom';
import { api } from 'src/Api';

export default function RejectModal({ handleClose, details, refresh }) {
  const [reasonalert, setreasonalert] = useState({
    visible: true,
    message: 'Please provide reason for declining this Offer.'
  });

  const [loading, setloading] = useState(false);

  function BusinessReject(offerid) {
    if (reasonalert.visible === true) {
      //   if (reasondrop === null || reasondrop === '') {
      //     setreqError(true);
      //     return;
      //   }
      const token = localStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${token}`);
      var formdata = new FormData();
      formdata.append('offerid', offerid);
      formdata.append('status', 'declined');
      formdata.append('reason', '');
      formdata.append('comment', reason);
      console.log(formdata.get('offerid'));
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(api.approveOffers, requestOptions)
        .then((response) => response.text())
        .then((result_) => {
          setloading(false);
          const result = JSON.parse(result_);
          console.log(result);
          if (result.success === true) {
            refresh();
          }
        })
        .catch((error) => {
          setloading(false);
          console.log('error', error);
        });
    }
  }

  const currencies = [
    {
      id: '1',
      name: 'you haven’t provided evidence of ownership for the right to represent the business'
    },
    {
      id: '2',
      name: 'your business has lower than four stars rating on a major platforms'
    },
    {
      id: '3',
      name: 'The offer that you provided was not exclusive and we found lower offers that you are providing elsewhere'
    },
    {
      id: '4',
      name: 'your payment was the client'
    }
  ];
  const [reasondrop, setReasondrop] = React.useState('');

  const handleChange = (event) => {
    setReasondrop(event.target.value);
  };
  const [reason, setReason] = useState('');
  const [reqError, setreqError] = useState(false);
  return (
    <Box>
      <Box p={1}>
        {reasonalert.visible && (
          <>
            <Alert severity="warning">{reasonalert.message}</Alert>
            <Box style={{ marginTop: 15 }}>
              {/* <TextField
                style={{ width: '100%', marginBottom: 10 }}
                id="outlined-select-currency"
                select
                label="Reason *"
                color={reqError ? 'error' : 'primary'}
                value={reasondrop}
                onChange={handleChange}
                error={reqError}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField> */}
              <TextField
                style={{ width: '100%', marginTop: 10 }}
                id="outlined-multiline-flexible"
                label="Comments"
                multiline
                color="primary"
                maxRows={4}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Box>
          </>
        )}
        <Box pt={1.5} display="flex" justifyContent="flex-end" flexDirection="row">
          <LoadingButton
            style={{ width: 40, marginRight: 5 }}
            color="error"
            size="small"
            variant="contained"
            component={RouterLink}
            to="#"
            onClick={() => BusinessReject(details)}
            loading={loading}
          >
            Decline
          </LoadingButton>
          <Button
            style={{ marginRight: 10 }}
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
