import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Button, Box, Typography, Alert } from '@mui/material';

import { LoadingButton } from '@mui/lab';
// component
import { api } from 'src/Api';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function DocumentModal({ handleClose, refresh, data }) {
  const { t } = useTranslation();
  const [file, setfile] = useState(null);
  const [documentType, setdocumentType] = useState(null);
  const [loading, setloading] = useState(false);

  function AddDocument() {
    if (file === null) {
      setalert({ visible: true, message: 'Please select document to upload' });
      return;
    }
    var myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    myHeaders.append('Authorization', 'Bearer ' + token);
    var formdata = new FormData();
    data && formdata.append('id', Encryption(data?.id, process.env.REACT_APP_SECRET_KEY));
    formdata.append(
      'name',
      Encryption(documentType, process.env.REACT_APP_SECRET_KEY) ??
        Encryption(file?.name, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append('attachment', file);
    formdata.append('type', Encryption('company', process.env.REACT_APP_SECRET_KEY));
    formdata.append('entity_id', Encryption(user.id, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.uploadDocument, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        console.log(result);
        if (result.success === true) {
          refresh();
        } else {
          setalert({ visible: true, message: result.message });
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error', error);
      })
      .finally(() => setloading(false));
  }

  useEffect(() => {
    if (data) {
      setdocumentType(data?.name);
      setfile({ name: data?.name });
    }
  }, []);
  useEffect(() => {
    if (documentType !== null || file !== null) {
      setalert({ visible: false, message: '' });
    }
  }, [documentType, file]);
  const [alert, setalert] = useState({ visible: false, message: '' });

  //category dropdown

  const handleChange = (event) => {
    setdocumentType(event.target.value);
  };
  const [docType, setdocType] = useState([]);
  function getDocumentTypes() {
    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.getDocumentType, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let response = JSON.parse(result);
        setdocType(response.data);
      })
      .catch((error) => console.log('error', error));
  }
  useEffect(() => {
    getDocumentTypes();
  }, []);
  return (
    <Box style={{ padding: 20 }}>
      <Stack spacing={3}>
        <Typography py={1} align="center" variant="h5">
          {t('documentModal.name')}
        </Typography>
        {alert.visible && (
          <>
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity="error"
            >
              {alert.message}
            </Alert>
          </>
        )}
        <TextField
          style={{ width: '100%', marginBottom: 10 }}
          id="outlined-select-currency"
          // select
          label={t('documentModal.field')}
          type="text"
          value={documentType}
          onChange={handleChange}
        >
          {/* {docType.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))} */}
        </TextField>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Button
            fullWidth
            style={{ width: file && 150, height: !file && 50 }}
            variant={file ? 'contained' : 'outlined'}
            component="label"
          >
            {file ? t('documentModal.buttons.3') : t('documentModal.buttons.2')}
            <input
              type="file"
              hidden
              onChange={(e) => {
                console.log(e.target.files[0]);
                setfile(e.target.files[0]);
              }}
            />
          </Button>
          {file && (
            <TextField fullWidth disabled value={file.name} label={t('documentModal.label')} />
          )}
        </Stack>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <LoadingButton
            onClick={() => AddDocument()}
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            style={{ marginRight: 10 }}
          >
            {t('documentModal.buttons.0')}
          </LoadingButton>
          <Button onClick={handleClose} variant="outlined">
            {t('documentModal.buttons.1')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
