import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { LoadingButton } from '@mui/lab';
import pallete from '../theme/palette';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Pagination
} from '@mui/material';

// components
import Page from '../components/Page';

import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { Decryption, Encryption } from 'src/functions/function';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
//

import { api } from '../Api';
import DocumentModal from 'src/sections/@dashboard/documents/documentModal';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Documents() {
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: 'document_type', label: t('Document.table.0'), alignRight: false, paddingLeft: 30 },
    { id: 'document_type', label: t('Document.table.1'), alignRight: false, paddingLeft: 30 },
    { id: 'action', label: t('Document.table.2'), alignRight: false, paddingLeft: 15 }
  ];
  const [permission, setpermission] = useState([]);
  const [data, setData] = useState(null);

  const requirement = ['visible', 'edit', 'add', 'delete'];
  const [loading, setloading] = useState(true);
  function checkRender() {
    let check = 'documents';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0].permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  function getDocuments(page) {
    const user = JSON.parse(localStorage.getItem('user'));

    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_KEY));
    user?.role !== 3 &&
      formdata.append('type', Encryption('company', process.env.REACT_APP_SECRET_KEY));
    user?.role !== 3 &&
      formdata.append('entity_id', Encryption(user.id, process.env.REACT_APP_SECRET_KEY));
    search.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_KEY));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.getDocuments, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          let decryptedata = JSON.parse(Decryption(result.data));
          setlist(decryptedata);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  useEffect(() => {
    getDocuments();
  }, []);

  const isUserNotFound = list.length === 0;

  const style = {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4
  };

  const [adminModal, setadminModal] = useState(false);

  const handleAdminClose = () => {
    setData(null);
    setadminModal(false);
  };

  const refreshAdmin = () => {
    setadminModal(false);
    getDocuments(page);
  };

  const [search, setsearch] = useState('');
  const [searchLoading, setsearchLoading] = useState(false);
  const [listModal, setlistModal] = useState(false);
  const [document, setdocument] = useState(null);

  function openModal(row) {
    setdocument(row);
    setlistModal(true);
  }

  function closeModal() {
    setdocument(null);
    setlistModal(false);
  }

  const [selectedIndex, setselectedIndex] = useState(null);
  const [deleting, setdeleting] = useState(false);

  function DeleteDocument(id, index) {
    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    setselectedIndex(index);
    setdeleting(true);
    var formdata = new FormData();
    formdata.append('id', Encryption(id, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.delete_documents, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          list.length == 0 && setlist([]);

          getDocuments(page);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setdeleting(false);
      });
  }
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getDocuments(value);
  };

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      setsearchLoading(true);
      setPage(1);
      getDocuments(1);
    }
  };
  return (
    <Page title={`Documents | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Document.name')}
          </Typography>

          {permission.includes('add') && (
            <Button
              onClick={() => setadminModal(true)}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {t('Document.add')}
            </Button>
          )}
        </Stack>

        <Modal
          style={style}
          open={adminModal}
          onClose={handleAdminClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle} className="admin_modal">
            <DocumentModal data={data} handleClose={handleAdminClose} refresh={refreshAdmin} />
          </Box>
        </Modal>
        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between'
            }}
          >
            <Searchbar
              placeholder={t('Document.search')}
              width={'80%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              endIcon={<Iconify icon="material-symbols:search" />}
              style={{ width: '17%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getDocuments(1);
              }}
            ></LoadingButton>
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                <TableBody>
                  {list.map((row, index) => {
                    const { id, name, attachment, seen, type } = row;

                    return (
                      <TableRow
                        onClick={() => {}}
                        style={{
                          backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
                          cursor: seen == '0' ? 'pointer' : 'default'
                        }}
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell
                          style={{ width: '45%' }}
                          component="th"
                          scope="row"
                          padding="normal"
                        >
                          <Typography
                            style={{ fontWeight: 'bold', paddingLeft: 14 }}
                            variant="subtitle2"
                            noWrap
                          >
                            {name}
                          </Typography>
                        </TableCell>

                        <TableCell
                          style={{ width: '20%' }}
                          component="th"
                          scope="row"
                          padding="normal"
                        >
                          <Typography
                            style={{ fontWeight: 'bold', paddingLeft: 14 }}
                            variant="subtitle2"
                            noWrap
                          >
                            {type}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            style={{ width: 100, marginRight: 10 }}
                            onClick={() => window.open(attachment)}
                            variant="outlined"
                            component={RouterLink}
                            to="#"
                            startIcon={<Iconify icon="carbon:view-filled" />}
                          >
                            {t('Document.buttons.0')}
                          </Button>
                          {permission.includes('edit') && (
                            <Button
                              style={{ width: 100, marginRight: 10 }}
                              onClick={() => {
                                setData(row);
                                setadminModal(true);
                              }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={
                                <Iconify color={pallete.primary.main} icon="tabler:edit" />
                              }
                            >
                              {t('Document.buttons.1')}
                            </Button>
                          )}
                          {permission.includes('delete') && (
                            <LoadingButton
                              startIcon={
                                <Iconify color={pallete.primary.main} icon="ic:baseline-delete" />
                              }
                              style={{ width: 100 }}
                              variant="outlined"
                              loading={selectedIndex == index && deleting}
                              onClick={() => {
                                DeleteDocument(id, index);
                                //      deleteContact(id, index);
                              }}
                            >
                              {t('Document.buttons.2')}
                            </LoadingButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
