import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  MenuItem,
  CircularProgress,
  Popover
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import GooglePlaces from 'src/components/GooglePlaces';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink } from 'react-router-dom';
import { api } from 'src/Api';
import ImagePicker from 'src/components/ImagePicker';
import pallete from '../../../theme/palette';
import DocumentPicker from 'src/components/DocumentPicker';
import palette from '../../../theme/palette';
import dayjs from 'dayjs';
import { Decryption, Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

export default function PropertyModal({ handleClose, refresh, edit, data }) {
  const { t } = useTranslation();
  const [reasonalert, setreasonalert] = useState({
    visible: false,
    message: 'Please provide reason for declining this business.'
  });

  const [loading, setloading] = useState(false);

  const [selectedFor, setselectedFor] = useState(null);
  const [ForObject, setForObject] = useState(null);
  const [selectedForId, setselectedForId] = useState(null);
  const [For, setFor] = useState([
    {
      id: 1,
      name: 'sale'
    },
    {
      id: 2,
      name: 'rent'
    }
  ]);

  const [selectedTenureObj, setselectedTenureObj] = useState(null);
  const [selectedTenure, setselectedTenure] = useState('');
  const [tenureData, settenureData] = useState([
    {
      id: 1,
      name: 'Weekly'
    },
    {
      id: 2,
      name: 'Bi monthly'
    },
    {
      id: 3,
      name: 'Monthly'
    },
    {
      id: 4,
      name: 'Yearly'
    }
  ]);

  const [selectedCategory, setselectedCategory] = useState(null);
  const [selectedCategoryObject, setselectedCategoryObject] = useState(null);

  const [Category, setCategory] = useState([
    {
      id: 1,
      name: 'Residential'
    },
    {
      id: 2,
      name: 'Commercial'
    }
  ]);

  const [selectedBody, setselectedBody] = useState(null);
  const [selectedBodyObject, setselectedBodyObject] = useState(null);

  const [bodycorps, setbodycorps] = useState([
    {
      id: 1,
      name: 'YES'
    },
    {
      id: 2,
      name: 'NO'
    },
    {
      id: 3,
      name: 'TBA'
    }
  ]);
  const [selectedType, setselectedType] = useState(null);
  const [selectedTypeObject, setselectedTypeObject] = useState(null);
  const [types, settypes] = useState([]);

  function GetPropertyTypes() {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    fetch(api.get_property_types, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          let decryptedData = JSON.parse(Decryption(result.data));
          console.log(decryptedData);
          settypes(decryptedData);
        }
      })
      .catch((error) => console.log('error', error));
  }

  const [construction, setconstruction] = useState([]);
  const [selectedConstruction, setselectedConstruction] = useState(null);
  const [selectedConstructionObject, setselectedConstructionObject] = useState(null);

  function getConstructionType() {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    fetch(api.get_construction_status, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          let decryptedData = JSON.parse(Decryption(result.data));
          setconstruction(decryptedData);
        }
      })
      .catch((error) => console.log('error', error));
  }

  const [saletypes, setsaletypes] = useState([]);
  const [selectedsaletypes, setselectedsaletypes] = useState(null);
  const [selectedsaletypesObject, setselectedsaletypesObject] = useState(null);

  function getSaleTypes() {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    fetch(api.getSaleType, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          let decryptedData = JSON.parse(Decryption(result.data));
          console.log(decryptedData, 'getsaletypes');
          setsaletypes(decryptedData);
        }
      })
      .catch((error) => console.log('error', error));
  }

  const [selectedStateObject, setselectedStateObject] = useState(null);
  const [states, setstates] = useState([]);

  function getStates() {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    fetch(api.getState, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.success == true) {
          let decryptedData = JSON.parse(Decryption(result.data));
          setstates(decryptedData);
        }
      })
      .catch((error) => console.log('error', error));
  }

  const [areas, setareas] = useState([]);
  const [selectedarea, setselectedarea] = useState(null);
  const [selectedareaObject, setselectedareaObject] = useState(null);
  const [areaname, setareaname] = useState(null);
  const [areaSize, setareaSize] = useState(null);
  const [arealotsize, setarealotsize] = useState(null);
  const [lot, setlot] = useState(null);
  const [showArea, setshowArea] = useState(false);
  const [priceCheck, setpriceCheck] = useState(false);
  const [buildCheck, setbuildCheck] = useState(false);
  const [investmentCheck, setinvestmentCheck] = useState(false);
  const [openInspection, setopenInspection] = useState(false);

  function getareaType() {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    fetch(api.getAreaUnit, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          let decryptedData = JSON.parse(Decryption(result.data));
          setareas(decryptedData);
        }
      })
      .catch((error) => console.log('error', error));
  }

  const handleChange = (event, type) => {
    if (type == 'body') {
      return setselectedBody(event.target.value?.name), setselectedBodyObject(event.target.value);
    }
    if (type == 'Category') {
      return (
        setselectedCategory(event.target.value?.id), setselectedCategoryObject(event.target.value)
      );
    }
    if (type == 'State') {
      return setstate(event.target.value?.name), setselectedStateObject(event.target.value);
    }
    if (type == 'For') {
      return (
        setselectedFor(event.target.value.name),
        setForObject(event.target.value),
        setselectedTenureObj(null),
        setselectedTenure(null)
      ); //available_for;
    }
    if (type == 'Tenure') {
      return setselectedTenure(event.target.value.name), setselectedTenureObj(event.target.value); //tenure;
    }
    if (type == 'Type') {
      return setselectedType(event.target.value.id), setselectedTypeObject(event.target.value);
    }
    if (type == 'Construction') {
      return (
        setselectedConstruction(event.target.value.id),
        setselectedConstructionObject(event.target.value)
      );
    }
    if (type == 'Sale') {
      return (
        setselectedsaletypes(event.target.value.id), setselectedsaletypesObject(event.target.value)
      );
    }
    if (type == 'Area') {
      return (
        setselectedarea(event.target.value?.id),
        setselectedareaObject(event.target.value),
        setareaname(event.target.value?.name)
      );
    }
  };

  const [title, settitle] = useState('');
  const [description, setdescription] = useState('');
  const [supportLetters, setsupportLetters] = useState('');
  const [address, setaddress] = useState('');
  const [price, setprice] = useState(null);
  const [year_of_build, setyear_of_build] = useState('');
  const [exclusiceAuthority, setexclusiceAuthority] = useState(null);
  const [beds, setbeds] = useState(0);
  const [baths, setbaths] = useState(0);
  const [garage, setgarage] = useState(0);
  const [draft, setdraft] = useState(false);
  const [uploadedImagesIds, setuploadedImagesIds] = useState([]);
  const [uploadedDocsIds, setuploadedDocsIds] = useState([]);
  const [streetnumber, setstreetnumber] = useState('');
  const [streetname, setstreetname] = useState('');
  const [suburb, setsuburb] = useState('');
  const [city, setcity] = useState('');
  const [state, setstate] = useState('');
  const [estate, setestate] = useState('');
  const [country, setcountry] = useState('Australia');
  const [postal_code, setpostal_code] = useState('');
  const [lat, setlat] = useState(null);
  const [lon, setlon] = useState(null);
  const [youtube, setyoutube] = useState('');
  const [width, setwidth] = useState('');
  const [depth, setdepth] = useState('');
  const [landPrice, setlandPrice] = useState('');
  const [buildPrice, setbuildPrice] = useState('');
  const [title_date, settitle_date] = useState(null);
  const [rentalInformation, setrentalInformation] = useState('');

  const handleCounterChange = (event, type) => {
    const value = event.target.value;
    if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 10)) {
      type == 'bed' && setbeds(event.target.value);
      type == 'bath' && setbaths(event.target.value);
      type == 'garage' && setgarage(event.target.value);
    }
  };

  const [reason, setReason] = useState('');
  const [reqError, setreqError] = useState({});

  useEffect(() => {
    GetPropertyTypes();
    getConstructionType();
    getSaleTypes();
    getareaType();
    getStates();
  }, []);

  function SaveProperty() {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    if (!draft) {
      //INFO VALIDATION
      if (title == '' || description == '') {
        description == '' && setreqError({ description: true });
        title == '' && setreqError({ title: true });
        return setreasonalert({
          visible: true,
          message: 'Title & description  is required'
        });
      }

      // //CATEGORY VALIDATION
      // if (!selectedCategory) {
      //   return (
      //     setreasonalert({ visible: true, message: 'Category is required' }),
      //     setreqError({ category: true })
      //   );
      // }

      // //FOR VALIDATION
      // if (!selectedFor) {
      //   return (
      //     setreasonalert({ visible: true, message: 'For sale/rent required' }),
      //     setreqError({ for: true })
      //   );
      // }

      // if (selectedFor == 'Rent' && !selectedTenure) {
      //   return (
      //     setreasonalert({ visible: true, message: 'Tenure is required for rental properties' }),
      //     setreqError({ tenure: true })
      //   );
      // }

      //IF RENT TENURE VALIDATION
      // if (rent && !selectedTenure) {
      //   return Alert.alert('', t('createProperty.alerts.tenure'), [
      //     {
      //       text: t('createProperty.alerts.buttons.ok'),
      //       onPress: () => console.log('ok'),
      //     },
      //   ]);
      // }

      //TYPE VALIDATION
      if (!selectedType) {
        return (
          setreasonalert({ visible: true, message: 'Type is required' }),
          setreqError({ type: true })
        );
      }
      //ADDRESS VALIDATION
      // if (address == '') {
      //   setreqError({ google: true });
      //   return setreasonalert({
      //     visible: true,
      //     message: 'Address is required'
      //   });
      // }

      // if (year_of_build && year_of_build != '' && parseInt(year_of_build) < 1960) {
      //   return (
      //     setreasonalert({ visible: true, message: 'Select Build Year 1960 atleast' }),
      //     setreqError({ buildYear: true })
      //   );
      // }
    }

    //   //PRICE OR PRICE ON CALL VALIDATION
    //   if (price == '' && !priceoncall) {
    //     return Alert.alert('', t('createProperty.alerts.info'), [
    //       {
    //         text: t('createProperty.alerts.buttons.ok'),
    //         onPress: () => console.log('ok'),
    //       },
    //     ]);
    //   }
    // } else {
    //   if (title == '' || address.name == '') {
    //     return Alert.alert('', t('createProperty.alerts.draft'), [
    //       {
    //         text: t('createProperty.alerts.buttons.ok'),
    //         onPress: () => console.log('ok'),
    //       },
    //     ]);
    //   }
    // }

    //IF OFF PLAN START DATE VALIDATION
    // if (plan && planstartdate == null) {
    //   return Alert.alert('', t('createProperty.alerts.plan'), [
    //     {
    //       text: t('createProperty.alerts.buttons.ok'),
    //       onPress: () => console.log('ok'),
    //     },
    //   ]);
    // }

    var formdata = new FormData();

    // edit && formdata.append('id', data.id);
    // edit && formdata.append('market_status', data?.market_status);
    // edit &&
    //   formdata.append(
    //     'status',
    //     draft ? 'draft' : data?.status == 'draft' ? 'active' : data?.status,
    //   );

    edit && formdata.append('id', Encryption(data?.id, process.env.REACT_APP_SECRET_APP));
    formdata.append('title', Encryption(title, process.env.REACT_APP_SECRET_APP));
    formdata.append('description', Encryption(description, process.env.REACT_APP_SECRET_APP));
    formdata.append('price', Encryption(price, process.env.REACT_APP_SECRET_APP));

    formdata.append(
      'support_letters',
      Encryption(supportLetters, process.env.REACT_APP_SECRET_APP)
    );
    // year_of_build &&
    //   year_of_build != '' &&
    //   formdata.append('year_of_build', Encryption(year_of_build, process.env.REACT_APP_SECRET_APP));
    // year_of_build &&
    //   year_of_build != '' &&
    //   formdata.append(
    //     'year_of_build_check',
    //     buildCheck
    //       ? Encryption(1, process.env.REACT_APP_SECRET_APP)
    //       : Encryption(0, process.env.REACT_APP_SECRET_APP)
    //   );
    // formdata.append(
    //   'on_call',
    //   priceCheck
    //     ? Encryption(1, process.env.REACT_APP_SECRET_APP)
    //     : Encryption(0, process.env.REACT_APP_SECRET_APP)
    // );
    formdata.append(
      'status',
      edit
        ? Encryption(data?.status, process.env.REACT_APP_SECRET_APP)
        : Encryption('AVAILABLE', process.env.REACT_APP_SECRET_APP)
    );
    // !edit &&
    //   !draft &&
    //   formdata.append('status', Encryption('active', process.env.REACT_APP_SECRET_APP));
    // draft && formdata.append('status', Encryption('draft', process.env.REACT_APP_SECRET_APP));
    // edit &&
    //   !draft &&
    //   formdata.append('status', Encryption(data?.status, process.env.REACT_APP_SECRET_APP));
    // edit &&
    //   formdata.append(
    //     'market_status',
    //     Encryption(data?.market_status, process.env.REACT_APP_SECRET_APP)
    //   );
    formdata.append(
      'lot_size',
      arealotsize != ''
        ? Encryption(arealotsize, process.env.REACT_APP_SECRET_APP)
        : Encryption(null, process.env.REACT_APP_SECRET_APP)
    );
    formdata.append(
      'lot',
      lot != ''
        ? Encryption(lot, process.env.REACT_APP_SECRET_APP)
        : Encryption(null, process.env.REACT_APP_SECRET_APP)
    );
    formdata.append(
      'square_feet',
      areaSize != ''
        ? Encryption(areaSize, process.env.REACT_APP_SECRET_APP)
        : Encryption(null, process.env.REACT_APP_SECRET_APP)
    );

    formdata.append('width', Encryption(width, process.env.REACT_APP_SECRET_APP));
    formdata.append('depth', Encryption(depth, process.env.REACT_APP_SECRET_APP));
    formdata.append('land_price', Encryption(landPrice, process.env.REACT_APP_SECRET_APP));
    formdata.append('build_price', Encryption(buildPrice, process.env.REACT_APP_SECRET_APP));
    formdata.append('bedroom', Encryption(beds, process.env.REACT_APP_SECRET_APP));
    formdata.append('garage', Encryption(garage, process.env.REACT_APP_SECRET_APP));
    formdata.append('bathroom', Encryption(baths, process.env.REACT_APP_SECRET_APP));
    // formdata.append('city', Encryption(city, process.env.REACT_APP_SECRET_APP));
    formdata.append('country', Encryption(country, process.env.REACT_APP_SECRET_APP));
    formdata.append('address', Encryption(address, process.env.REACT_APP_SECRET_APP));
    // streetnumber != undefined &&
    //   formdata.append('house_no', Encryption(streetnumber, process.env.REACT_APP_SECRET_APP));
    // streetname != undefined &&
    //   formdata.append('street', Encryption(streetname, process.env.REACT_APP_SECRET_APP));
    formdata.append('suburbs', Encryption(suburb, process.env.REACT_APP_SECRET_APP));
    formdata.append('estate', Encryption(estate, process.env.REACT_APP_SECRET_APP));
    formdata.append('state', Encryption(state, process.env.REACT_APP_SECRET_APP));
    // formdata.append('postcode', Encryption(postal_code, process.env.REACT_APP_SECRET_APP));
    formdata.append('realtor_id', Encryption(user?.id, process.env.REACT_APP_SECRET_APP));
    formdata.append('is_published', Encryption('1', process.env.REACT_APP_SECRET_APP));
    // selectedFor == 'rent' &&
    //   formdata.append('tenure', Encryption(selectedTenure, process.env.REACT_APP_SECRET_APP));
    formdata.append('property_type_id', Encryption(selectedType, process.env.REACT_APP_SECRET_APP));
    formdata.append(
      'proper_category_id',
      Encryption('1', process.env.REACT_APP_SECRET_APP)
      // Encryption(selectedCategory, process.env.REACT_APP_SECRET_APP)
    );
    formdata.append('available_for', Encryption('sale', process.env.REACT_APP_SECRET_APP));
    formdata.append('lat', Encryption(lat, process.env.REACT_APP_SECRET_APP));
    formdata.append('long', Encryption(lon, process.env.REACT_APP_SECRET_APP));
    //  formdata.append('currency', Encryption('AUD', process.env.REACT_APP_SECRET_APP));
    formdata.append('user_id', Encryption(user?.id, process.env.REACT_APP_SECRET_APP));
    formdata.append('title_date', Encryption(title_date, process.env.REACT_APP_SECRET_APP));
    formdata.append('body_corp', Encryption(selectedBody, process.env.REACT_APP_SECRET_APP));
    formdata.append(
      'rental_information',
      Encryption(rentalInformation, process.env.REACT_APP_SECRET_APP)
    );
    //  formdata.append('is_video', video ? '1' : '0');
    ////   formdata.append('is_youtube', youtube.length > 0 ? 1 : 0);
    //    youtube.length > 0 && formdata.append('youtube_url', youtube);
    //  formdata.append('area_unit_id', Encryption(selectedarea, process.env.REACT_APP_SECRET_APP));
    // formdata.append(
    //   'constructions_status_id',
    //   Encryption(selectedConstruction, process.env.REACT_APP_SECRET_APP)
    // );
    // formdata.append(
    //   'sale_type_method_id',
    //   Encryption(selectedsaletypes, process.env.REACT_APP_SECRET_APP)
    // );
    // formdata.append(
    //   'is_show_area',
    //   showArea
    //     ? Encryption(1, process.env.REACT_APP_SECRET_APP)
    //     : Encryption(0, process.env.REACT_APP_SECRET_APP)
    // );
    // if (
    //   openInspection &&
    //   inspectionList.filter(
    //     (item) => item.date != null && item.start_time != null && item.end_time != null
    //   ).length > 0
    // ) {
    //   formdata.append(
    //     'open_inspections',
    //     Encryption(
    //       JSON.stringify(
    //         inspectionList.filter((item) => item.date && item.start_time && item.end_time)
    //       ),
    //       process.env.REACT_APP_SECRET_APP
    //     )
    //   );
    // } else {
    //   formdata.append(
    //     'open_inspections',
    //     Encryption(JSON.stringify([]), process.env.REACT_APP_SECRET_APP)
    //   );
    // }
    //property_documents // property_images
    // if (
    //   openinspection &&
    //   inspectionList.filter(
    //     (item) => item.date != null && item.start_time != null && item.end_time != null
    //   ).length > 0
    // ) {
    //   formdata.append(
    //     'open_inspections',
    //     JSON.stringify(
    //       inspectionList.filter((item) => item.date && item.start_time && item.end_time)
    //     )
    //   );
    // } else {
    //   formdata.append('open_inspections', JSON.stringify([]));
    // }

    // formdata.append('facility_ids', selectedFacilities?.toString());
    // uploadedImagesIds.length > 0 &&
    //   formdata.append('property_images', uploadedImagesIds.toString());
    // uploadedImages.length == 0 && videoId && formdata.append('property_images', videoId.toString());

    // for (let i = 0; i < documents.length; i++) {
    //   formdata.append('attachment[' + i + ']', {
    //     uri: documents[i].uri,
    //     name: documents[i].name,
    //     type: 'application/pdf'
    //     //   type: documents[i].type,
    //   });
    // }
    // if (video) {
    //   formdata.append(`video`, {
    //     uri: video.uri,
    //     name: (Math.random() + 1).toString(36).substring(7),
    //     type: 'video/mp4',
    //   });
    // }

    // formdata.append(
    //   'for_investment',
    //   investmentCheck
    //     ? Encryption(1, process.env.REACT_APP_SECRET_APP)
    //     : Encryption(0, process.env.REACT_APP_SECRET_APP)
    // );
    // formdata.append('off_plan_project', plan ? 1 : 0);
    //  planstartdate && formdata.append('off_plan_start', planstartdate);
    //  planenddate && formdata.append('off_plan_end', planenddate);
    // exclusiceAuthority &&
    //   formdata.append(
    //     'exclusive_authority',
    //     Encryption(exclusiceAuthority, process.env.REACT_APP_SECRET_APP)
    //   );

    formdata.append(
      'is_youtube',
      youtube.length > 0
        ? Encryption(1, process.env.REACT_APP_SECRET_APP)
        : Encryption(0, process.env.REACT_APP_SECRET_APP)
    );
    youtube.length > 0 &&
      formdata.append('youtube_url', Encryption(youtube, process.env.REACT_APP_SECRET_APP));

    (uploadedImagesIds.length > 0 || videoId) &&
      formdata.append(
        'property_images',
        videoId
          ? Encryption(
              `${videoId},${uploadedImagesIds.toString()}`,
              process.env.REACT_APP_SECRET_APP
            )
          : Encryption(uploadedImagesIds.toString(), process.env.REACT_APP_SECRET_APP)
      );

    uploadedDocsIds.length > 0 &&
      formdata.append(
        'property_documents',
        Encryption(uploadedDocsIds.toString(), process.env.REACT_APP_SECRET_APP)
      );

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);

    fetch(api.save_properties, requestOptions)
      .then((response) => response.text())

      .then((result_) => {
        console.log(result_);
        let result = JSON.parse(result_);
        if (result.success == true) {
          refresh();
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
      });

    // if (!selectedConstruction) {
    //   return Alert.alert('', t('createProperty.alerts.construction'), [
    //     {
    //       text: t('createProperty.alerts.buttons.ok'),
    //       onPress: () => console.log('ok'),
    //     },
    //   ]);
    // }
    // if (!selectedarea) {
    //   return Alert.alert('', t('createProperty.alerts.area'), [
    //     {
    //       text: t('createProperty.alerts.buttons.ok'),
    //       onPress: () => console.log('ok'),
    //     },
    //   ]);
    // }
    // if (!selectedsaletypes) {
    //   return Alert.alert('', t('createProperty.alerts.sale'), [
    //     {
    //       text: t('createProperty.alerts.buttons.ok'),
    //       onPress: () => console.log('ok'),
    //     },
    //   ]);
    // }

    // if (selectedFacilities.length == 0) {
    //   return Alert.alert('', t('createProperty.alerts.facility'), [
    //     {
    //       text: t('createProperty.alerts.buttons.ok'),
    //       onPress: () => console.log('ok'),
    //     },
    //   ]);
    // }

    // IMAGES VALIDATION

    // if (PropertyImages.length == 0) {
    //   return Alert.alert('', t('createProperty.alerts.image'), [
    //     {
    //       text: t('createProperty.alerts.buttons.ok'),
    //       onPress: () => console.log('ok'),
    //     },
    //   ]);
    // }
  }

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoloading, setvideoloading] = useState(false);
  const [videoId, setvideoId] = useState(null);

  const handleVideoSelect = (event) => {
    const file = event.target.files[0];
    uploadVideo(event);
    //setSelectedVideo(URL.createObjectURL(file));
  };

  function uploadVideo(event) {
    setvideoloading(true);
    setyoutube(null);
    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('type', Encryption('video', process.env.REACT_APP_SECRET_APP));
    formdata.append('image', event.target.files[0]);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.upload_property_images, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let decryptedData = JSON.parse(Decryption(result.data));
        setSelectedVideo(decryptedData?.uri);
        setvideoId(decryptedData?.id);
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setvideoloading(false);
      });
  }

  useEffect(() => {
    if (edit) {
      settitle(data?.title);
      setdescription(data?.description);
      data?.support_letters && setsupportLetters(data?.support_letters);
      setselectedCategoryObject(Category.filter((item) => item.id == data.proper_category.id)[0]);
      setselectedCategory(data?.proper_category?.id);
      setselectedBodyObject(bodycorps.filter((item) => item.name == data.body_corp)[0]);
      data?.body_corp && setselectedBody(data?.body_corp);
      setForObject(For.filter((item) => item.name == data?.available_for)[0]);
      setselectedStateObject(states.filter((item) => item.name == data?.state)[0]);
      setselectedFor(data?.available_for);
      if (data?.available_for == 'rent') {
        setselectedTenureObj(tenureData.filter((item) => item.name == data?.tenure)[0]);
        setselectedTenure(data?.tenure);
      }
      data?.title_date && settitle_date(data?.title_date);
      data?.lot_size && setarealotsize(data?.lot_size);
      data?.square_feet && setareaSize(data?.square_feet);
      data?.lot && setlot(data?.lot);
      data?.price && setprice(data?.price);
      data?.land_price && setlandPrice(data?.land_price);
      data?.build_price && setbuildPrice(data?.build_price);
      // setexclusiceAuthority(data?.exclusive_authority);
      //  setshowArea(data?.is_show_area === 1 && true);
      // setpriceCheck(data?.on_call === 1 && true);
      // setbuildCheck(data?.year_of_build_check === 1 && true);
      // setinvestmentCheck(data?.for_investment === 1 && true);
      //   setdraft(data?.status == 'draft' && true);
      // setyear_of_build(data?.year_of_build);
      setbeds(data?.bedroom);
      setbaths(data?.bathroom);
      setgarage(data?.garage);
      setSelectedVideo(data.property_images.filter((item) => item.type == 'video')[0]?.uri);
      !data?.youtube_url &&
        setvideoId(data.property_images.filter((item) => item.type == 'video')[0]?.id);
      setuploadedImagesIds(
        data?.property_images?.filter((item) => item.type == 'image').map((items) => items.id)
      );
      setuploadedDocsIds(
        data?.documents?.filter((item) => item.type == 'property').map((items) => items.id)
      );
      data?.open_inspections?.length > 0 && setopenInspection(true);
      data?.open_inspections?.length > 0 && setInspectionList(data?.open_inspections);
      // "address": "1-8 Nield Avenue, Greenwich NSW, Australia",
      // "postals": "2065",
      // "country": "Australia",
      // "city": "Municipality of Lane Cove",
      // "state": "NSW",
      // "statecode": null,
      // "house_no": "1-8",
      // "street": "Nield Ave",
      // "suburbs": "Greenwich",
      data?.address && setaddress(data?.address);
      setpostal_code(data?.postals);
      // setcountry(data?.country);
      // setcity(data?.city);
      data?.state && setstate(data?.state);
      data?.estate && setestate(data?.estate);
      setstreetname(data?.street);
      setstreetnumber(data?.house_no);
      data?.suburbs && setsuburb(data?.suburbs);
      setlat(data?.lat);
      setlon(data?.long);
      data?.youtube_url && setyoutube(data?.youtube_url);
      data?.width && setwidth(data?.width);
      data?.depth && setdepth(data?.depth);
      data?.rental_information && setrentalInformation(data?.rental_information);
      setproperty_Image_Listing(data?.property_images);
    }
  }, []);

  useEffect(() => {
    if (edit) {
      if (types.length > 0) {
        setselectedTypeObject(types.filter((item) => item.id == data?.property_type?.id)[0]);
        setselectedType(data.property_type.id);
      }
      if (construction.length > 0) {
        setselectedConstructionObject(
          construction.filter((item) => item.id == data?.construction_status?.id)[0]
        );
        setselectedConstruction(data.property_type.id);
      }
      if (saletypes.length > 0) {
        setselectedsaletypesObject(
          saletypes.filter((item) => item.id == data?.sale_type_method?.id)[0]
        );
        setselectedsaletypes(data?.sale_type_method?.id);
      }
      if (areas.length > 0 && data?.area_unit != null) {
        setselectedareaObject(areas.filter((item) => item.id == data?.area_unit?.id)[0]);
        setselectedarea(areas.filter((item) => item.id == data?.area_unit?.id)[0]?.id);
        setareaname(data?.area_unit?.name);
      }
      if (states.length > 0)
        setselectedStateObject(states.filter((item) => item.name == data?.state)[0]);
      setstate(data?.state);
    }
    //setselectedConstructionObject
  }, [types, construction, saletypes, areas, states]);

  const [inspectionList, setInspectionList] = useState([
    {
      date: null,
      start_time: null,
      end_time: null
    }
  ]);

  const updateDateTime = (index, field, value, del) => {
    if (del) {
      setInspectionList((prevState) => {
        const newArray = [...prevState];
        newArray.splice(index, 1);
        return newArray;
      });
    } else {
      const newInspectionList = [...inspectionList]; // Create a copy of the array

      newInspectionList[index][field] = value; // Update the specific field value
      setInspectionList(newInspectionList); // Update the state with the modified array
    }
  };

  const addMoreList = () => {
    const newInspectionList = [...inspectionList];
    newInspectionList.push({ date: null, start_time: null, end_time: null });
    setInspectionList(newInspectionList);
  };

  // DATEPICKER
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickPicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [property_Image_Listing, setproperty_Image_Listing] = useState([]);
  const [imageError, setimageError] = useState('');
  const [imageLoading, setimageLoading] = useState(false);
  const handleUploadFromLink = async (url) => {
    try {
      setimageLoading(true);
      let fileId = url.split('/')[5];
      const response = await fetch(
        `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`

        //     `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=AIzaSyCfkkFnJ7ixENdtACx3-Q5Mewh3wftkCo8`
      );
      const blob = await response.blob();
      ImageUpload(blob);
    } catch (error) {
      console.error('Error uploading image:', error);
      setimageLoading(false);
    }
  };

  function ImageUpload(blob) {
    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('type', Encryption('image', process.env.REACT_APP_SECRET_APP));
    formdata.append('image', blob, 'image.jpg');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.upload_property_images, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let decryptedData = JSON.parse(Decryption(result.data));
        setuploadedImagesIds((uploadedImages) => [...uploadedImages, decryptedData?.id]);
        setproperty_Image_Listing((prev) => [...prev, decryptedData]);
        setimageLink('');
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setimageLoading(false);
      });
  }

  const [imageLink, setimageLink] = useState(null);

  return (
    <>
      <Box
        pt={3}
        pb={3}
        sx={{
          backgroundColor: 'white',
          position: 'sticky',
          top: 0,
          borderBottom: '2px solid',
          borderColor: pallete.primary.main,
          zIndex: 5,
          flexDirection: 'row',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between'
        }}
        className="property_modal_header"
      >
        <Typography pl={3.5} variant="h4" className="margin_property">
          {t('CreateProperty.name')}
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
          mr={3.5}
          className="margin_property"
        >
          <LoadingButton
            style={{ width: 40, marginRight: 5 }}
            size="small"
            variant="contained"
            component={RouterLink}
            to="#"
            loading={loading}
            onClick={() => {
              SaveProperty();
              console.log(uploadedImagesIds);
            }}
          >
            {t('CreateProperty.buttons.0')}
          </LoadingButton>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            {t('CreateProperty.buttons.1')}
          </Button>
        </Box>
      </Box>
      <Box p={3} pt={1} className="property_padding">
        <Box p={1}>
          {/* TITLE AND DESCRIPTION */}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
          >
            <TextField
              required
              style={{ width: '100%', marginBottom: 20 }}
              id="outlined-select-currency"
              label={'Title'}
              color={reqError.title ? 'error' : 'primary'}
              value={title}
              onChange={(e) => settitle(e.target.value)}
              error={reqError?.title}
            />

            <TextField
              required
              multiline
              maxRows={10}
              style={{ width: '100%' }}
              id="outlined-select-currency"
              label={'Design'}
              color={reqError?.description ? 'error' : 'primary'}
              value={description}
              onChange={(e) => setdescription(e.target.value)}
              error={reqError?.description}
            />
          </Box>

          {/* {selectedFor == 'rent' && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 20
              }}
            >
              <TextField
                required
                style={{ width: '100%' }}
                id="outlined-select-currency"
                select
                label={t('CreateProperty.fields.4')}
                color={reqError?.tenure ? 'error' : 'primary'}
                value={selectedTenureObj}
                onChange={(e) => handleChange(e, 'Tenure')}
                error={reqError?.tenure}
                //     InputLabelProps={{ shrink: edit ? true : false }}
                InputLabelProps={{ shrink: !!selectedTenureObj }}
              >
                {tenureData.map((option) => (
                  <MenuItem key={option.id} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )} */}

          {/* CONSTRUCTION STATUS & TYPE */}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
          >
            <TextField
              required
              style={{ width: '100%' }}
              id="outlined-select-currency"
              select
              label={t('CreateProperty.fields.5')}
              color={reqError?.type ? 'error' : 'primary'}
              value={selectedTypeObject}
              onChange={(e) => handleChange(e, 'Type')}
              error={reqError?.type}
              //  InputLabelProps={{ shrink: edit ? true : false }}
              InputLabelProps={{ shrink: !!selectedTypeObject }}
            >
              {types.map((option) => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 20,
              justifyContent: 'space-between'
            }}
          >
            <TextField
              style={{ width: '90%' }}
              id="outlined-select-currency"
              label={'Title Date'}
              color={reqError.title ? 'error' : 'primary'}
              value={title_date}
              InputLabelProps={{ shrink: !!title_date }}
              onChange={(e) => settitle_date(e.target.value)}
            />
            <Button
              variant="outlined"
              onClick={handleClickPicker}
              style={{ fontSize: 20, height: 55, width: '8%' }}
            >
              +
            </Button>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePicker}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['StaticDatePicker']}>
                  <DemoItem label="">
                    <StaticDatePicker
                      defaultValue={dayjs()}
                      onChange={(selectedDate) => {
                        settitle_date(selectedDate.format('DD/MM/YY'));
                        handleClosePicker();
                      }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </Popover>
          </Box>

          {/* SALE TYPE & AREA UNIT */}

          {/* <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
          >
            <TextField
              style={{ width: '49%' }}
              id="outlined-select-currency"
              select
              label={t('CreateProperty.fields.7')}
              color={'primary'}
              value={selectedsaletypesObject}
              onChange={(e) => handleChange(e, 'Sale')}
              InputLabelProps={{ shrink: !!selectedsaletypesObject }}

              //        InputLabelProps={{ shrink: edit ? true : false }}
            >
              {saletypes.map((option) => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              style={{ width: '49%' }}
              id="outlined"
              select
              label={t('CreateProperty.fields.8')}
              color={'primary'}
              value={selectedareaObject}
              onChange={(e) => handleChange(e, 'Area')}
              InputLabelProps={{ shrink: !!selectedareaObject }}
            >
              <MenuItem value={null}>
                <em>None</em>
              </MenuItem>
              {areas.map((option) => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box> */}
          {/* WIDTH AND DEPTH */}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
            className="top_column"
          >
            <TextField
              style={{ width: '49%' }}
              id="outlined-select-currency"
              label={'Width'}
              placeholder={'Width'}
              color={'primary'}
              value={width}
              onChange={(e) => setwidth(e.target.value)}
              //    type="number"
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            />
            <TextField
              style={{ width: '49%' }}
              id="outlined-select-currency"
              label={'Depth'}
              placeholder={'Depth'}
              color={'primary'}
              value={depth}
              onChange={(e) => setdepth(e.target.value)}
              //   type="number"
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            />

            {/* <FormControlLabel
              control={<Checkbox checked={showArea} onChange={() => setshowArea(!showArea)} />}
              label={t('CreateProperty.fields.11')}
              className="property_checkbox"
            /> */}
          </Box>

          {/* BODY CORP && RENTAL INFORMATION */}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
          >
            <TextField
              style={{ width: '49%' }}
              id="outlined-select-currency"
              select
              label={'Body Corp'}
              color={'primary'}
              value={selectedBodyObject}
              onChange={(e) => handleChange(e, 'body')}
              //      InputLabelProps={{ shrink: edit && true }}
              InputLabelProps={{ shrink: !!selectedBodyObject }}
            >
              {bodycorps.map((option) => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              style={{ width: '49%' }}
              id="outlined-select-currency"
              label={'Rental Information'}
              color={'primary'}
              value={rentalInformation}
              onChange={(e) => setrentalInformation(e.target.value)}
            />
          </Box>

          {/*LOT LAND SIZE BUILD SIZE */}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
            className="top_column"
          >
            <TextField
              style={{ width: '32%' }}
              id="outlined-select-currency"
              label={'Lot'}
              color={'primary'}
              placeholder="Lot"
              value={lot}
              onChange={(e) => setlot(e.target.value)}
              type="number"
              className="width_full_property"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              style={{ width: '32%' }}
              id="outlined-select-currency"
              label={'Land Size'}
              placeholder="Land Size"
              color={'primary'}
              value={areaSize}
              onChange={(e) => setareaSize(e.target.value)}
              type="number"
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            />

            <TextField
              style={{ width: '32%' }}
              id="outlined-select-currency"
              label={'Build Size'}
              placeholder="Build Size"
              color={'primary'}
              value={arealotsize}
              onChange={(e) => setarealotsize(e.target.value)}
              type="number"
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            />
          </Box>

          {/* TOTAL PRICE BUILD PRICE LAND PRICE */}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
            className="top_column"
          >
            <TextField
              style={{ width: '32%' }}
              id="outlined-select-currency"
              label={'Land Price'}
              placeholder={'Land Price'}
              color={'primary'}
              value={landPrice}
              onChange={(e) => setlandPrice(e.target.value)}
              type="number"
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            />
            <TextField
              style={{ width: '32%' }}
              id="outlined-select-currency"
              label={'Build Price'}
              placeholder={'Build Price'}
              color={'primary'}
              value={buildPrice}
              onChange={(e) => setbuildPrice(e.target.value)}
              type="number"
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            />

            <TextField
              style={{ width: '32%' }}
              id="outlined-select-currency"
              label={'Total Price'}
              placeholder={'Total Price'}
              color={'primary'}
              value={price}
              onChange={(e) => setprice(e.target.value)}
              type="number"
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            />
          </Box>

          {/* <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
            className="top_column"
          >
            <GooglePlaces
              placeholder={t('CreateProperty.fields.12')}
              width="36%"
              onResult={(val) => {
                setaddress(val.address);
                setstreetname(val.streetname);
                setstreetnumber(val.streetnumber);
                setsuburb(val.suburb);
                setpostal_code(val.postcode);
                setstate(val.state);
                setcountry(val.country);
                setcity(val.city);
                setlat(val.lat);
                setlon(val.lon);
              }}
              address={data?.address}
              error={reqError.google}
              required={true}
            />
          </Box> */}

          {/* BUILD YEAR & EXCLUSIVE AUTHORITY */}
          {/* 
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
            className="top_column"
          >
            <TextField
              style={{ width: '33%' }}
              id="outlined-select-currency"
              label={t('CreateProperty.fields.15')}
              color={'primary'}
              value={exclusiceAuthority}
              onChange={(e) => setexclusiceAuthority(e.target.value)}
              type="date"
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            />

            <TextField
              style={{ width: '33%' }}
              id="outlined-select-currency"
              label={t('CreateProperty.fields.16')}
              color={reqError?.buildYear ? 'error' : 'primary'}
              value={year_of_build}
              onChange={(e) => setyear_of_build(e.target.value)}
              type="number"
              error={reqError?.buildYear}
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            />

            <FormControlLabel
              control={
                <Checkbox checked={buildCheck} onChange={() => setbuildCheck(!buildCheck)} />
              }
              label={t('CreateProperty.fields.17')}
              className="property_checkbox"
            />
          </Box> */}

          {/* BEDS BATHS & GARAGE */}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
          >
            <TextField
              style={{ width: '32%' }}
              id="outlined-select-currency"
              label={t('CreateProperty.fields.18')}
              color={'primary'}
              value={beds}
              onChange={(e) => handleCounterChange(e, 'bed')}
              type="number"
              inputProps={{
                min: 0,
                max: 10
              }}
            />

            <TextField
              style={{ width: '32%' }}
              id="outlined-select-currency"
              label={t('CreateProperty.fields.19')}
              color={'primary'}
              value={baths}
              onChange={(e) => handleCounterChange(e, 'bath')}
              type="number"
              inputProps={{
                min: 0,
                max: 10
              }}
            />

            <TextField
              style={{ width: '32%' }}
              id="outlined-select-currency"
              label={'Cars'}
              color={'primary'}
              value={garage}
              onChange={(e) => handleCounterChange(e, 'garage')}
              type="number"
              inputProps={{
                min: 0,
                max: 10
              }}
            />
          </Box>

          {/* ADDRESS & PRICE */}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
            className="top_column"
          >
            {/* <TextField
              style={{ width: '14%' }}
              id="outlined-select-currency"
              label={'State'}
              color={'primary'}
              value={state}
              onChange={(e) => setstate(e.target.value)}
              type="number"
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            /> */}
            <TextField
              className="width_full_property"
              required
              style={{ width: '14%' }}
              id="outlined-select-currency"
              select
              label={'State'}
              //    color={reqError?.type ? 'error' : 'primary'}
              value={selectedStateObject}
              onChange={(e) => handleChange(e, 'State')}
              error={reqError?.type}
              InputLabelProps={{ shrink: !!selectedStateObject }}
            >
              {states.map((option) => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              style={{ width: '34%' }}
              id="outlined-select-currency"
              label={'Address'}
              color={'primary'}
              value={address}
              onChange={(e) => setaddress(e.target.value)}
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            />

            <TextField
              style={{ width: '24%' }}
              id="outlined-select-currency"
              label={'Estate'}
              color={'primary'}
              value={estate}
              onChange={(e) => setestate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            />
            <GooglePlaces
              placeholder={'Suburb'}
              width={'24%'}
              onResult={(val) => {
                // setaddress(val.address);
                // setstreetname(val.streetname);
                // setstreetnumber(val.streetnumber);
                val?.suburb && setsuburb(val.suburb);
                // setpostal_code(val.postcode);
                // setstate(val.state);
                // setcountry(val.country);
                // setcity(val.city);
                setlat(val.lat);
                setlon(val.lon);
              }}
              address={data?.suburbs || ''}
              required={false}
              regions={true}
            />
            {/* <TextField
              style={{ width: '24%' }}
              id="outlined-select-currency"
              label={'Suburb'}
              color={'primary'}
              value={suburb}
              onChange={(e) => setsuburb(e.target.value)}
              InputLabelProps={{ shrink: true }}
              className="width_full_property"
            /> */}
          </Box>

          {/* INVESTMENT AND DRAFT CHECK BOXES */}

          {/* <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 20,
              paddingLeft: 5
            }}
            className="top_column"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={investmentCheck}
                  onChange={() => setinvestmentCheck(!investmentCheck)}
                />
              }
              label={t('CreateProperty.fields.21')}
              className="width_full"
            />
            <FormControlLabel
              // style={{ marginLeft: 5 }}
              control={<Checkbox checked={draft} onChange={() => setdraft(!draft)} />}
              label={t('CreateProperty.fields.22')}
              className="width_full"
            />
          </Box> */}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
            className="top_column"
          >
            <TextField
              multiline
              maxRows={10}
              style={{ width: '100%' }}
              id="outlined-select-currency"
              label={'Support Letters'}
              //   placeholder="Support Letters"
              color={'primary'}
              value={supportLetters}
              onChange={(e) => setsupportLetters(e.target.value)}
            />
          </Box>
          {/* IMAGES */}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 15,
              paddingLeft: 2
            }}
          >
            <ImagePicker
              buttonText={t('CreateProperty.buttons.2')}
              onUploadComplete={(ids, obj) => {
                setuploadedImagesIds((uploadedImages) => [...uploadedImages, ids]);
                setproperty_Image_Listing((prev) => [...prev, obj]);
              }}
              onDelete={(id) => {
                setuploadedImagesIds(uploadedImagesIds.filter((item) => item != id));
                setproperty_Image_Listing(property_Image_Listing.filter((item) => item?.id != id));
              }}
              data={property_Image_Listing || data?.property_images || []}
            />
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 20,
              justifyContent: 'space-between'
            }}
          >
            <TextField
              disabled={selectedVideo != null}
              style={{ width: '90%', marginBottom: 0 }}
              id="outlined-select-currency"
              label={'Upload image from google drive url'}
              value={imageLink}
              onChange={(e) => {
                setimageLink(e.target.value);
                setimageError('');
              }}
              InputLabelProps={{ shrink: imageLink ? true : false }}
              error={imageError.length > 0}
              helperText={imageError || ''}
            />
            <LoadingButton
              disabled={imageLink ? false : true}
              size="small"
              variant="outlined"
              component={RouterLink}
              to="#"
              loading={imageLoading}
              onClick={() => {
                const urlPattern =
                  /^(https?:\/\/)?(([a-z0-9]+-?)[a-z0-9]+\.)+[a-z]{2,6}(:[0-9]{1,5})?(\/[a-z0-9._%+-\/\?=&#]*)?$/i;
                const isValidUrl = urlPattern.test(imageLink);
                if (isValidUrl) {
                  handleUploadFromLink(imageLink);
                } else {
                  setimageError('The link you entered is not valid');
                }
              }}
              style={{ height: 50, width: '8%' }}
            >
              Upload
            </LoadingButton>
            {/* <Button
              variant="outlined"
              onClick={()=>handleUploadFromLink(imageLink)}
              style={{ height: 50, width: '8%' }}
            >
              Upload
            </Button> */}
          </Box>

          {/* DOCUMENTS */}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 15,
              paddingLeft: 2
            }}
          >
            <DocumentPicker
              buttonText={t('CreateProperty.buttons.3')}
              onUploadComplete={(ids) => {
                setuploadedDocsIds((uploadedDocsIds) => [...uploadedDocsIds, ids]);
                console.log('UPLOADED IDS', ids);
              }}
              onDelete={(id) => {
                setuploadedImagesIds(uploadedDocsIds.filter((item) => item != id));
              }}
              data={edit && data?.documents}
            />
          </Box>
          {/* VIDEO */}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 15,
              paddingLeft: 2
            }}
          >
            <div>
              {!selectedVideo && !videoloading && (
                <>
                  {youtube.length == 0 && (
                    <input
                      type="file"
                      accept="video/*"
                      id="video-input"
                      style={{ display: 'none' }}
                      onChange={handleVideoSelect}
                    />
                  )}
                  <label htmlFor="video-input">
                    <Button variant="outlined" component={'span'} disabled={youtube.length > 0}>
                      {t('CreateProperty.buttons.4')}
                    </Button>
                  </label>
                </>
              )}

              {selectedVideo && !videoloading && (
                <div
                  style={{
                    width: '100px',
                    height: '100px',
                    position: 'relative',
                    marginRight: 22,
                    marginTop: 10,
                    border: '2px solid',
                    borderColor: palette.primary.main,
                    borderRadius: 15,
                    overflow: 'hidden',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <video src={selectedVideo} width="100%" height="100%" autoPlay muted />
                  <IconButton
                    style={{ position: 'absolute', top: 0, right: 10 }}
                    edge="end"
                    aria-label="delete"
                    onClick={() => setSelectedVideo(null)}
                    color="error"
                  >
                    <Delete />
                  </IconButton>
                </div>
              )}
              {videoloading && (
                <div
                  style={{
                    width: '100px',
                    height: '100px',
                    position: 'relative',
                    marginRight: 22,
                    marginTop: 10,
                    border: '2px solid',
                    borderColor: palette.primary.main,
                    borderRadius: 15,
                    overflow: 'hidden',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <CircularProgress color={'error'} size={20} />
                </div>
              )}
            </div>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
          >
            <TextField
              disabled={selectedVideo != null}
              required
              style={{ width: '100%', marginBottom: 10 }}
              id="outlined-select-currency"
              label={t('CreateProperty.fields.23')}
              color={reqError.title ? 'error' : 'primary'}
              value={youtube}
              onChange={(e) => {
                setyoutube(e.target.value);
                setSelectedVideo(null);
                setvideoId(null);
              }}
              error={reqError?.title}
            />
          </Box>

          {/* OPEN INSPECTION */}
          {/* <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 10
            }}
          >
            <FormControlLabel
              style={{ paddingLeft: 5 }}
              control={
                <Checkbox
                  checked={openInspection}
                  onChange={() => setopenInspection(!openInspection)}
                />
              }
              label={t('CreateProperty.fields.24')}
              className="property_checkbox"
            />
          </Box>
          {openInspection &&
            inspectionList.map((item, index) => {
              return (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20
                  }}
                >
                  <DatePickerMui
                    placeholder={t('CreateProperty.fields.25')}
                    value={item?.date}
                    onChange={(e) => {
                      if (e == null) {
                        updateDateTime(index, 'date', null, true);
                      } else {
                        updateDateTime(index, 'date', e);
                      }
                    }}
                  />

                  <Box
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <TextField
                      style={{ width: '49%' }}
                      id="outlined-select-currency"
                      placeholder={t('CreateProperty.fields.26')}
                      color={'primary'}
                      value={moment(item?.start_time, 'hh:mm A').format('HH:mm')}
                      onChange={(e) => {
                        updateDateTime(index, 'start_time', e.target.value);
                      }}
                      type="time"
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      style={{ width: '49%' }}
                      id="outlined-select-currency"
                      placeholder={t('CreateProperty.fields.27')}
                      color={'primary'}
                      value={moment(item?.end_time, 'hh:mm A').format('HH:mm')}
                      onChange={(e) => {
                        updateDateTime(index, 'end_time', e.target.value);
                      }}
                      type="time"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Box>
              );
            })}
          {openInspection && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 20
              }}
            >
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={() => {
                  addMoreList();
                }}
                style={{ height: 50 }}
              >
                Add Inspection
              </Button>
            </Box>
          )} */}

          {reasonalert.visible && (
            <>
              <Alert
                style={{ marginTop: 20 }}
                onClose={() => {
                  setreasonalert({ visible: false, message: '' });
                  setreqError({});
                }}
                severity="error"
              >
                {reasonalert.message}
              </Alert>
            </>
          )}

          {/* <Box pt={1.5} mt={3} display="flex" justifyContent="flex-end" flexDirection="row">
            <LoadingButton
              style={{ width: 40, marginRight: 5 }}
              size="small"
              variant="contained"
              component={RouterLink}
              to="#"
              loading={loading}
              onClick={() => {
                SaveProperty();
                console.log(uploadedImagesIds);
              }}
            >
              Save
            </LoadingButton>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Box> */}
        </Box>
      </Box>
    </>
  );
}
