import { useEffect, useState } from 'react';
// material
import { Stack, TextField, Button, Box, Typography, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// component

import { api } from 'src/Api';

// ----------------------------------------------------------------------

export default function ReplyModal({ handleClose, refresh, user }) {
  const [loading, setloading] = useState(false);
  const [subject, setsubject] = useState('');
  const [body, setbody] = useState('');

  function sendEmail() {
    if (subject === '') {
      setalert({ visible: true, message: 'Subject is required' });
      return;
    }
    if (body === '' || body === '<p><br></p>') {
      setalert({ visible: true, message: 'Body is required' });
      return;
    }

    var myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', 'Bearer ' + token);
    var formdata = new FormData();
    formdata.append('id', user.id);
    formdata.append('email', user?.user.email);
    formdata.append('subject', subject);
    formdata.append('body', body);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    console.log(body, '/////////////////');
    setloading(true);
    fetch(api.sendEmail, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        console.log(result);
        if (result.success === true) {
          refresh();
        } else {
          setalert({ visible: true, message: result.message });
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error', error);
      })
      .finally(() => setloading(false));
  }

  useEffect(() => {
    if (subject !== '' || body !== '') {
      setalert({ visible: false, message: '' });
    }
  }, [subject, body]);
  const [alert, setalert] = useState({ visible: false, message: '' });

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ];
  return (
    <Box style={{ padding: 20 }}>
      <Stack spacing={3}>
        <Typography py={1} align="center" variant="h5">
          Reply
        </Typography>
        {alert.visible && (
          <>
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity="error"
            >
              {alert.message}
            </Alert>
          </>
        )}

        <TextField
          fullWidth
          label="Subject"
          onChange={(e) => {
            setsubject(e.target.value);
          }}
        />
        {/* <TextField
          fullWidth
          label="Body"
          onChange={(e) => {
            setbody(e.target.value);
          }}
        /> */}
        <Box style={{ minHeight: 250 }}>
          <ReactQuill
            theme="snow"
            value={body}
            style={{ height: '200px' }}
            onChange={setbody}
            modules={modules}
            formats={formats}
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <LoadingButton
            style={{ marginRight: 10 }}
            onClick={() => sendEmail()}
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
          >
            Reply
          </LoadingButton>
          <Button onClick={handleClose} color="error" variant="contained">
            Cancel
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
