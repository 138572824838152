import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink } from 'react-router-dom';
import { api } from 'src/Api';
import pallete from '../../../theme/palette';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

export default function ContactModal({ handleClose, refresh, edit, data }) {
  const { t } = useTranslation();
  const [reasonalert, setreasonalert] = useState({
    visible: false,
    message: 'Please provide reason for declining this business.'
  });

  const [loading, setloading] = useState(false);

  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [min, setmin] = useState('');
  const [max, setmax] = useState('');
  const [phone, setphone] = useState(null);
  const [address, setaddress] = useState(null);

  const [reqError, setreqError] = useState({});

  function SaveContact() {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    edit && formdata.append('id', Encryption(data?.id, process.env.REACT_APP_SECRET_KEY));
    formdata.append('name', Encryption(name, process.env.REACT_APP_SECRET_KEY));
    formdata.append('email', Encryption(email, process.env.REACT_APP_SECRET_KEY));
    phone &&
      phone != undefined &&
      phone != '' &&
      formdata.append('phone_no', Encryption(phone, process.env.REACT_APP_SECRET_KEY));
    address &&
      address != undefined &&
      address != '' &&
      formdata.append('address', Encryption(address, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);

    fetch(api.save_company_contacts, requestOptions)
      .then((response) => response.text())

      .then((result_) => {
        let result = JSON.parse(result_);
        if (result.success === true) {
          refresh();
        } else {
          let error = Object.values(result?.errors)[0][0];
          if (error.includes('name')) {
            setreqError({ name: true });
          }
          if (error.includes('email')) {
            setreqError({ email: true });
          }
          if (error.includes('phone')) {
            setreqError({ phone: true });
          }
          setreasonalert({ visible: true, message: Object.values(result?.errors)[0][0] });
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
      });
  }

  useEffect(() => {
    if (edit) {
      setname(data?.name);
      setemail(data?.email);
      setphone(data?.phone_no);
      setaddress(data?.address);
    }
  }, []);

  return (
    <>
      <Box
        pt={2}
        pb={2}
        sx={{
          backgroundColor: 'white',
          position: 'sticky',
          top: 0,
          borderBottom: '2px solid',
          borderColor: pallete.primary.main,
          zIndex: 5
        }}
      >
        <Typography pl={3.5} variant="h4">
          {t('ContactModal.name')}
        </Typography>
      </Box>
      <Box p={3} pt={1}>
        <Box p={1}>
          {/* Name AND Email */}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
          >
            <TextField
              required
              style={{ width: '100%', marginBottom: 20 }}
              id="outlined-select-currency"
              label={t('ContactModal.fields.0')}
              color={reqError.name ? 'error' : 'primary'}
              value={name}
              onChange={(e) => setname(e.target.value)}
              error={reqError?.name}
            />

            <TextField
              required
              style={{ width: '100%' }}
              id="outlined-select-currency"
              label={t('ContactModal.fields.1')}
              color={reqError?.email ? 'error' : 'primary'}
              value={email}
              onChange={(e) => setemail(e.target.value)}
              error={reqError?.email}
            />
          </Box>
          {/* Phone Number */}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
          >
            <TextField
              required
              style={{ width: '100%' }}
              id="outlined-select-currency"
              label={t('ContactModal.fields.2')}
              value={phone}
              onChange={(e) => setphone(e.target.value)}
              type="number"
              color={reqError?.phone ? 'error' : 'primary'}
              error={reqError?.phone}
            />
          </Box>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
          >
            <TextField
              style={{ width: '100%' }}
              id="outlined-select-currency"
              label={'Address'}
              color={'primary'}
              value={address}
              onChange={(e) => setaddress(e.target.value)}
            />
          </Box>

          {/* Min & Max  */}
          {/* <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20
            }}
          >
            <TextField
              style={{ width: '49%' }}
              id="outlined-select-currency"
              label={t('ContactModal.fields.3')}
              color={'primary'}
              value={min}
              onChange={(e) => setmin(e.target.value)}
              type="number"
            />
            <TextField
              style={{ width: '49%' }}
              id="outlined-select-currency"
              label={t('ContactModal.fields.4')}
              color={'primary'}
              value={max}
              onChange={(e) => setmax(e.target.value)}
              type="number"
            />
          </Box> */}

          {reasonalert.visible && (
            <>
              <Alert
                style={{ marginTop: 20 }}
                onClose={() => {
                  setreasonalert({ visible: false, message: '' });
                  setreqError({});
                }}
                severity="error"
              >
                {/* {Object.values(reasonalert?.message)[0][0]} */}
                {reasonalert.message}
              </Alert>
            </>
          )}

          <Box pt={1.5} mt={3} display="flex" justifyContent="flex-end" flexDirection="row">
            <LoadingButton
              style={{ width: 40, marginRight: 5 }}
              size="small"
              variant="contained"
              component={RouterLink}
              to="#"
              loading={loading}
              onClick={() => {
                SaveContact();
              }}
            >
              {t('ContactModal.buttons.1')}
            </LoadingButton>
            <Button variant="outlined" onClick={handleClose}>
              {t('ContactModal.buttons.0')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
