// material
import react, { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Grid, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// component
import Iconify from '../../../components/Iconify';
import { api } from '../../../Api';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter,
  height: 250
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

const TOTAL = 714000;

export default function TotalBusinesses({ total }) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      {/* PROPERTIES */}
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="eva:shopping-bag-fill" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.total_properties)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              {t('dashboard.box.0')}
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      {/* COMPANY */}
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="eva:shopping-bag-fill" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.total_companies)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              {t('dashboard.box.1')}
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      {/* USERS */}
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="carbon:user-avatar-filled" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.total_users)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              {t('dashboard.box.2')}
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      {/* AGENTS */}
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="eos-icons:admin" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.total_realtors)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              {t('dashboard.box.3')}
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
    </Grid>
  );
}
