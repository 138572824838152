// material
import react, { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Grid, Box } from '@mui/material';
// utils
import CircularProgress from '@mui/material/CircularProgress';

import { fShortenNumber } from '../../../utils/formatNumber';

// component
import Iconify from '../../../components/Iconify';
import { api } from '../../../Api';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

const TOTAL = 714000;

export default function TotalBusinesses({ total }) {
  const [loading, setloading] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="bxs:offer" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.total_business_offers)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Offers
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="bxs:offer" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.approved_business_offers)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Approved
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="bxs:offer" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.pending_business_offers)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Pending
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="bxs:offer" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.declined_business_offers)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Declined
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
    </Grid>
  );
}
