import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import { api } from 'src/Api';

export default function TypeDropDown({ setFunction, width, error, dontAppend }) {
  const [types, setTypes] = useState([]);

  function getTypes() {
    const requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    fetch(api.getoffertype, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          dontAppend ? setTypes(result.data) : setTypes([{ id: 0, name: 'All' }, ...result.data]);
        }
      })
      .catch((error) => console.log('error', error));
  }

  const handleChange = (event) => {
    setFunction(event.target.value);
  };

  useEffect(() => {
    getTypes();
  }, []);
  return (
    <TextField
      error={error}
      color={error ? 'error' : 'primary'}
      fullWidth
      style={{ width: width }}
      select
      label={'Type'}
      onChange={handleChange}
    >
      {types.map((option) => (
        <MenuItem key={option.id} value={option}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
