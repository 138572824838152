import React, { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  CardContent,
  Divider,
  TextField,
  Typography,
  Stack,
  Avatar
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { api } from '../../../Api';
import GooglePlaces from 'src/components/GooglePlaces';
import { Decryption, Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

export default function AccountModal({ handleClose }) {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('user'));
  const [loading, setloading] = useState(false);
  const [alert, setalert] = useState({ visible: false, message: '' });

  function SaveProfile() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    formdata.append('suburbs', Encryption(suburb, process.env.REACT_APP_SECRET_KEY));
    formdata.append('country', Encryption(country, process.env.REACT_APP_SECRET_KEY));
    formdata.append('city', Encryption(city, process.env.REACT_APP_SECRET_KEY));
    formdata.append('street', Encryption(streetname, process.env.REACT_APP_SECRET_KEY));
    formdata.append('suburbs', Encryption(suburb, process.env.REACT_APP_SECRET_KEY));
    formdata.append('state', Encryption(state, process.env.REACT_APP_SECRET_KEY));
    formdata.append('postcode', Encryption(postal_code, process.env.REACT_APP_SECRET_KEY));
    formdata.append('house_no', Encryption(streetnumber, process.env.REACT_APP_SECRET_KEY));
    formdata.append('num_of_realtors', Encryption(agents, process.env.REACT_APP_SECRET_KEY));
    formdata.append('firstname', Encryption(firstName, process.env.REACT_APP_SECRET_KEY));
    formdata.append('lastname', Encryption(lastName, process.env.REACT_APP_SECRET_KEY));
    formdata.append('email', Encryption(email, process.env.REACT_APP_SECRET_KEY));
    formdata.append('contact', Encryption(contact, process.env.REACT_APP_SECRET_KEY));
    formdata.append('address', Encryption(address, process.env.REACT_APP_SECRET_KEY));
    birthday && formdata.append('dob', Encryption(birthday, process.env.REACT_APP_SECRET_KEY));
    if (file) {
      formdata.append('avatar', file.file);
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.updateProfile, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const response = JSON.parse(result);
        if (response.success === true) {
          let decryptedData = Decryption(response.data);
          localStorage.setItem('user', decryptedData);
          handleClose();
        } else {
          setalert({ visible: true, message: 'Something went wrong' });
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => setloading(false));
  }

  const [file, setfile] = useState({ image: user.avatar, file: '' });
  const [firstName, setfirstName] = useState(user.firstname);
  const [lastName, setlastName] = useState(user.lastname);
  const [email, setemail] = useState(user.email);
  const [contact, setcontact] = useState(user.contact);
  const [address, setaddress] = useState(user.address);
  const [birthday, setbirthday] = useState(user?.dob);
  const [agents, setagents] = useState(user?.num_of_realtors);
  const [streetnumber, setstreetnumber] = useState('street_number');
  const [streetname, setstreetname] = useState('');
  const [suburb, setsuburb] = useState('');
  const [city, setcity] = useState('');
  const [state, setstate] = useState('');
  const [country, setcountry] = useState('');
  const [postal_code, setpostal_code] = useState('');

  const [objAfter, setobjAfter] = useState({
    firstName: user?.firstname,
    lastName: user?.lastname,
    email: user?.email,
    contact: user?.contact,
    address: user?.address,
    birhtday: user?.dob,
    file: false
  });

  return (
    <Box p={1} mt={2}>
      <Typography variant="h4" style={{ fontWeight: 'bold', textAlign: 'center' }}>
        {t('EditProfile.name')}
      </Typography>

      <CardContent>
        <Stack
          mb={2}
          direction={{ xs: 'column' }}
          style={{ display: 'flex', flexDirection: 'row' }}
          className="image_center"
        >
          <Box
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {file && (
              <Avatar
                alt="Remy Sharp"
                src={objAfter?.file ? file.image : file.image + `?v='${Date.now()}`}
                sx={{ width: 80, height: 80 }}
                className="image_center"
              />
            )}
            <Button
              style={{
                width: !file ? 80 : null,
                height: !file ? 80 : 30,
                borderRadius: !file ? 40 : 20,
                marginTop: 10,
                marginBottom: 10,
                fontSize: 12
              }}
              variant={file ? 'outlined' : 'outlined'}
              component="label"
            >
              {t('EditProfile.buttons.0')}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => {
                  setobjAfter({
                    file: true
                  });
                  setfile({
                    image: URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0]
                  });
                }}
              />
            </Button>
          </Box>
        </Stack>
        <Grid container spacing={2} className="top_column">
          {/* FIRST NAME / LAST NAME */}
          {user?.role !== 4 && (
            <>
              <Grid item lg={6} sm={12} md={12}>
                <TextField
                  id="outlined-error-helper-text"
                  fullWidth
                  label={t('EditProfile.fields.0')}
                  type="text"
                  value={firstName}
                  onChange={(val) => {
                    setfirstName(val.target.value);
                  }}
                  variant="outlined"
                  display="inline"
                />
              </Grid>
              <Grid item lg={6} sm={12} md={12}>
                <TextField
                  id="outlined-error-helper-text"
                  fullWidth
                  label={t('EditProfile.fields.1')}
                  value={lastName}
                  onChange={(val) => {
                    setlastName(val.target.value);
                  }}
                  type="text"
                  variant="outlined"
                  display="inline"
                />
              </Grid>
            </>
          )}
          {user?.role === 4 && (
            <Grid item lg={6} sm={12} md={12}>
              <TextField
                id="outlined-error-helper-text"
                fullWidth
                label={t('EditProfile.fields.7')}
                value={firstName}
                onChange={(val) => {
                  setfirstName(val.target.value);
                }}
                type="text"
                variant="outlined"
                display="inline"
              />
            </Grid>
          )}

          <Grid item lg={6} sm={12} md={12}>
            <TextField
              id="outlined-error-helper-text"
              fullWidth
              label={t('EditProfile.fields.2')}
              value={email}
              onChange={(val) => {
                setemail(val.target.value);
              }}
              type="text"
              variant="outlined"
              display="inline"
            />
          </Grid>
          <Grid item lg={6} sm={12} md={12}>
            <TextField
              id="outlined-error-helper-text"
              fullWidth
              label={t('EditProfile.fields.3')}
              name="contact"
              value={contact}
              onChange={(val) => {
                setcontact(val.target.value);
              }}
              type="tel"
              variant="outlined"
              display="inline"
            />
          </Grid>
          <Grid item lg={6} sm={12} md={12}>
            <GooglePlaces
              width={'100%'}
              onResult={(val) => {
                setaddress(val?.address);
                setstreetname(val.streetname);
                setstreetnumber(val.streetnumber);
                setsuburb(val.suburb);
                setpostal_code(val.postal_code);
                setstate(val.state);
                setcountry(val.country);
                setcity(val.city);
              }}
              address={user?.address}
              placeholder={t('EditProfile.fields.4')}
            />
          </Grid>
          {user?.role === 4 && (
            <Grid item lg={6} sm={12} md={12}>
              <TextField
                fullWidth
                id="outlined-select-currency"
                label={t('EditProfile.fields.6')}
                color={'primary'}
                value={agents}
                onChange={(e) => {
                  setagents(e.target.value);
                }}
                type="number"
                inputProps={{
                  min: 0,
                  max: 10
                }}
              />
            </Grid>
          )}
          {user?.role !== 4 && (
            <Grid item lg={6} sm={12} md={12}>
              <TextField
                fullWidth
                id="outlined-select-currency"
                label={t('EditProfile.fields.5')}
                color={'primary'}
                value={birthday}
                onChange={(e) => {
                  setbirthday(e.target.value);
                }}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Divider />
      {alert && alert.visible && <Alert severity="error">{alert.message}</Alert>}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <LoadingButton
          disabled={false}
          loading={loading}
          sx={{
            marginRight: 1
          }}
          onClick={() => {
            SaveProfile();
          }}
          color="primary"
          variant="contained"
        >
          {t('EditProfile.buttons.1')}
        </LoadingButton>
        <LoadingButton
          sx={{
            marginRight: 1
          }}
          onClick={handleClose}
          variant="outlined"
        >
          {t('EditProfile.buttons.2')}
        </LoadingButton>
      </Box>
    </Box>
  );
}
