import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Link, CircularProgress, TextField } from '@mui/material';
import Highlighter from 'react-highlight-words';
import palette from 'src/theme/palette';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink } from 'react-router-dom';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImgsViewer from 'react-images-viewer';
import Label from 'src/components/Label';
import { api } from 'src/Api';
import RejectModal from './RejectModal';
import Iconify from 'src/components/Iconify';
import format from 'date-fns/format';

export default function OfferDescription({ handleClose, details, refresh, permission }) {
  const [loading, setloading] = useState(false);
  const [imagesMultiple, setimagesMultiple] = useState([]);
  const [fullImage, setfullImage] = useState(false);
  const [mainLength, setmainLength] = useState(0);

  function imagesArray() {
    setmainLength(imagesMultiple.length);
    imagesMultiple.length = 0;
    if (details.images?.length > 0) {
      details.images?.map((item, index) => {
        setimagesMultiple((imagesMultiple) => [
          ...imagesMultiple,
          {
            src: item.image
          }
        ]);
      });
    }
  }

  useEffect(() => {
    imagesArray();
  }, []);

  const [index, setindex] = useState(0);

  function gotoPrevious() {
    if (index >= 0 && index <= mainLength) {
      setindex(index - 1);
    }
  }

  function gotoNext() {
    if (index >= 0 && index < mainLength) {
      setindex(index + 1);
    }
  }

  function settingFullImage(index) {
    imagesArray();
    setindex(index);
    setfullImage(true);
  }

  const heading = { fontSize: 14, fontWeight: 'bold', width: 90 };

  const [timelineLoading, settimelineLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [logs, setlogs] = useState([]);

  function getTimeline() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('event_type', 'offer');
    formdata.append('entity_id', details?.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_event_logs, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setlogs(result.data);
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        settimelineLoading(false);
      });
  }

  function offerReturn(offer) {
    if (
      (offer.offer_type === 'Percentage' || offer.offer_type === 'Amount') &&
      offer.offer_frequency !== 'Custom' &&
      offer.offer_frequency !== 'Ends On'
    ) {
      return (
        (offer.offer_type === 'Percentage'
          ? offer.offer_value + '% off'
          : '$' + offer.offer_value + ' off') +
        ' ' +
        (offer.offer_item !== '' && offer.offer_item) +
        ' ' +
        offer.offer_frequency
      );
    } else if (
      (offer.offer_type === 'Percentage' || offer.offer_type === 'Amount') &&
      offer.offer_frequency === 'Custom'
    ) {
      return (
        (offer.offer_type === 'Percentage'
          ? offer.offer_value + '% off '
          : '$' + offer.offer_value + ' off ') +
        (offer.offer_item !== '' && offer.offer_item) +
        ' Valid From ' +
        FormatDate(offer.start_date) +
        ' To ' +
        FormatDate(offer.end_date)
      );
    } else if (
      (offer.offer_type === 'Percentage' || offer.offer_type === 'Amount') &&
      offer.offer_frequency === 'Ends On'
    ) {
      return (
        ' ' +
        offer?.offer_item +
        ' ' +
        (offer.offer_type === 'Percentage'
          ? offer.offer_value + '% off'
          : '$' + offer.offer_value + ' off') +
        ' ' +
        ' Until  ' +
        FormatDate(offer.end_date)
      );
    } else if (
      offer.offer_type !== 'Percentage' &&
      offer.offer_type !== 'Amount' &&
      offer.offer_type !== 'Custom' &&
      offer.offer_frequency !== 'Ends On' &&
      offer.offer_frequency !== 'Custom'
    )
      return (
        offer.offer_type +
        (offer.offer_item !== '' && ' ' + offer.offer_item + ' ') +
        ' ' +
        offer.offer_frequency
      );
    else if (
      offer.offer_type !== 'Percentage' &&
      offer.offer_type !== 'Amount' &&
      offer.offer_type !== 'Custom' &&
      offer.offer_frequency === 'Ends On'
    )
      return (
        offer.offer_type +
        (offer.offer_item !== '' && ' ' + offer.offer_item + ' ') +
        ' Until ' +
        FormatDate(offer.end_date)
      );
    else if (
      offer.offer_type !== 'Percentage' &&
      offer.offer_type !== 'Amount' &&
      offer.offer_type !== 'Custom' &&
      offer.offer_frequency === 'Custom'
    )
      return (
        offer.offer_type +
        (offer.offer_item !== '' && ' ' + offer.offer_item + ' ') +
        ' Valid From ' +
        FormatDate(offer.start_date) +
        ' To ' +
        FormatDate(offer.end_date)
      );
    else if (offer.offer_type === 'Custom' && offer.offer_frequency === 'Custom')
      return (
        offer.offer_item +
        ' Valid From ' +
        FormatDate(offer.start_date) +
        ' To ' +
        FormatDate(offer.end_date)
      );
    else if (offer.offer_type === 'Custom' && offer.offer_frequency === 'Ends On')
      return offer.offer_item + ' Until ' + FormatDate(offer.end_date);
    else if (
      offer.offer_type === 'Custom' &&
      offer.offer_frequency !== 'Ends on' &&
      offer.offer_frequency !== 'Custom'
    )
      return offer.offer_item + ' ' + offer.offer_frequency;
  }

  function FormatDate(date) {
    const myArray = date.split(' ');
    const formatedDate = format(Date.parse(myArray[0]), 'MM-dd-yyyy');
    return formatedDate;
  }

  function offerApprove(id, status) {
    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    formdata.append('offerid', id);
    formdata.append('status', status);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.approveOffers, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          refresh();
        } else {
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
      });
  }

  const [rejectAlert, setrejectAlert] = useState({ visible: false, message: '' });
  const [reason, setreason] = useState('');
  const [rejectLoading, setrejectLoading] = useState(false);

  function OfferReject() {
    if (rejectAlert.visible === false) {
      setrejectAlert({
        visible: true,
        message: 'Please provide reason for declining this offer.'
      });
      return;
    }
    if (rejectAlert.visible === true) {
      setrejectLoading(true);
      const token = localStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${token}`);
      var formdata = new FormData();
      formdata.append('offerid', details.id);
      formdata.append('status', 'declined');
      formdata.append('reason', '');
      formdata.append('comment', reason);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(api.approveOffers, requestOptions)
        .then((response) => response.text())
        .then((result_) => {
          const result = JSON.parse(result_);
          if (result.success === true) {
            refresh();
          }
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => setrejectLoading(false));
    }
  }

  return (
    <Box>
      <Box
        p={2}
        style={{
          borderBottom: '2px solid',
          borderBottomColor: palette.primary.main,
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between'
        }}
      >
        <Box px={2} maxWidth={'60%'}>
          <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
            {offerReturn(details)}
          </Typography>
          <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
            {details.businessname}
          </Typography>
        </Box>
        <Box px={2} style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap' }}>
          <Label
            style={{ height: 30, marginRight: 5 }}
            variant="ghost"
            color={
              (details.status === 'active' && 'success') ||
              (details.status === 'pending' && 'primary') ||
              'error'
            }
          >
            {details.status === 'active'
              ? 'Approved'
              : details.status === 'pending'
              ? 'Pending'
              : 'Declined'}
          </Label>
          {details.status === 'pending' && (
            <>
              {permission?.includes('decline') && (
                <LoadingButton
                  variant="contained"
                  size="small"
                  style={{ width: 40, marginRight: 5 }}
                  component={RouterLink}
                  color="error"
                  to="#"
                  onClick={() => OfferReject()}
                  loading={rejectLoading}
                >
                  Decline
                </LoadingButton>
              )}

              {permission.includes('approve') && (
                <LoadingButton
                  style={{ width: 40, marginRight: 5 }}
                  color="primary"
                  size="small"
                  variant="contained"
                  component={RouterLink}
                  to="#"
                  onClick={() => {
                    setloading(true);
                    offerApprove(details.id, 'active');
                  }}
                  loading={loading}
                >
                  Approve
                </LoadingButton>
              )}
            </>
          )}
          {details.status === 'active' && (
            <>
              {permission?.includes('decline') && (
                <LoadingButton
                  variant="contained"
                  size="small"
                  style={{ width: 40, marginRight: 5 }}
                  component={RouterLink}
                  color="error"
                  to="#"
                  onClick={() => OfferReject()}
                  loading={rejectLoading}
                >
                  Decline
                </LoadingButton>
              )}
            </>
          )}
          <Button color="primary" variant="contained" onClick={handleClose} size={'small'}>
            Close
          </Button>
        </Box>
      </Box>
      {rejectAlert.visible && (
        <Box p={4}>
          <Alert
            action={
              <>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setrejectAlert({ visible: false, message: '' })}
                >
                  CANCEL
                </Button>
              </>
            }
            severity="primary"
          >
            {rejectAlert.message}
          </Alert>
          <Box>
            <TextField
              style={{ width: '100%', marginTop: 10 }}
              id="outlined-multiline-flexible"
              label="Comments"
              multiline
              color="primary"
              maxRows={4}
              value={reason}
              onChange={(e) => setreason(e.target.value)}
            />
          </Box>
        </Box>
      )}
      {/* {rejectmodal && (
        <RejectModal handleClose={handleClose} details={details.id} refresh={refresh} />
      )} */}

      <Box p={2} display="flex" flexDirection="row" justifyContent="start">
        <Button
          style={{
            width: 135,
            marginRight: 5,
            color: 'black',
            backgroundColor: value == 0 ? palette.primary.main : 'silver'
          }}
          color="primary"
          variant="contained"
          onClick={() => {
            setValue(0);
          }}
        >
          View Offer
        </Button>
        <Button
          style={{
            width: 135,
            color: 'black',
            backgroundColor: value == 1 ? palette.primary.main : 'silver'
          }}
          color="primary"
          variant="contained"
          onClick={() => {
            setValue(1);
            settimelineLoading(true);
            getTimeline();
          }}
        >
          Timeline
        </Button>
      </Box>

      {value == 0 && (
        <Box px={4} py={2}>
          <ImgsViewer
            imgs={imagesMultiple}
            currImg={index}
            onClickPrev={gotoPrevious}
            onClickNext={gotoNext}
            isOpen={fullImage}
            onClose={() => setfullImage(false)}
          />

          {details?.images?.length > 0 && (
            <Box mb={2}>
              <ImageList
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
                cols={5}
                rowHeight={120}
              >
                {details.images.map((item, index) => (
                  <ImageListItem
                    style={{
                      borderRadius: 15,
                      overflow: 'hidden',
                      border: '2px solid',
                      borderColor: palette.primary.main,
                      marginRight: 5
                    }}
                    onClick={() => settingFullImage(index)}
                    key={item.index}
                  >
                    <img
                      style={{ width: 100 }}
                      src={item.image}
                      alt={'Business Image'}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          )}

          {details?.description != '' && (
            <Box py={1} style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography pr={1} sx={{ fontSize: 14, fontWeight: 'bold' }} component="div">
                Description:
              </Typography>
              <Typography row sx={{ fontSize: 14 }} component="div">
                {details.description}
              </Typography>
            </Box>
          )}

          <Box
            py={0.5}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <Box display={'flex'} row>
              <Typography pr={1} sx={{ fontSize: 14, fontWeight: 'bold' }} component="div">
                Added on:
              </Typography>
              <Typography row sx={{ fontSize: 14 }} component="div">
                {details?.submitted?.date}
              </Typography>
            </Box>
            {details.status == 'active' || details.status == 'declined' ? (
              <>
                <Box display={'flex'} row>
                  <Typography pr={1} sx={{ fontSize: 14, fontWeight: 'bold' }} component="div">
                    {details.status == 'active' ? 'Approved' : 'Declined'} on:
                  </Typography>
                  <Typography row sx={{ fontSize: 14 }} component="div">
                    {details?.approved_or_declined?.date}
                  </Typography>
                </Box>
                <Box display={'flex'} row>
                  <Typography pr={1} sx={{ fontSize: 14, fontWeight: 'bold' }} component="div">
                    By:
                  </Typography>
                  <Typography row sx={{ fontSize: 14 }} component="div">
                    {details?.approved_or_declined?.user}
                  </Typography>
                </Box>
              </>
            ) : null}
          </Box>

          {/* {details.status === 'pending' && (
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography sx={heading} component="div">
                Termination:
              </Typography>

              <Typography row sx={{ fontSize: 14 }} component="div">
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => window.open('https://' + details?.website)}
                >
                  {' '}
                  {details?.link}
                </Link>
              </Typography>
            </Box>
          )} */}
          {details?.link != '' && (
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography sx={heading} component="div">
                Link:
              </Typography>

              <Typography row sx={{ fontSize: 14 }} component="div">
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      details?.link?.includes('https://', 'HTTPS://', 'http://', 'HTTP://')
                        ? details?.link
                        : 'https://' + details?.link,
                      ''
                    )
                  }
                >
                  {' '}
                  {details?.link}
                </Link>
              </Typography>
            </Box>
          )}

          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={heading} component="div">
              Used by:
            </Typography>

            <Typography row sx={{ fontSize: 14 }} component="div">
              {details?.redeem_count}
            </Typography>
          </Box>

          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={heading} component="div">
              Offer type:
            </Typography>

            <Typography row sx={{ fontSize: 14 }} component="div">
              {details?.offer_type}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={heading} component="div">
              Frequency:
            </Typography>

            <Typography row sx={{ fontSize: 14 }} component="div">
              {details?.offer_frequency}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={heading} component="div">
              Online offer:
            </Typography>

            <Label
              style={{ height: 20, textTransform: 'capitalize' }}
              variant="ghost"
              color={details?.is_online ? 'success' : 'error'}
            >
              {details?.is_online.toString()}
            </Label>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={heading} component="div">
              Shared by:
            </Typography>

            <Typography row sx={{ fontSize: 14 }} component="div">
              {details?.post_count}
            </Typography>
          </Box>
        </Box>
      )}
      {value == 1 && (
        <>
          {timelineLoading ? (
            <Box
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                height: 250
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              py={2}
              px={10}
              sx={{
                height: 400,
                overflow: 'auto'
              }}
            >
              {logs?.map((item, index) => {
                return (
                  <Box py={0.5} flexDirection={'row'} display={'flex'}>
                    <Box width={'30%'} mr={3}>
                      <Typography fontSize={14} component={'div'}>
                        {' '}
                        {moment(item.created_at).format('DD/MM/YYYY')}
                      </Typography>
                    </Box>
                    <Box width={'70%'}>
                      <Highlighter
                        highlightStyle={{
                          backgroundColor: 'transparent',
                          fontWeight: 'bold'
                        }}
                        searchWords={[
                          'approved',
                          'pending',
                          'expired',
                          'declined',
                          'updated',
                          'seen',
                          'created'
                        ]}
                        autoEscape={true}
                        textToHighlight={item.comments}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </>
      )}
      {/* <Box p={4} display="flex" justifyContent="flex-end" flexDirection="row"></Box> */}
    </Box>
  );
}
