import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography, Stack } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { ChangePasswordForm } from 'src/sections/authentication/changePassword';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 4),
  padding: theme.spacing(5),
  backgroundColor: theme.palette.drawerBackground
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ChangePassword() {
  return (
    <RootStyle title={`Change Password | ${process.env.REACT_APP_PROJECTNAME}`}>
      <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
        <img src={process.env.REACT_APP_LOGO} alt="Logo" />
      </SectionStyle>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 2 }}>
            <Typography variant="h5" gutterBottom>
              Change your password
            </Typography>
          </Stack>

          <ChangePasswordForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
