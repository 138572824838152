import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Table,
  TableHead,
  TableBody,
  Stack,
  Avatar,
  TableCell,
  TableRow,
  InputAdornment,
  IconButton,
  TextField
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { api } from 'src/Api';
import palette from '../../../theme/palette';
import { Decryption, Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function ViewsModal({ handleClose, refresh, data }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [FilteredData, setFilteredData] = useState([]);

  function getLogs(refresh) {
    !refresh && setLoading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append('entity_id', Encryption(data, process.env.REACT_APP_SECRET_KEY));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_view_logs, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          let decryptedData = JSON.parse(Decryption(result.data));
          console.log(decryptedData);
          setList(decryptedData);
          setFilteredData(decryptedData);
        } else {
          setList([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getLogs();
  }, []);

  return (
    <>
      {/* MODAL HEADER */}
      <Box
        flexDirection="row"
        justifyContent="space-between"
        display="flex"
        pt={2}
        pb={2}
        sx={{
          backgroundColor: 'white',
          position: 'sticky',
          top: 0,
          borderBottom: '2px solid',
          borderColor: palette.primary.main,
          zIndex: 5
        }}
      >
        <Typography pl={3.5} variant="h4">
          {t('ViewsModal.heading')}
        </Typography>
        <Button
          sx={{
            marginRight: 3.5
          }}
          color="primary"
          variant="outlined"
          onClick={handleClose}
        >
          {t('ViewsModal.buttons.0')}
        </Button>
      </Box>

      <Box p={3} pt={1}>
        {/* TEXT FIELD BOX */}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 10,
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1,
            marginBottom: 10
          }}
        >
          <TextField
            required
            style={{ width: '100%' }}
            id="outlined-select-currency"
            label={'Search'}
            color={'primary'}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setFilteredData(
                  list.filter(
                    (item) =>
                      item?.user_id?.firstname.includes(e.target.value) ||
                      item?.user_id?.lastname.includes(e.target.value)
                  )
                );
              } else {
                setFilteredData(list);
              }
            }}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </Box>
        {/* LOADER AND NOTES LIST */}
        <Box p={1} pb={5} style={{ height: 'calc(100vh - 250px)', overflowY: 'auto' }}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {FilteredData.length > 0 && (
                <Table sx={{ width: '100%' }} aria-label="simple table">
                  <TableHead
                  // style={{ position: 'sticky', top: 55, backgroundColor: 'white', paddingBottom: 5 }}
                  >
                    <TableRow>
                      <TableCell>{t('ViewsModal.table.0')}</TableCell>
                      <TableCell>{t('ViewsModal.table.1')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {FilteredData.map((item, index) => {
                      const { document, user_id } = item;
                      return (
                        <TableRow hover key={index} tabIndex={-1} role="checkbox">
                          <TableCell scope="row" padding="normal" style={{ maxWidth: '50%' }}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar alt={user_id.firstname} src={`${user_id.avatar}`} />
                              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="subtitle2" flexWrap={'wrap'}>
                                  {user_id.firstname} {user_id.lastname}
                                </Typography>
                              </Box>
                            </Stack>
                          </TableCell>

                          <TableCell style={{ fontWeight: 'bold' }}>
                            {moment(item.created_at).format('DD/MM/YYYY hh:mm A')}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </>
          )}
          {FilteredData.length == 0 && !loading && (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
              <Typography>{t('ViewsModal.empty')}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
