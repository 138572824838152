import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, MenuItem, Checkbox, Typography } from '@mui/material';

import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink } from 'react-router-dom';
import { api } from 'src/Api';

export default function TerminateModal({ handleClose, user, refresh, isUser }) {
  const [reasonalert, setreasonalert] = useState({
    visible: true,
    message: 'Please provide reason for terminating (Optional)'
  });

  const [loading, setloading] = useState(false);

  function TerminateUser() {
    setloading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();

    formdata.append('user_id', user?.id);
    formdata.append('status', 'terminated');
    formdata.append('reason_termination', reason);
    formdata.append('allow_rehire', allowRehire ? '1' : '0');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.terminateadmin, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          refresh();
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setloading(false);
      });
  }

  const [reason, setReason] = useState('');

  const [allowRehire, setallowRehire] = useState(false);
  return (
    <Box>
      <Box p={1}>
        {reasonalert.visible && (
          <>
            <Alert severity="warning">{reasonalert.message}</Alert>
            <Box style={{ marginTop: 15 }}>
              <TextField
                style={{ width: '100%', marginTop: 10 }}
                label="Reason"
                multiline
                color="primary"
                rows={5}
                maxRows={5}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
              <Box
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  marginTop: 10,
                  alignItem: 'center'
                }}
              >
                <Checkbox
                  sx={{
                    marinLeft: 5,
                    padding: 0
                  }}
                  checked={allowRehire}
                  onChange={() => {
                    setallowRehire(!allowRehire);
                  }}
                />
                <Typography ml={1} variant="substitute1">
                  Allow Rehire?
                </Typography>
              </Box>
            </Box>
          </>
        )}
        <Box pt={1.5} display="flex" justifyContent="flex-end" flexDirection="row">
          <LoadingButton
            style={{ marginRight: 5 }}
            color="error"
            size="small"
            variant="contained"
            component={RouterLink}
            to="#"
            onClick={() => TerminateUser()}
            loading={loading}
          >
            Terminate
          </LoadingButton>
          <Button
            style={{ marginRight: 10 }}
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
