import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import StatusDropDown from 'src/layouts/dashboard/StatusDropDown';
import { LoadingButton } from '@mui/lab';

// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Pagination,
  Modal,
  Divider,
  CircularProgress
} from '@mui/material';
import pallete from '../theme/palette';
import format from 'date-fns/format';
import { ReplyModal } from '../sections/@dashboard/request';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';

//
import { api } from '../Api';
import { Decryption, Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Inquiry() {
  const { t } = useTranslation();
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'reply'];

  function checkRender() {
    let check = 'enquiry';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0].permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [status, setstatus] = useState('');
  const [searchLoading, setsearchLoading] = useState(false);
  const [loading, setloading] = useState(false);

  function getBugs(page) {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_KEY));
    user.role != 3 &&
      formdata.append('realtor_id', Encryption(user?.id, process.env.REACT_APP_SECRET_KEY));
    search.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_KEY));
    status.length > 0 &&
      status != 'All' &&
      formdata.append('type', Encryption(status, process.env.REACT_APP_SECRET_KEY));
    selectedTopic.length > 0 &&
      selectedTopic != 'All' &&
      formdata.append('about_value', Encryption(selectedTopic, process.env.REACT_APP_SECRET_KEY));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.getRequests, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        //setcount(result.counts);
        if (result.success === true) {
          let decryptedData = JSON.parse(Decryption(result.data));
          setlist(decryptedData);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setsearchLoading(false);
        setloading(false);
      });
  }

  useEffect(() => {
    getBugs();
    getTopics();
  }, []);

  const isUserNotFound = list.length === 0;

  const [isreply, setisreply] = useState(false);

  const [user, setuser] = useState(null);

  function CloseReply() {
    setisreply(false);
  }

  function CloseAndRefresh() {
    setisreply(false);
    getBugs();
  }

  const [topics, settopics] = useState([]);

  const [selectedTopic, setselectedTopic] = useState('');

  function getTopics() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.getFeatures, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          let decryptedata = JSON.parse(Decryption(result.data));
          settopics([{ id: 1000, name: 'All' }, ...decryptedata]);
        }
      })
      .catch((error) => console.log('error', error));
  }

  const style = {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getBugs(value);
  };

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      setsearchLoading(true);
      setPage(1);
      getBugs(1);
    }
  };

  return (
    <Page title={`Enquiry | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Modal
          style={style}
          open={isreply}
          onClose={CloseReply}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle}>
            <ReplyModal handleClose={CloseReply} user={user} refresh={CloseAndRefresh} />
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            {'Enquiries'}
          </Typography>
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between'
            }}
            className="top_column"
          >
            <Searchbar
              placeholder={t('Inquiry.search') + '...'}
              width={topics.length > 0 ? '65%' : '87%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            {topics.length > 0 && (
              <StatusDropDown
                setFunction={(val) => {
                  setselectedTopic(val);
                }}
                data={topics}
                placeholder={t('Inquiry.about')}
              />
            )}

            <LoadingButton
              endIcon={<Iconify icon="material-symbols:search" />}
              style={{ width: '10%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getBugs(1);
              }}
              className="width_full_status"
            />
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, paddingTop: '20px' }}>
              <Divider />
              <Table>
                <TableBody>
                  {list.map((row) => {
                    const { id, status } = row;

                    return (
                      <TableRow
                        onClick={() => {}}
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        style={{
                          backgroundColor: status == 'active' && 'rgba(253,208,151,0.2)',
                          cursor: status == '0' ? 'pointer' : 'default'
                        }}
                      >
                        <TableCell
                          style={{
                            width: '100%'
                          }}
                          component="th"
                          scope="row"
                          padding="normal"
                        >
                          <Stack direction="row" spacing={2} style={{ width: '100%' }}>
                            <Box style={{ width: '100%' }}>
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Typography
                                    variant="heading1"
                                    noWrap
                                    style={{
                                      fontSize: 15,
                                      fontWeight: 'bold',
                                      textTransform: 'capitalize'
                                    }}
                                  >
                                    {row.name}
                                  </Typography>
                                </Box>
                                <Typography variant="subtitle2" noWrap style={{ paddingRight: 10 }}>
                                  {format(new Date(row.created_at), 'MM/dd/yyyy hh:mm aaa')}
                                </Typography>
                              </Box>

                              <Typography
                                variant="subtitle2"
                                noWrap
                                style={{
                                  textTransform: 'capitalize',
                                  fontWeight: 'bold',
                                  fontSize: 15,
                                  width: '90%',
                                  textAlign: 'justify'
                                }}
                              >
                                {row.email}
                              </Typography>

                              <Typography
                                variant="subtitle2"
                                noWrap
                                style={{
                                  textTransform: 'capitalize',

                                  fontSize: 15,
                                  width: '90%',
                                  textAlign: 'justify',
                                  flexDirection: 'row',
                                  display: 'flex'
                                }}
                              >
                                <Typography style={{ fontWeight: 'bold' }}>Contact: </Typography>{' '}
                                {row.contact_number}
                              </Typography>

                              <Typography
                                variant="subtitle2"
                                noWrap
                                style={{
                                  textTransform: 'capitalize',

                                  fontSize: 15,
                                  width: '90%',
                                  textAlign: 'justify',
                                  flexDirection: 'row',
                                  display: 'flex'
                                }}
                              >
                                <Typography style={{ fontWeight: 'bold' }}>About: </Typography>{' '}
                                {row.about}
                              </Typography>

                              <Typography
                                variant="subtitle2"
                                noWrap
                                style={{
                                  textTransform: 'capitalize',
                                  fontSize: 15,
                                  width: '90%',
                                  textAlign: 'justify',
                                  flexDirection: 'row',
                                  display: 'flex'
                                }}
                              >
                                <Typography style={{ fontWeight: 'bold' }}>
                                  Description:{' '}
                                </Typography>
                                {row.reason}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {loading && (
                  <Box
                    py={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flex: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
