import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Button,
  InputAdornment
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { api } from 'src/Api';
import palette from '../../../theme/palette';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { Attachment, AttachmentRounded, Close, VisibilityOutlined } from '@mui/icons-material';
import { Decryption, Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

export default function NotesModal({ handleClose, refresh, data }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setmessage] = useState('');
  const [list, setList] = useState([]);

  function getNotes(refresh) {
    !refresh && setLoading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append('property_id', Encryption(data?.id, process.env.REACT_APP_SECRET_KEY));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_property_comments, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          let decryptedData = JSON.parse(Decryption(result.data));
          setList(decryptedData);
        } else {
          setList([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setLoading(false);
      });
  }
  const [saving, setsaving] = useState(false);

  function saveNote() {
    setcolor(null);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append('comment', Encryption(note, process.env.REACT_APP_SECRET_KEY));
    formdata.append('property_id', Encryption(data?.id, process.env.REACT_APP_SECRET_KEY));
    file && formdata.append('attachment', file);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setsaving(true);
    fetch(api.save_property_comments, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        console.log(result_);
        const result = JSON.parse(result_);
        if (result.success === true) {
          setNote('');
          setfile(null);
          setShowSnackbar(true);
          setmessage(t('NotesModal.snackBar.0'));
          getNotes(refresh);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setsaving(false);
      });
  }

  const [deleting, setdeleting] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(null);

  function DeleteNote(id, index) {
    setcolor(true);
    setdeleting(true);
    setselectedIndex(index);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('id', Encryption(id, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.delete_property_comments, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setShowSnackbar(true);
          setmessage(t('NotesModal.snackBar.1'));
          getNotes(refresh);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setdeleting(false);
        setselectedIndex(null);
      });
  }

  useEffect(() => {
    getNotes();
  }, []);
  const [file, setfile] = useState(null);
  const [color, setcolor] = useState(null);
  return (
    <>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        display="flex"
        pt={2}
        pb={2}
        sx={{
          backgroundColor: 'white',
          position: 'sticky',
          top: 0,
          borderBottom: '2px solid',
          borderColor: palette.primary.main,
          zIndex: 5
        }}
      >
        <Typography pl={3.5} variant="h4">
          {t('NotesModal.name')}
        </Typography>
        <Button
          sx={{
            marginRight: 3.5
          }}
          color="primary"
          variant="outlined"
          onClick={handleClose}
        >
          {t('NotesModal.buttons.0')}
        </Button>
      </Box>

      <Box p={3} pt={1}>
        {/* TEXT FIELD BOX */}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 10,
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1,
            marginBottom: 10
          }}
        >
          {file && (
            <Box
              style={{
                width: '100%',
                marginBottom: 5,
                marginLeft: 20,
                marginRight: 20,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between'
              }}
              flexDirection={'row'}
            >
              <Box flexDirection={'row'} display={'flex'}>
                {/* <AttachmentRounded style={{ marginRight: 10 }} /> */}
                <Typography style={{ marginLeft: 5 }}>{file?.name}</Typography>
              </Box>
              <IconButton
                color="primary"
                component="label"
                onClick={() => {
                  setfile(null);
                }}
              >
                <Close />
              </IconButton>
            </Box>
          )}
          <TextField
            required
            style={{ width: '100%' }}
            id="outlined-select-currency"
            label={t('NotesModal.note')}
            color={'primary'}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {saving ? (
                    <CircularProgress size={20} style={{ marginRight: 5 }} />
                  ) : (
                    <Box flexDirection="row">
                      <IconButton color="primary" component="label">
                        <input
                          type="file"
                          hidden
                          onChange={(e) => {
                            setfile(e.target.files[0]);
                          }}
                        />
                        <AttachmentRounded />
                      </IconButton>
                      <IconButton
                        color="primary"
                        disabled={!note} // Disable button when note is empty
                        onClick={saveNote}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Box>
                  )}
                </InputAdornment>
              )
            }}
          />
        </Box>
        {/* LOADER AND NOTES LIST */}
        <Box p={1} pb={5} style={{ height: 'calc(100vh - 250px)', overflowY: 'auto' }}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '80%' }}>
              <CircularProgress />
            </Box>
          ) : (
            list?.length > 0 &&
            list.map((item, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1px solid lightgray"
                py={1}
              >
                <Typography>{item?.comment}</Typography>
                {selectedIndex == index && deleting ? (
                  <Box p={1}>
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  <Box flexDirection={'row'} display={'flex'}>
                    {item.attachment && (
                      <IconButton
                        color="primary"
                        onClick={() => {
                          window.open(item?.attachment);
                        }}
                      >
                        <VisibilityOutlined />
                      </IconButton>
                    )}

                    <IconButton
                      color="primary"
                      onClick={() => {
                        DeleteNote(item.id, index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))
          )}
          {list.length == 0 && !loading && (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '80%' }}>
              <Typography>{t('NotesModal.empty')}</Typography>
            </Box>
          )}
        </Box>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setShowSnackbar(false);
          }}
        >
          <Alert
            onClose={() => {
              setShowSnackbar(false);
            }}
            severity={color ? 'error' : 'success'}
            sx={{
              width: '100%',
              color: 'black'
            }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}
