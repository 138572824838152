import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
// material
import { Stack, TextField, IconButton, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { api } from 'src/Api';
import Alert from '@mui/material/Alert';
import { Decryption, Encryption } from 'src/functions/function';

// ----------------------------------------------------------------------

export default function ChangePasswordForm() {
  const navigate = useNavigate();
  const checkcode = localStorage.getItem('code'); //GET CODE FROM LOCALSTORAGE
  useEffect(() => {
    const authResult = new URLSearchParams(window.location.search);
    const key = authResult.get('key').replace(/ /g, '+'); // GET KEY FROM URL
    //  const bytes = CryptoJS.AES.decrypt(key, process.env.REACT_APP_SECRET_KEY);
    //   const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); //DECRYPT THE ENCRYPTED CODE RECEIVED FROM URL

    let decryptedData = Decryption(key);
    ExtractEmailAndCode(decryptedData); // SPLIT EMAIL AND CODE FROM DECRYPTED CODE AND CHECK THE VALIDITY
  }, []);
  const [email, setemail] = useState('');
  const [code, setcode] = useState('');

  function ExtractEmailAndCode(str) {
    const keyValuePairs = str.split('&');

    let email = '';
    let code = '';

    // Iterating over the key-value pairs
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split('=');

      if (key === 'email') {
        email = value;
        setemail(value);
      } else if (key === 'code') {
        code = value;
        setcode(value);
      }
    });

    checkCodeVlidity(email, code);

    // if (code != checkcode) {
    //   setdisabled(true);
    //   setalert({ visible: true, message: 'The link has been expired' });
    // }

    // if (code == checkcode) {
    //   localStorage.clear('code');
    // }
  }
  const [alert, setalert] = useState({ visible: false, message: '' });
  const RegisterSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Your passwords do not match.')
  });

  // COMMENT

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      setPassword();
    }
  });

  const { errors, values, touched, handleSubmit, getFieldProps } = formik;
  const [loading, setloading] = useState(false);
  const [disabled, setdisabled] = useState(false);

  function setPassword() {
    var formdata = new FormData();
    formdata.append('email', Encryption(email, process.env.REACT_APP_SECRET_KEY));
    formdata.append('password', Encryption(values.password, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);

    fetch(api.updateforgotpassword, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          setalert({ visible: true, message: result.message });
        } else {
          setalert({ visible: true, message: result.message });
        }
      })
      .catch((error) => alert(error))
      .finally(() => {
        setloading(false);
      });
  }
  const [showPassword, setshowPassword] = useState(false);

  const checkCodeVlidity = (email, code) => {
    let token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('code', Encryption(code, process.env.REACT_APP_SECRET_KEY));
    formdata.append('email', Encryption(email, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.check_code_validity, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
        } else {
          setdisabled(true);
          setalert({ visible: true, message: 'The link has been expired' });
        }
      })
      .catch((error) => console.log('error', error));
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setshowPassword(!showPassword);
                    }}
                    edge="end"
                  >
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setshowPassword(!showPassword);
                    }}
                    edge="end"
                  >
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />

          {alert.visible && (
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity={alert.message.includes('updated') ? 'success' : 'error'}
            >
              {alert.message}
            </Alert>
          )}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            disabled={disabled}
          >
            Save
          </LoadingButton>
          <LoadingButton
            style={{ marginTop: 10 }}
            fullWidth
            size="large"
            variant="outlined"
            onClick={() => {
              navigate('/login');
            }}
          >
            Login
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
