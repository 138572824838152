import { filter } from 'lodash';
import * as React from 'react';
import Box from '@mui/material/Box';
import StatusDropDown from 'src/layouts/dashboard/StatusDropDown';
import { LoadingButton } from '@mui/lab';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { useState, useEffect } from 'react';
import { Link as RouterLink, redirect } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  CircularProgress
} from '@mui/material';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
//

import { api } from '../Api';
import HelpModal from 'src/sections/@dashboard/help/helpModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'help_category', label: 'Category', alignRight: false },
  { id: 'help_topic', label: 'Question', alignRight: false, paddingLeft: 15 },
  { id: 'answer', label: 'Answer', alignRight: false, paddingLeft: 15 }
];

// ----------------------------------------------------------------------

export default function HelpCenter() {
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'upload'];
  const [loading, setloading] = useState(true);
  function checkRender() {
    let check = 'help center';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0].permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);

  function getQuestions() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();

    search.length > 0 && formdata.append('search', search);
    status && status != 'All' && formdata.append('category', status);
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.getHelp, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          setlist(result.data);
        } else {
          setlist([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  useEffect(() => {
    getQuestions();
  }, []);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isUserNotFound = list.length === 0;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };
  //  {/* <UserPassword handleClose={handleClose} userid={userid} /> */}

  const [adminModal, setadminModal] = useState(false);
  const handleAdminClose = () => setadminModal(false);
  const refreshAdmin = () => {
    setadminModal(false);
    getQuestions();
  };

  const [search, setsearch] = useState('');
  const [status, setstatus] = useState(null);
  const [searchLoading, setsearchLoading] = useState(false);

  return (
    <Page title={`Help Center | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Help Center
          </Typography>
          {permission.includes('upload') && (
            <Button
              onClick={() => setadminModal(true)}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Help Question
            </Button>
          )}
        </Stack>

        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={adminModal}
          onClose={handleAdminClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <HelpModal handleClose={handleAdminClose} refresh={refreshAdmin} />
          </Box>
        </Modal>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between'
            }}
          >
            <Searchbar
              width={'50%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
            />
            <StatusDropDown
              width={'30%'}
              setFunction={(val) => {
                setstatus(val);
              }}
              helpcenter={true}
            />
            <LoadingButton
              endIcon={<Iconify icon="material-symbols:search" />}
              style={{ width: '17%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                getQuestions();
              }}
            >
              Search
            </LoadingButton>
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                <TableBody>
                  {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, help_category, help_topic, answer } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="left" style={{ width: '20%', fontWeight: 'bold' }}>
                          {help_category}
                        </TableCell>
                        <TableCell align="left"> {help_topic}</TableCell>
                        <TableCell align="left" noWrap>
                          {answer}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
