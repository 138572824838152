import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Avatar,
  MenuItem
} from '@mui/material';
import { api } from 'src/Api';
import { LoadingButton } from '@mui/lab';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImgsViewer from 'react-images-viewer';
import palette from 'src/theme/palette';
import ListModal from 'src/sections/@dashboard/documents/listModal';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import { Decryption, Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

export default function UserModal({ handleClose, details, permission, termination, refresh }) {
  const { t } = useTranslation();
  const [userpermission, setuserpermission] = useState([]);
  const requirement = ['visible', 'terminate'];
  function checkRender() {
    let check = 'users';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setuserpermission((permissions) => [
            ...permissions,
            items.permission_type_name.toLowerCase()
          ])
        : false
    );
  }

  useEffect(() => {
    console.log(permission);
    checkRender();
  }, []);

  const [loading, setloading] = useState(false);
  const [imagesMultiple, setimagesMultiple] = useState([]);
  const [fullImage, setfullImage] = useState(false);
  const [mainLength, setmainLength] = useState(0);
  const [value, setValue] = useState(0);
  const [allowed_realtors, setAllowedRealtors] = useState(details.allowed_realtors);

  const [alert, setalert] = useState({ visible: false, message: '' });

  function UpdateAllowedAgents() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('id', Encryption(details.id, process.env.REACT_APP_SECRET_KEY));
    formdata.append(
      'allowed_realtors',
      Encryption(allowed_realtors, process.env.REACT_APP_SECRET_KEY)
    );

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.allowed_realtors, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const response = JSON.parse(result);
        if (response.success === true) {
          let decryptedData = Decryption(response.data);
          localStorage.setItem('companies', decryptedData);
          refresh();
        } else {
          setalert({ visible: true, message: 'Something went wrong' });
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => setloading(false));
  }

  let objBefore = {
    allowed_realtors: details?.allowed_realtors
  };

  const [objAfter, setobjAfter] = useState({
    allowed_realtors: details?.allowed_realtors
  });

  useEffect(() => {
    setobjAfter({
      allowed_realtors: allowed_realtors
    });
  }, [allowed_realtors]);

  function imagesArray() {
    setimagesMultiple([
      {
        src: details.avatar
      }
    ]);
  }
  useEffect(() => {
    imagesArray();
  }, []);

  const [index, setindex] = useState(0);
  function gotoPrevious() {
    if (index >= 0 && index <= mainLength) {
      setindex(index - 1);
    }
  }
  function gotoNext() {
    if (index >= 0 && index < mainLength) {
      setindex(index + 1);
    }
  }

  function settingFullImage(index) {
    imagesArray();
    setindex(index);
    setfullImage(true);
  }

  const [docLoading, setdocLoading] = useState(false);
  const [terminationloading, setterminationloading] = useState(false);
  function TerminateProfile() {
    setterminationloading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('id', details?.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.user_terminate, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          handleClose(true);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setterminationloading(false);
      });
  }
  // console.log(details);
  return (
    <Box py={2} px={4}>
      {/* {permission.includes('visible') && (
        <Box
          py={2}
          display="flex"
          flexDirection="row"
          justifyContent="start"
          style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
        >
          <Button
            style={{
              width: 135,
              marginRight: 5,
              color: 'black',
              backgroundColor: value == 0 ? palette.primary.main : 'silver'
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              setValue(0);
            }}
          >
            Details
          </Button>
          <Button
            style={{
              width: 135,
              color: 'black',
              backgroundColor: value == 1 ? palette.primary.main : 'silver'
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              setValue(1);
              setdocLoading(true);
            }}
          >
            Documents
          </Button>
        </Box>
      )} */}
      {value == 0 && (
        <Box align="center">
          <ImgsViewer
            showImgCount={false}
            imgs={imagesMultiple}
            currImg={index}
            onClickPrev={gotoPrevious}
            onClickNext={gotoNext}
            isOpen={fullImage}
            onClose={() => setfullImage(false)}
          />
          {imagesMultiple.length > 0 && (
            <ImageList
              sx={{
                width: '100%',
                marginTop: 2,
                justifyContent: 'center',
                alignContent: 'center',

                display: 'flex'
              }}
              cols={5}
              rowHeight={120}
            >
              {imagesMultiple.map((item, index) => (
                <ImageListItem onClick={() => settingFullImage(index)} key={item.index}>
                  <img
                    style={{ height: 120, width: 120, borderRadius: 60 }}
                    src={item.src}
                    alt={'Profile Image'}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
          <Typography
            pt={1}
            align="center"
            row
            sx={{ fontSize: 16, fontWeight: 'bold' }}
            component="div"
          >
            {details.firstname} {details.lastname}
          </Typography>
          {/* <Typography pt={0} align="center" row sx={{ fontSize: 12 }} component="div">
            Joined {details.joined_since}
          </Typography> */}

          <Box
            // sx={{ borderBottom: 0.1 }}
            width="100%"
            py={1}
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Typography align="center" pr={1} sx={{ fontSize: 16 }} component="div">
              {t('UserModal.headings.0')}:
            </Typography>

            <Typography
              align="center"
              row
              sx={{ fontSize: 16, fontWeight: 'bold' }}
              component="div"
            >
              {details.email}
            </Typography>
          </Box>
          <Divider />
          {details.contact && (
            <>
              <Box py={1} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                  {t('UserModal.headings.1')}:
                </Typography>
                <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                  {details.contact}
                </Typography>
              </Box>
              <Divider />
            </>
          )}
          {details.address && (
            <>
              <Box py={1} style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                  {t('UserModal.headings.2')}:
                </Typography>
                <Typography row sx={{ fontSize: 15, fontWeight: 'bold' }} component="div">
                  {details.address}
                </Typography>
              </Box>
            </>
          )}
          {details?.role == 4 && (
            <>
              <Divider />
              <Box py={1} style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                  {t('UserModal.headings.3')}:
                </Typography>
                <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                  {details.num_of_realtors}
                </Typography>
              </Box>

              <Divider />
              <Box py={1} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                  {t('UserModal.headings.4')}:
                </Typography>
                <TextField
                  disabled={!permission?.includes('edit')}
                  id="outlined-error-helper-text"
                  value={allowed_realtors}
                  onChange={(val) => {
                    setAllowedRealtors(val.target.value);
                  }}
                  type="number"
                  variant="outlined"
                  size="small"
                  style={{ width: 100 }}
                />
                {/* <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                {details.allowed_realtors}
              </Typography> */}
              </Box>

              <Divider />
              <Box py={1} style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                  {t('UserModal.headings.5')}:
                </Typography>
                <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                  {details.total_realtors_count}
                </Typography>
              </Box>

              <Divider />
              <Box py={1} style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                  {t('UserModal.headings.6')}:
                </Typography>
                <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                  {details.active_realtors_count}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}

      <Box display="flex" justifyContent="flex-end" flexDirection="row" pt={2}>
        <LoadingButton
          disabled={JSON.stringify(objBefore) != JSON.stringify(objAfter) ? false : true}
          loading={loading}
          sx={{
            marginRight: 1
          }}
          onClick={() => {
            UpdateAllowedAgents();
          }}
          color="primary"
          variant="contained"
        >
          {t('UserModal.buttons.0')}
        </LoadingButton>

        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
          }}
        >
          {t('UserModal.buttons.1')}
        </Button>
      </Box>
    </Box>
  );
}
