import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Pagination,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  CircularProgress
} from '@mui/material';
import Label from 'src/components/Label';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserModal } from '../sections/@dashboard/user';
import ContactModal from 'src/sections/@dashboard/contact/ContactModal';
//
import pallete from '../theme/palette';
import { api } from '../Api';
import { Decryption, Encryption } from 'src/functions/function';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Contact() {
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: 'name', label: t('Contact.table.0'), alignRight: false },
    { id: 'email', label: t('Contact.table.1'), alignRight: false },
    { id: 'contact', label: t('Contact.table.2'), alignRight: false },
    { id: 'added', label: 'Contact', alignRight: false },
    { id: 'address', label: 'Address', alignRight: false },
    { id: 'action', label: t('Contact.table.6'), alignRight: false }
  ];
  const [permission, setpermission] = useState([]);
  const requirement = ['visible', 'add', 'edit', 'delete'];
  function checkRender() {
    let check = 'request buyer';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState(true);
  const [searchLoading, setsearchLoading] = useState(false);

  function getContacts(page) {
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_KEY));
    search.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_KEY));
    user?.role == 4 &&
      formdata.append('company_id', Encryption(user?.id, process.env.REACT_APP_SECRET_KEY));
    user?.role == 1 &&
      formdata.append('agent_id', Encryption(user?.id, process.env.REACT_APP_SECRET_KEY));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_company_contacts, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          list.length = 0;
          let decryptedData = JSON.parse(Decryption(result.data));
          setlist(decryptedData);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloading(false);
        setsearchLoading(false);
      });
  }

  const handleClose = () => setOpen(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  useEffect(() => {
    setLoggedInUser(JSON.parse(localStorage.getItem('user')));
    getContacts();
  }, []);
  const [open, setOpen] = React.useState(false);
  const [user, setuser] = useState(null);

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('');

  const isUserNotFound = list.length === 0;
  const style = {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  //FILTERS
  const [contactModal, setcontactModal] = useState(false);
  const [editContact, seteditContact] = useState(null);
  const [edit, setedit] = useState(false);
  const [deleting, setdeleting] = useState(false);

  const [selectedIndex, setselectedIndex] = useState(null);

  function deleteContact(id, index) {
    setselectedIndex(index);
    setdeleting(true);

    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('id', Encryption(id, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.delete_company_contacts, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          getContacts();
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setdeleting(false);
      });
  }

  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getContacts(value);
  };
  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      setsearchLoading(true);
      setPage(1);
      getContacts(1);
    }
  };
  return (
    <Page title={`Contacts | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* <Modal
          style={style}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, p: 0, maxWidth: '60%', minWidth: '50%' }}>
            <UserModal handleClose={handleClose} details={user} permission={permission} />
          </Box>
        </Modal> */}
        {/* CONTACT MODAL */}
        <Modal
          style={style}
          open={contactModal}
          onClose={() => {
            setcontactModal(false);
          }}
        >
          <Box sx={{ ...boxStyle, p: 0, width: '75%', maxHeight: '90%' }} className="admin_modal">
            <ContactModal
              handleClose={() => {
                setcontactModal(false);
              }}
              refresh={() => {
                setcontactModal(false);
                getContacts(page);
              }}
              edit={edit}
              data={editContact}
            />
          </Box>
        </Modal>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {'Register Buyer'}
          </Typography>
          <Button
            onClick={(event) => {
              setedit(false);
              seteditContact(null);
              setcontactModal(true);
            }}
            variant="contained"
            size="small"
            // color="error"
            style={{ width: 120, marginRight: 5, height: 40 }}
            component={RouterLink}
            to="#"
          >
            {t('Contact.add')}
          </Button>
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
          >
            <Searchbar
              placeholder={t('Contact.search') + '...'}
              width={'100%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              endIcon={<Iconify icon="material-symbols:search" />}
              style={{ width: '15%', marginLeft: '2.5%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getContacts(1);
              }}
            />
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                />
                <TableBody>
                  {list.map((row, index) => {
                    const { id, name, phone_no, min_price, max_price, email, added_by, address } =
                      row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell scope="row" padding="normal" style={{ width: 150 }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            style={{ width: 150 }}
                          >
                            <Box style={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                              <Typography variant="subtitle2" flexWrap={'wrap'}>
                                {name}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 150 }}>
                          <Typography noWrap>{email}</Typography>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          {added_by?.firstname + ' ' + added_by?.lastname}
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          {phone_no ? phone_no : 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          {address ? address : 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          <Button
                            onClick={() => {
                              seteditContact(row);
                              setedit(true);
                              setcontactModal(true);
                            }}
                            style={{ marginTop: 5, width: 100 }}
                            variant="outlined"
                            component={RouterLink}
                            to="#"
                            startIcon={<Iconify color={pallete.primary.main} icon="tabler:edit" />}
                          >
                            {t('Contact.buttons.0')}
                          </Button>
                          {(loggedInUser?.role == 4 || loggedInUser?.role == 3) && (
                            <LoadingButton
                              startIcon={
                                <Iconify color={pallete.primary.main} icon="ic:baseline-delete" />
                              }
                              style={{ marginTop: 5, width: 100 }}
                              variant="outlined"
                              loading={selectedIndex == index && deleting}
                              onClick={() => {
                                deleteContact(id, index);
                              }}
                            >
                              {t('Contact.buttons.1')}
                            </LoadingButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
