import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9999 // Ensure the loader is on top of everything
    }}
  >
    <CircularProgress color="primary" />
  </div>
);

export default Loader;
