import React, { useState, useEffect } from 'react';
import { Button, Grid, IconButton, Box, CircularProgress } from '@mui/material';
import { Delete } from '@mui/icons-material';
import palette from '../theme/palette';
import { api } from 'src/Api';
import { Decryption, Encryption } from 'src/functions/function';

const ImagePicker = ({ onUploadComplete, onDelete, data, buttonText }) => {
  const [uploadedImages, setuploadedImages] = useState([]);
  const [PropertyImages, setPropertyImages] = useState([]);

  const handleImageChange = async (e) => {
    const images = Array.from(e.target.files);
    images.map((item, index) => {
      if (index <= 29) {
        setPropertyImages((images) => [
          {
            uri: 'loading',
            width: item.width,
            height: item.height,
            mime: item.mime
          },
          ...images
        ]);

        UploadImages(item, 'image', index);
      } else {
        return;
      }
    });
  };

  function DeleteAsset(id, type) {
    onDelete(id);
    if (type == 'image') {
      setuploadedImages(uploadedImages.filter((item) => item != id));
      setPropertyImages(PropertyImages.filter((item) => item.id != id));
      
    }
  }

  function UploadImages(uri, assettype, index) {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    formdata.append('type', Encryption(assettype, process.env.REACT_APP_SECRET_KEY));
    formdata.append('image', uri);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.upload_property_images, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success == true) {
          if (assettype == 'image') {
            let decryptedData = JSON.parse(Decryption(result.data));

            onUploadComplete(decryptedData?.id, decryptedData);
            setuploadedImages((uploadedImages) => [...uploadedImages, decryptedData?.id]);
            setPropertyImages((prevState) => {
              const newArray = [...prevState];
              newArray[index] = decryptedData;
              return newArray;
            });
          }
        }
      })
      .catch((error) => {
        assettype == 'image' &&
          error &&
          setPropertyImages((prevState) => {
            const newArray = [...prevState];
            newArray.splice(index, 1);
            return newArray;
          });
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (data) {
      setPropertyImages(data?.filter((item) => item.type == 'image'));
      setuploadedImages(data?.filter((item) => item.type == 'image').map((items) => items.id));
    }
  }, [data]);

  return (
    <Box container alignItems="center">
      <Grid item xs={12}>
        <Button color="primary" variant="outlined" component="label">
          {buttonText ? buttonText : 'Select Images'}
          <input type="file" accept="image/*" multiple hidden onChange={handleImageChange} />
        </Button>
      </Grid>
      {PropertyImages.length > 0 && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%'
          }}
        >
          {PropertyImages.map((image, index) => (
            <div
              key={index}
              style={{
                width: '100px',
                height: '100px',
                position: 'relative',
                marginRight: 22,
                marginTop: 10,
                border: '2px solid',
                borderColor: palette.primary.main,
                borderRadius: 15,
                overflow: 'hidden',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
              }}
            >
              {image?.uri == 'loading' ? (
                <CircularProgress color={'error'} size={20} />
              ) : (
                <>
                  <img
                    src={image?.uri}
                    alt={`Selected Image ${index + 1}`}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  <IconButton
                    style={{ position: 'absolute', top: 0, right: 10 }}
                    edge="end"
                    aria-label="delete"
                    onClick={() => DeleteAsset(image.id, 'image')}
                    color="error"
                  >
                    <Delete />
                  </IconButton>
                </>
              )}
            </div>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ImagePicker;
