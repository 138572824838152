import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import { api } from 'src/Api';
import { Decryption, Encryption } from 'src/functions/function';

export default function StatusDropDown({
  setFunction,
  fullWidth,
  error,
  width,
  type,
  document,
  helpcenter,
  data,
  placeholder
}) {
  const [status, setStatus] = useState([]);

  function getStatus() {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    type && formdata.append('type', Encryption(type, process.env.REACT_APP_SECRET_KEY));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(
      document ? api.getDocumentType : helpcenter ? api.getCategories : api.status,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          let decryptedData = JSON.parse(Decryption(result.data));
          helpcenter
            ? setStatus([{ id: 0, question: 'All' }, ...decryptedData])
            : setStatus([{ id: 0, name: 'All' }, ...decryptedData]);
        }
      })
      .catch((error) => console.log('error', error));
  }

  const [reasondrop, setReasondrop] = React.useState('');

  const handleChange = (event) => {
    helpcenter ? setFunction(event.target.value.question) : setFunction(event.target.value.name);
    setReasondrop(event.target.value);
  };

  useEffect(() => {
    !data && getStatus();

    data && setStatus(data);
  }, []);

  return (
    <TextField
      error={error}
      color={error ? 'error' : 'primary'}
      fullWidth
      style={{
        width: width ? '30%' : !fullWidth && '20%',
        marginLeft: !fullWidth && !document && !helpcenter && !data && 15
      }}
      select
      value={reasondrop}
      label={
        placeholder
          ? placeholder
          : document
          ? 'Type'
          : helpcenter
          ? 'Category'
          : data
          ? 'Type'
          : 'Status'
      }
      onChange={handleChange}
      className='width_full_status'
    >
      {status.map((option) => (
        <MenuItem key={option.id} value={option}>
          {helpcenter ? option.question : option.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
