import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress, IconButton } from '@mui/material';
import { api } from 'src/Api';
import Iconify from 'src/components/Iconify';

export default function CityDropdown({ setFunction, Width }) {
  const [citys, setcity] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState();

  function getCity() {
    const Citys = JSON.parse(localStorage.getItem('city'));
    if (search.length == 0 && Citys?.length > 0) {
      setcity(Citys);
    } else {
      setloading(true);
      var formdata = new FormData();
      if (search.length > 0) {
        formdata.append('search', search);
        setloading(true);
      }

      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      fetch(api.getcityandstates, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success == true) {
            search.length == 0 && localStorage.setItem('city', JSON.stringify(result.data));
            setcity(result.data);
          } else {
            setcity(result.data);
          }
        })
        .catch((error) => console.log('error', error))
        .finally(() => {
          setloading(false);
        });
    }
  }

  useEffect(() => {
    getCity();
  }, []);

  useEffect(() => {
    if (search?.length == 0) {
      getCity();
    }
  }, [search]);
  const [selectedValue, setselectedValue] = useState(null);
  return (
    <Autocomplete
      onChange={(event, value) => {
        setsearch('');
        setFunction(value);
      }}
      clearIcon={
        <Iconify
          onClick={() => {
            getCity();
            if (selectedValue) {
              setsearch('');
              setFunction(null);
              getCity();
            }
          }}
          icon={'material-symbols:close-rounded'}
          sx={{ color: 'text.disabled', width: 20, height: 20 }}
        />
      }
      filterOptions={(x) => x}
      disablePortal
      options={citys?.map((option) => option.name)}
      style={{ width: Width }}
      noOptionsText="No City/State"
      renderInput={(params) => (
        <TextField
          onChange={(e) => {
            setsearch(e.target.value);
          }}
          {...params}
          label="City/State"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {!selectedValue && (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <>
                        {search.length > 0 && (
                          <IconButton
                            style={{ padding: 5 }}
                            onClick={() => {
                              getCity();
                            }}
                          >
                            <Iconify
                              icon={'material-symbols:search'}
                              sx={{
                                color: 'text.disabled',
                                width: 20,
                                height: 20,
                                cursor: 'pointer'
                              }}
                            />
                          </IconButton>
                        )}
                      </>
                    )}
                  </>
                )}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}
