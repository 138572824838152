import React, { useState, useEffect } from 'react';
import { Button, Grid, IconButton, Box, CircularProgress, InputAdornment } from '@mui/material';
import { Save } from '@mui/icons-material';
import { Delete, FileCopy } from '@mui/icons-material';
import palette from '../theme/palette';
import { api } from 'src/Api';
import { TextField } from '@mui/material';
import { Decryption, Encryption } from 'src/functions/function';

const DocumentPicker = ({ onUploadComplete, onDelete, data, buttonText }) => {
  const [uploadedDocuments, setuploadedDocuments] = useState([]);
  const [propertyDocuments, setpropertyDocuments] = useState([]);

  useEffect(() => {
    if (data) {
      setpropertyDocuments(data?.filter((item) => item.type == 'property'));
      setuploadedDocuments(
        data?.filter((item) => item.type == 'property').map((items) => items.id)
      );
    }
  }, []);

  const handleDocumentChange = async (e) => {
    const images = Array.from(e.target.files);
    images.map((item, index) => {
      if (index <= 29) {
        setpropertyDocuments((images) => [
          {
            uri: 'loading',
            width: item.width,
            height: item.height,
            mime: item.mime
          },
          ...images
        ]);

        UploadDocuments(item, 'property', index);
      } else {
        return;
      }
    });
  };

  function DeleteAsset(id, type) {
    onDelete(id);
    if (type == 'property') {
      setuploadedDocuments(uploadedDocuments.filter((item) => item != id));
      setpropertyDocuments(propertyDocuments.filter((item) => item.id != id));
    }
  }

  function UploadDocuments(uri, assettype, index) {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    formdata.append('type', Encryption(assettype, process.env.REACT_APP_SECRET_KEY));
    formdata.append('name', Encryption(uri?.name, process.env.REACT_APP_SECRET_KEY));
    formdata.append('attachment', uri);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.upload_property_documents, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success == true) {
          if (assettype == 'property') {
            let decryptedData = JSON.parse(Decryption(result.data));
            onUploadComplete(decryptedData?.id);
            setuploadedDocuments((uploadedDocuments) => [...uploadedDocuments, decryptedData?.id]);
            setpropertyDocuments((prevState) => {
              const newArray = [...prevState];
              newArray[index] = decryptedData;
              return newArray;
            });
          }
        } else {
        }
      })
      .catch((error) => {
        assettype == 'image' &&
          error &&
          setpropertyDocuments((prevState) => {
            const newArray = [...prevState];
            newArray.splice(index, 1);
            return newArray;
          });
      })
      .finally(() => {});
  }

  const handleNameChange = (indexx, newName) => {
    const updatedData = propertyDocuments.map((item, index) => {
      if (index === indexx) {
        return {
          ...item,
          name: newName
        };
      }
      return item;
    });

    setpropertyDocuments(updatedData);
  };

  const [saving, setsaving] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(null);

  function EditDocument(id, index) {
    setsaving(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', propertyDocuments[index]['name']);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.edit_property_documents, requestOptions)
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log('error', error))
      .finally(() => {
        setsaving(false);
        setselectedIndex(null);
      });
  }
  return (
    <Box container alignItems="center">
      <Grid item xs={12}>
        <Button color="primary" variant="outlined" component="label">
          {buttonText ? buttonText : 'Select Documents'}
          <input type="file" multiple hidden onChange={handleDocumentChange} />
        </Button>
      </Grid>
      {propertyDocuments.length > 0 && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%'
          }}
        >
          {propertyDocuments.map((image, index) => (
            <div
              key={index}
              style={{
                width: '200px',
                height: '150px',
                position: 'relative',
                marginRight: 22,
                marginTop: 10,
                border: '2px solid',
                borderColor: palette.primary.main,
                borderRadius: 15,
                overflow: 'hidden',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
              }}
            >
              {image?.uri == 'loading' ? (
                <CircularProgress color={'error'} size={20} />
              ) : (
                <>
                  <Box
                    style={{
                      width: '90%',
                      height: '70%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <FileCopy
                      onClick={() => {
                        window.open(image.uri, '_blank');
                      }}
                      style={{ width: '80%', height: '80%' }}
                    />

                    <TextField
                      style={{ width: '100%', marginTop: 10 }}
                      label={''}
                      value={image.name}
                      onChange={(e) => {
                        console.log(e.target.value);
                        handleNameChange(index, e.target.value);
                      }}
                      size="small"
                      variant="standard"
                      onFocus={() => {
                        setselectedIndex(index);
                      }}
                      onBlur={() => {
                        // setselectedIndex(null);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {saving && selectedIndex == index ? (
                              <CircularProgress size={10} style={{ marginRight: 5 }} />
                            ) : (
                              <>
                                {selectedIndex == index && (
                                  <Box flexDirection="row">
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        EditDocument(image.id, index);
                                      }}
                                      style={{ marginBottom: 5 }}
                                    >
                                      <Save fontSize="small" />
                                    </IconButton>
                                  </Box>
                                )}
                              </>
                            )}
                          </InputAdornment>
                        ),
                        style: { fontSize: 10 }
                      }}
                    />
                  </Box>

                  <IconButton
                    style={{ position: 'absolute', top: 0, right: 10 }}
                    edge="end"
                    aria-label="delete"
                    onClick={() => DeleteAsset(image.id, 'property')}
                    color="error"
                  >
                    <Delete />
                  </IconButton>
                </>
              )}
            </div>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DocumentPicker;
