import { sample } from 'lodash';
// utils
import { mockImgAvatar } from '../utils/mockImages';

// ----------------------------------------------------------------------

// const users = [...Array(24)].map((_, index) => ({
//   id: faker.datatype.uuid(),
//   avatarUrl: mockImgAvatar(index + 1),
//   name: faker.name.findName(),
//   company: faker.company.companyName(),
//   isVerified: faker.datatype.boolean(),
//   status: sample(['active', 'banned']),
//   role: sample(['user', 'admin'])
// }));

const users = [
  {
    id: 1,
    name: 'username',
    avatarUrl: mockImgAvatar(1),
    email: 'johndoe@gmail.com',
    contact: '090078601',
    address: 'tariq road,3405t9,3eor73',
    action: sample(['active', 'banned'])
  },
  {
    id: 2,
    name: 'shakeeb',
    avatarUrl: mockImgAvatar(2),
    email: 'john@gmail.com',
    contact: '090078601',
    address: 'tariq road,3405t9,3eor73',
    action: sample(['active', 'banned'])
  }
];

export default users;
