import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { TextField, Autocomplete, CircularProgress, Icon } from '@mui/material';
import { api } from 'src/Api';
import Iconify from 'src/components/Iconify';
import { Decryption, Encryption } from 'src/functions/function';

export default function AgentDropDown({
  setFunction,
  Width,
  margin_,
  selectedAgent,
  placeholder,
  realtor_company
}) {
  const [agents, setagents] = useState([]);

  function getCompanyAgents() {
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    // formdata.append('company_id', Encryption(user?.id, process.env.REACT_APP_SECRET_APP));
    formdata.append('company_id', Encryption(realtor_company, process.env.REACT_APP_SECRET_APP));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_agents, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          agents.length = 0;
          let decryptedData = JSON.parse(Decryption(result.data));
          setagents(decryptedData.filter((item) => item.id !== selectedAgent));
        } else {
          setagents([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {});
  }

  useEffect(() => {
    getCompanyAgents();
  }, []);

  return (
    <Autocomplete
      onChange={(event, value) => {
        setFunction(value ? value.id : null);
      }}
      clearIcon={
        <Iconify
          onClick={() => {
            setFunction(null);
          }}
          icon={'material-symbols:close-rounded'}
          sx={{ color: 'text.disabled', width: 20, height: 20 }}
        />
      }
      disablePortal
      id="combo-box-demo"
      options={agents} // Pass the whole array of objects as options
      getOptionLabel={(option) => option?.name} // Provide a function to get the label for each option
      getOptionSelected={(option, value) => option.name === value.name} // Compare the name property to determine if an option is selected
      noOptionsText="No Agent Available"
      style={{ width: Width, marginTop: margin_ ? 0 : 5, marginRight: margin_ ? 0 : 5 }}
      renderInput={(params) => <TextField {...params} label={placeholder || 'Select Agent'} />}
      className="width_agent"
    />
  );
}
