import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import CategoryDropDown from 'src/layouts/dashboard/CategoryDropDown';
import GooglePlaces from 'src/components/GooglePlaces';
import Slider from '@mui/material/Slider';
import { useSnackbar, closeSnackbar } from 'notistack';
import { fCurrency } from 'src/utils/formatNumber';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Box,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Drawer,
  IconButton,
  Pagination,
  TextField,
  MenuItem,
  InputAdornment
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';
import { PropertyDetails, RejectModal, PropertyModal } from '../sections/@dashboard/products';
import pallete from '../theme/palette';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';

import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
//
import { api } from '../Api';
import TypesDropDown from 'src/layouts/dashboard/TypesDropDown';
import SaleTypeDropDown from 'src/layouts/dashboard/SaleTypeDropDown';
import ConstructionStatusDropDown from 'src/layouts/dashboard/ConstructionStatusDropDown';
import AreaUnitDropDown from 'src/layouts/dashboard/AreaUnitDropDown';
import SortByDropDown from 'src/layouts/dashboard/SortByDropDown';
import NotesModal from 'src/sections/@dashboard/products/NotesModal';
import { ViewsModal } from '../sections/@dashboard/products';
import { Decryption, Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { chatUrl } from '../Api';
import Loader from 'src/components/Loader';
import build from '@date-io/date-fns';
import { Description } from '@mui/icons-material';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function valuetext(value) {
  return `${value}°C`;
}

export default function EcommerceShop() {
  const self = JSON.parse(localStorage.getItem('user'));
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: 'title', label: t('Property.table.0'), alignRight: false },
    { id: 'status', label: t('Property.table.5'), alignRight: false },
    { id: 'property_type', label: 'Type', alignRight: false },
    { id: 'lot', label: 'Lot', alignRight: false },
    { id: 'address', label: t('Property.table.1'), alignRight: false },
    { id: 'suburb', label: 'Suburb', alignRight: false },
    { id: 'estate', label: 'Estate', alignRight: false },
    { id: 'state', label: 'State', alignRight: false },
    { id: 'title_date', label: 'Title Date', alignRight: false },
    { id: 'dimension', label: 'Dimension', alignRight: false },
    { id: 'Land', label: 'Land Size', alignRight: false },
    { id: 'prices', label: 'Land Price', alignRight: false },
    { id: 'pricess', label: 'Build Price', alignRight: false },
    { id: 'pricesss', label: 'Total Price', alignRight: false },
    { id: 'design', label: 'Design', alignRight: false },
    { id: 'description', label: t('Property.table.3'), alignRight: false },
    { id: 'size', label: 'Build Size', alignRight: false },
    { id: 'body', label: 'Body Corp', alignRight: false },
    { id: 'view_count', label: t('Property.table.7'), alignRight: false },
    { id: 'action', label: t('Property.table.6'), alignRight: false }
  ];

  const [permission, setpermission] = useState([]);
  const [offerpermission, setofferpermission] = useState([]);
  const requirement = [
    'visible',
    'add',
    'edit',
    'delete',
    'notes',
    'change agent',
    'change status'
  ];

  function checkRender() {
    let check = 'properties';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0].permission_types.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
    let checkOffer = 'offers';
    const OfferPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == checkOffer
    );
    OfferPerm[0]?.permission_types.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setofferpermission((offerpermission) => [
            ...offerpermission,
            items.permission_type_name.toLowerCase()
          ])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  const [list, setlist] = useState([]);

  const detailClose = () => {
    setDetail(false);
    setofferModal(false);
  };

  const [detail, setDetail] = React.useState(false);
  const [notesModal, setnotesModal] = useState(false);
  const [viewModal, setviewModal] = useState(false);

  const [loading, setloading] = useState(true);
  const [count, setcount] = useState(null);
  const [category, setcategory] = useState(null);
  const [type, settype] = useState(null);
  const [saletype, setsaletype] = useState(null);
  const [construction_status, setconstruction_status] = useState(null);
  const [area_unit, setarea_unit] = useState(null);
  const [sort_by, setsort_by] = useState(null);
  const [city, setcity] = useState(null);
  const [price, setprice] = React.useState([100, 1000000]);
  const [area, setarea] = React.useState([10, 10000]);
  const [bed, setbed] = React.useState([1, 10]);
  const [bath, setbath] = React.useState([1, 10]);
  const [garage, setgarage] = React.useState([1, 10]);

  const [currentPage, setcurrentPage] = useState(1);

  function getBusinesses(pagenumber) {
    setloading(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();

    pagenumber && formdata.append('page', Encryption(pagenumber, process.env.REACT_APP_SECRET_APP));

    search.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));
    if (!allbusinesses) {
      available &&
        formdata.append(
          'status_available',
          Encryption('AVAILABLE', process.env.REACT_APP_SECRET_APP)
        );
      // sold && formdata.append('status_sold', 'sold');
      request &&
        formdata.append('status_request', Encryption('REQUEST', process.env.REACT_APP_SECRET_APP));
      hold && formdata.append('status_hold', Encryption('HOLD', process.env.REACT_APP_SECRET_APP));
      pending &&
        formdata.append('status_pending', Encryption('PENDING', process.env.REACT_APP_SECRET_APP));
      forinvestment &&
        formdata.append(
          'available_for',
          Encryption('investment', process.env.REACT_APP_SECRET_APP)
        );
      settled &&
        formdata.append('status_settled', Encryption('SETTLED', process.env.REACT_APP_SECRET_APP));
      contracting &&
        formdata.append(
          'status_contracting',
          Encryption('CONTRACTING', process.env.REACT_APP_SECRET_APP)
        );
      underconstruction &&
        formdata.append(
          'status_underconstruction',
          Encryption('UNDER CONSTRUCTION', process.env.REACT_APP_SECRET_APP)
        );
      decline &&
        formdata.append(
          'status_declined',
          Encryption('declined', process.env.REACT_APP_SECRET_APP)
        );
      online && formdata.append('isonline', Encryption('1', process.env.REACT_APP_SECRET_APP));
      star && formdata.append('rating', Encryption('4', process.env.REACT_APP_SECRET_APP));
      byme && formdata.append('approved_by_me', Encryption(1, process.env.REACT_APP_SECRET_APP));
      type && formdata.append('property_type', Encryption(type, process.env.REACT_APP_SECRET_APP));
      construction_status &&
        formdata.append(
          'construction_status',
          Encryption(construction_status, process.env.REACT_APP_SECRET_APP)
        );
      area_unit &&
        formdata.append('area_unit', Encryption(area_unit, process.env.REACT_APP_SECRET_APP));
      sort_by && formdata.append('sortby', Encryption(sort_by, process.env.REACT_APP_SECRET_APP));
      price &&
        formdata.append('price_from', Encryption(price[0], process.env.REACT_APP_SECRET_APP));
      price && formdata.append('price_to', Encryption(price[1], process.env.REACT_APP_SECRET_APP));
      area &&
        formdata.append('square_feet_from', Encryption(area[0], process.env.REACT_APP_SECRET_APP));
      area &&
        formdata.append('square_feet_to', Encryption(area[1], process.env.REACT_APP_SECRET_APP));
      bed && formdata.append('bedroom_from', Encryption(bed[0], process.env.REACT_APP_SECRET_APP));
      bed && formdata.append('bedroom_to', Encryption(bed[1], process.env.REACT_APP_SECRET_APP));
      bath &&
        formdata.append('bathroom_from', Encryption(bath[0], process.env.REACT_APP_SECRET_APP));
      bath && formdata.append('bathroom_to', Encryption(bath[1], process.env.REACT_APP_SECRET_APP));
      garage &&
        formdata.append('parking_from', Encryption(garage[0], process.env.REACT_APP_SECRET_APP));
      garage &&
        formdata.append('parking_to', Encryption(garage[1], process.env.REACT_APP_SECRET_APP));
    }
    category &&
      formdata.append('property_catergory', Encryption(category, process.env.REACT_APP_SECRET_APP));
    // city && formdata.append('city', city);
    // if (address) {
    //   address?.country &&
    //     address?.country != undefined &&
    //     formdata.append('country', Encryption(address?.country, process.env.REACT_APP_SECRET_APP));
    //   address?.postcode &&
    //     address?.postcode != undefined &&
    //     formdata.append(
    //       'postal_code',
    //       Encryption(address?.postcode, process.env.REACT_APP_SECRET_APP)
    //     );

    //   address?.city &&
    //     address?.city != undefined &&
    //     formdata.append('city', Encryption(address?.city, process.env.REACT_APP_SECRET_APP));
    //   address?.suburb &&
    //     address?.suburb != undefined &&
    //     formdata.append('suburbs', Encryption(address?.suburb, process.env.REACT_APP_SECRET_APP));
    // }
    state && formdata.append('state', Encryption(state, process.env.REACT_APP_SECRET_APP));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.getBusinesses, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        setcount(result.counts);
        if (result.success === true) {
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);

          // let decryptedData = JSON.parse(Decryption(result.data));
          let decryptedData = Decryption(result.data);
          setlist(decryptedData);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setsearchLoading(false);
        setloading(false);
      });
  }

  const refresh = () => {
    setDetail(false);
    getBusinesses(page);
  };

  useEffect(() => {
    getBusinesses();
    getStates();
  }, []);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [business, setbusiness] = useState(null);

  function openModal(row) {
    setbusiness(row);
    setDetail(true);
  }

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const isBusinessFound = list.length === 0;

  const style = {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  const boxStyleNote = {
    minWidth: '60%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main,
    overflow: 'hidden'
  };

  const [offerModal, setofferModal] = useState(false);

  const offerClose = (id) => {
    setofferModal(false);
    if (id === true) {
      getBusinesses(page);
    }
  };

  const [selectedIndex, setselectedIndex] = useState(null);
  const [deleting, setdeleting] = useState(false);

  function DeleteProperty(id, index, marketstatus) {
    setselectedIndex(index);
    setdeleting(true);
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('property_id', Encryption(id, process.env.REACT_APP_SECRET_APP));
    formdata.append('status', Encryption('deleted', process.env.REACT_APP_SECRET_APP));
    formdata.append('market_status', Encryption(marketstatus, process.env.REACT_APP_SECRET_APP));
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.approveProperty, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        console.log(result_);
        const result = JSON.parse(result_);
        if (result.success === true) {
          getBusinesses(page);
          refresh();
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setdeleting(false);
      });
  }

  function closeandOpen() {
    setDetail(false);
    setofferModal(true);
  }

  const [rejectModal, setrejectModal] = useState(false);

  const closeReject = () => {
    setrejectModal(false);
  };
  const RejectRefresh = () => {
    setrejectModal(false);
    getBusinesses();
  };

  const [search, setsearch] = useState('');
  const [searchLoading, setsearchLoading] = useState(false);

  const Buttonstyle = {
    width: '44%',
    height: 45,
    marginTop: 10,
    color: 'black',
    boxShadow: 'none',
    fontSize: 11,
    marginRight: 5
  };

  const [allbusinesses, setallbusinesses] = useState(true);
  const [available, setavailable] = useState(false);
  const [request, setrequest] = useState(false);
  const [hold, sethold] = useState(false);
  const [pending, setpending] = useState(false);
  const [forinvestment, setforinvestment] = useState(false);
  const [settled, setsettled] = useState(false);
  const [contracting, setcontracting] = useState(false);
  const [underconstruction, setunderconstruction] = useState(false);
  const [online, setonline] = useState(false);
  const [decline, setdecline] = useState(false);
  const [star, setstar] = useState(false);
  const [byme, setbyme] = useState(false);

  function filterSetter(type) {
    if (type == 'all business') {
      setallbusinesses(!allbusinesses);
      setavailable(false);
      setforinvestment(false);
      setrequest(false);
      sethold(false);
      setpending(false);
      setsettled(false);
      setcontracting(false);
      setunderconstruction(false);
      setdecline(false);
      setonline(false);
      setstar(false);
      setbyme(false);
      settype(false);
      setarea_unit(false);
      setsort_by(false);
      setconstruction_status(false);
      price(false);
      area(false);
      bed(false);
      bath(false);
      garage(false);
    } else {
      setallbusinesses(false);
    }
  }

  function CheckStatus(status, market_status) {
    if (status == 'active' && market_status == 'active') {
      return 'Available';
    } else if (status == 'active') {
      return market_status;
    } else {
      return status;
    }
  }

  const [propertyModal, setpropertyModal] = useState(false);
  const [editproperty, seteditproperty] = useState(false);
  const [editData, seteditData] = useState(false);
  const [propertyID, setpropertyID] = useState(null);

  function openpropertyModal() {
    setpropertyModal(true);
  }

  const closeproperty = () => {
    setpropertyModal(false);
  };

  const propertyRefresh = () => {
    setpropertyModal(false);
    getBusinesses(page);
  };

  const [address, setaddress] = useState(null);

  const tableRef = React.createRef();

  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    getBusinesses(value);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileLoading, setfileLoading] = useState(false);
  const [errors, setErrors] = useState([
    {
      message: 'The bedroom field is required.',
      row: 2
    },
    {
      message: 'The city field is required.',
      row: 3
    }
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = (e) => {
    const token = localStorage.getItem('token');
    setfileLoading(true);
    const file = e.target.files[0];
    setSelectedFile(file);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('file', file);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.import, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          list.length = 0;
          getBusinesses(1);
        } else {
          if (result?.errors?.length > 0) {
            list.length = 0;
            getBusinesses(1);
            result?.errors?.map((item, index) => {
              return enqueueSnackbar(item.message + ' in ' + 'row ' + item.row, {
                variant: 'error',
                action,
                persist: true
              });
            });
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setSelectedFile(null);
        setfileLoading(false);
      });
  };

  const action = (snackbarId) => (
    <>
      <Button
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <Typography style={{ color: 'white' }}> Dismiss</Typography>
      </Button>
    </>
  );

  function AddView(entity_id) {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('entity_id', Encryption(entity_id, process.env.REACT_APP_SECRET_APP));
    formdata.append('user_id', Encryption(self?.id, process.env.REACT_APP_SECRET_APP));
    formdata.append('type', Encryption('property', process.env.REACT_APP_SECRET_APP));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.save_view_logs, requestOptions)
      .then((response) => response.text())
      .then((result) => {})
      .catch((error) => console.log('error', error));
  }

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      setsearchLoading(true);
      setPage(1);
      getBusinesses(1);
    }
  };

  const [selectedStateObject, setselectedStateObject] = useState(null);
  const [states, setstates] = useState([]);
  const [state, setstate] = useState(null);

  function getStates() {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    fetch(api.getState, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.success == true) {
          let decryptedData = JSON.parse(Decryption(result.data));
          setstates(decryptedData);
        }
      })
      .catch((error) => console.log('error', error));
  }

  const handleChange = (newValue, type) => {
    type == 'price' && setprice(newValue);
    type == 'area' && setarea(newValue);
    type == 'bed' && setbed(newValue);
    type == 'bath' && setbath(newValue);
    type == 'garage' && setgarage(newValue);
  };

  return (
    <Page title={`Properties | ${process.env.REACT_APP_PROJECTNAME}`}>
      {fileLoading && <Loader />}

      <Container>
        {/* VIEWS MODAL */}
        <Modal
          style={style}
          open={viewModal}
          onClose={() => {
            setviewModal(false);
          }}
        >
          <Box
            sx={{ ...boxStyleNote, p: 0, minWidth: '50%', height: '90%' }}
            className="admin_modal_pa"
          >
            {/* PROPERTY NOTES */}
            <ViewsModal
              handleClose={() => {
                setviewModal(false);
              }}
              data={propertyID}
              refresh={refresh}
              permission={permission}
              offerpermission={offerpermission}
            />
          </Box>
        </Modal>
        {/* NOTES MODAL */}
        <Modal
          style={style}
          open={notesModal}
          onClose={() => {
            setnotesModal(false);
          }}
        >
          <Box
            sx={{ ...boxStyleNote, p: 0, minWidth: '50%', height: '90%' }}
            className="admin_modal_pa"
          >
            {/* PROPERTY NOTES */}
            <NotesModal
              handleClose={() => {
                setnotesModal(false);
              }}
              data={editData}
              refresh={refresh}
              permission={permission}
              offerpermission={offerpermission}
            />
          </Box>
        </Modal>
        {/* PROPERTY DETAIL MODAL */}
        <Modal style={style} open={detail} onClose={detailClose}>
          <Box
            sx={{ ...boxStyle, p: 0, minWidth: '70%', minHeight: '80%' }}
            className="admin_modal_pa"
          >
            {/* PROPERTY DETAIL */}
            <PropertyDetails
              handleClose={detailClose}
              details={business}
              refresh={refresh}
              closeandOpen={closeandOpen}
              permission={permission}
              offerpermission={offerpermission}
            />
          </Box>
        </Modal>

        {/* ADD Property MODAL */}
        <Modal style={style} open={propertyModal} onClose={closeproperty}>
          <Box
            sx={{ ...boxStyle, p: 0, width: '75%', maxHeight: '90%' }}
            className="admin_modal_pa"
          >
            <PropertyModal
              handleClose={closeproperty}
              refresh={propertyRefresh}
              edit={editproperty}
              data={editData}
            />
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            {t('Property.name')}
          </Typography>

          <Box>
            {permission.includes('add') && (
              <>
                <Button
                  onClick={(event) => {
                    seteditData(null);
                    seteditproperty(false);
                    openpropertyModal();
                  }}
                  variant="contained"
                  size="small"
                  //  color="error"
                  style={{ width: 120, marginRight: 5, height: 40 }}
                  component={RouterLink}
                  to="#"
                >
                  {t('Property.add')}
                </Button>
                <>
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ marginRight: 5, height: 40 }}
                  >
                    {t('Property.excel')}
                    <input
                      hidden
                      accept=".xls, .xlsx"
                      type="file"
                      onChange={(e) => {
                        handleFileChange(e);
                        e.target.value = null; // Clear the input value
                      }}
                    />
                  </Button>
                </>
              </>
            )}
          </Box>
        </Stack>

        {/* DRAWER FILTER */}

        <Drawer
          classes={{
            paper: 'width_drawer'
          }}
          anchor={'right'}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
        >
          {/* CLOSE BUTTON FOR MOBILE */}

          <Box pb={2} className="cross_drawer">
            <IconButton
              aria-label="close"
              style={{
                position: 'absolute',
                right: 5,
                marginBottom: 10
              }}
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'column'
            }}
          >
            {/* FILTERS PROPERTIES */}

            {/* <Box
              style={{
                justifyContent: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 20
              }}
            >
              <Button
                onClick={() => {
                  filterSetter('all business');
                  setallbusinesses(!allbusinesses);
                }}
                variant="contained"
                style={{
                  ...Buttonstyle,
                  backgroundColor: allbusinesses ? pallete.primary.main : 'silver',
                  color: allbusinesses ? 'white' : 'black'
                }}
              >
                ALL PROPERTIES ({count?.count_all_properties})
              </Button>

              <Button
                onClick={() => {
                  filterSetter('available');
                  setavailable(!available);
                }}
                variant="contained"
                style={{
                  ...Buttonstyle,
                  backgroundColor: available ? pallete.primary.main : 'silver',
                  color: available ? 'white' : 'black'
                }}
                component={RouterLink}
                to="#"
              >
                {'AVAILABLE'}
              </Button>

              <Button
                onClick={() => {
                  filterSetter('HOLD');
                  sethold(!hold);
                }}
                variant="contained"
                style={{
                  ...Buttonstyle,
                  backgroundColor: hold ? pallete.primary.main : 'silver',
                  color: hold ? 'white' : 'black'
                }}
                component={RouterLink}
                to="#"
              >
                {'HOLD'}
              </Button>

              <Button
                onClick={() => {
                  filterSetter('request');
                  setrequest(!request);
                }}
                variant="contained"
                style={{
                  ...Buttonstyle,
                  backgroundColor: request ? pallete.primary.main : 'silver',
                  color: request ? 'white' : 'black'
                }}
                component={RouterLink}
                to="#"
              >
                {'REQUEST'}
              </Button>

              <Button
                onClick={() => {
                  filterSetter('contracting');
                  setcontracting(!contracting);
                }}
                variant="contained"
                style={{
                  ...Buttonstyle,
                  backgroundColor: contracting ? pallete.primary.main : 'silver',
                  color: contracting ? 'white' : 'black'
                }}
                component={RouterLink}
                to="#"
              >
                {'CONTRACTING'}
              </Button>

              <Button
                onClick={() => {
                  filterSetter('underconstruction');
                  setunderconstruction(!underconstruction);
                }}
                variant="contained"
                style={{
                  ...Buttonstyle,
                  backgroundColor: underconstruction ? pallete.primary.main : 'silver',
                  color: underconstruction ? 'white' : 'black'
                }}
                component={RouterLink}
                to="#"
              >
                {'UNDER CONSTRUCTION'}
              </Button>

              <Button
                onClick={() => {
                  filterSetter('settled');
                  setsettled(!settled);
                }}
                variant="contained"
                style={{
                  ...Buttonstyle,
                  backgroundColor: settled ? pallete.primary.main : 'silver',
                  color: settled ? 'white' : 'black'
                }}
                component={RouterLink}
                to="#"
              >
                {'SETTLED'}
              </Button>

              <Button
                onClick={() => {
                  filterSetter('pending');
                  setpending(!pending);
                }}
                variant="contained"
                style={{
                  ...Buttonstyle,
                  backgroundColor: pending ? pallete.primary.main : 'silver',
                  color: pending ? 'white' : 'black'
                }}
                component={RouterLink}
                to="#"
              >
                {'PENDING'}
              </Button>
            </Box> */}

            <Box
              style={{
                flexDirection: 'row',
                display: 'flex',
                flexWrap: 'wrap',
                width: '90%',
                alignSelf: 'center'
              }}
            >
              <Box
                style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 10 }}
              >
                <TypesDropDown
                  placeholder={t('Property.filter.9')}
                  filter
                  setFunction={(val) => {
                    settype(val);
                    filterSetter();
                  }}
                  Width={'100%'}
                  dontAppend
                  value={type}
                />

                <TextField
                  style={{ marginTop: 20 }}
                  className="width_full_property"
                  id="outlined-select-currency"
                  select
                  label={'State'}
                  value={selectedStateObject}
                  onChange={(event) => {
                    setstate(event.target.value?.name);
                    setselectedStateObject(event.target.value);
                  }}
                  InputLabelProps={{ shrink: !!selectedStateObject }}
                  InputProps={{
                    endAdornment: selectedStateObject && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setstate(''); // Clear the state value
                            setselectedStateObject(null); // Clear the selectedStateObject
                          }}
                          style={{ marginRight: 15 }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                >
                  {states.map((option) => (
                    <MenuItem key={option.id} value={option}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>

            <Box
              p={1}
              style={{
                flexDirection: 'row',
                display: 'flex',
                flexWrap: 'wrap',
                width: '90%',
                alignSelf: 'center',
                marginTop: 50
              }}
            >
              <Typography style={{ marginTop: 5, fontSize: 12 }}>
                {t('Property.filter.14')}
              </Typography>

              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={price}
                onChange={(e, val) => {
                  handleChange(val, 'price');
                  filterSetter();
                }}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={100}
                max={1000000}
                defaultValue={1000000}
                step={5000}
                size="small"
              />

              <Typography style={{ marginTop: 5, fontSize: 12 }}>
                {t('Property.filter.15')}
              </Typography>

              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={area}
                onChange={(e, val) => {
                  handleChange(val, 'area');
                  filterSetter();
                }}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={10}
                max={10000}
                defaultValue={10000}
                step={500}
                size="small"
              />

              <Typography style={{ marginTop: 5, fontSize: 12 }}>
                {t('Property.filter.16')}
              </Typography>

              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={bed}
                onChange={(e, val) => {
                  handleChange(val, 'bed');
                  filterSetter();
                }}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={1}
                max={10}
                defaultValue={10}
                step={1}
                size="small"
              />

              <Typography style={{ marginTop: 5, fontSize: 12 }}>
                {t('Property.filter.17')}
              </Typography>

              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={bath}
                onChange={(e, val) => {
                  handleChange(val, 'bath');
                  filterSetter();
                }}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={1}
                max={10}
                defaultValue={10}
                step={1}
                size="small"
              />

              <Typography style={{ marginTop: 5, fontSize: 12 }}>Car</Typography>

              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={garage}
                onChange={(e, val) => {
                  handleChange(val, 'garage');
                  filterSetter();
                }}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={1}
                max={10}
                defaultValue={10}
                step={1}
                size="small"
              />
            </Box>

            <Box
              style={{
                width: '90%',
                alignSelf: 'center',
                marginTop: 20,
                marginBottom: 20
              }}
            >
              <Button
                onClick={(event) => {
                  setOpenDrawer(false);
                  setsearchLoading(true);
                  //CHECKING
                  setPage(1);
                  getBusinesses(1);
                }}
                variant="outlined"
                component={RouterLink}
                to="#"
                fullWidth
                style={{ height: 50 }}
              >
                {t('Property.filter.19')}
              </Button>
            </Box>
          </Box>
        </Drawer>

        {/* FILTER PROPERTIES END */}
        {/* <Box>
          
        </Box> */}

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between'
            }}
            className="top_options"
          >
            <Searchbar
              width={'84%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              placeholder={t('Agents.search') + '...'}
              handleKeyPress={handleKeyPress}
            />

            {/* <CityDropdown
              setFunction={(val) => {
                setcity(val);
              }}
              Width={'24%'}
              dontAppend
            /> */}

            {/* GOOGLE PLACES */}
            {/* <GooglePlaces
              width={'24%'}
              onResult={(val) => {
                setaddress(val);
              }}
              regions={true}
              placeholder={t('Property.loc')}
            /> */}
            {/* <CategoryDropDown
              placeholder={t('Property.category')}
              setFunction={(val) => {
                setcategory(val);
              }}
              Width={'24%'}
              dontAppend
              margin_
            /> */}

            <LoadingButton
              style={{ width: '4%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                setPage(1);
                getBusinesses(1);
              }}
              className="width_full"
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
            <LoadingButton
              style={{ width: '4%' }}
              variant="contained"
              //   loading={searchLoading}
              onClick={() => {
                setOpenDrawer(true);
              }}
              className="width_full"
            >
              <Iconify icon="ion:filter" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table ref={tableRef}>
                <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                <TableBody>
                  {list.map((row, index) => {
                    const {
                      id,
                      thumbnail_0,
                      title,
                      description,
                      seen,
                      price,
                      square_feet,
                      lot_size,
                      bedroom,
                      bathroom,
                      garage,
                      address,
                      status,
                      market_status,
                      view_count,
                      property_type,
                      lot,
                      suburbs,
                      state,
                      estate,
                      width,
                      depth,
                      land_price,
                      build_price,
                      title_date,
                      body_corp
                    } = row;

                    return (
                      <TableRow
                        onClick={() => {
                          // seen == '0' && mark('business', id);
                          self.role == 1 && AddView(id);
                          openModal(row);
                        }}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)'
                        }}
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        {/* PROPERTY NAME */}

                        <TableCell scope="row" padding="normal" style={{ minWidth: 80 }}>
                          <Typography flexWrap={'wrap'} className="tableFont">
                            {title}
                          </Typography>
                        </TableCell>

                        {/* STATUS */}

                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          <Typography
                            style={{
                              textTransform: 'uppercase',
                              fontWeight: 'bold'
                            }}
                            className="tableFont"
                          >
                            {CheckStatus(status, market_status)}
                          </Typography>
                        </TableCell>

                        {/* PROPERTY TYPE */}

                        <TableCell scope="row" padding="normal" style={{ maxWidth: 100 }}>
                          <Typography flexWrap={'nowrap'} className="tableFont">
                            {property_type ? property_type?.name : 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* LOT */}

                        <TableCell scope="row" padding="normal" style={{ maxWidth: 70 }}>
                          <Typography noWrap className="tableFont">
                            {lot}
                          </Typography>
                        </TableCell>

                        {/* ADDRESS */}

                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          <Typography className="tableFont">{address}</Typography>
                        </TableCell>

                        {/* SUBURB */}

                        <TableCell align="left" style={{ maxWidth: 80 }}>
                          <Typography className="tableFont">{suburbs}</Typography>
                        </TableCell>

                        {/* ESTATE */}

                        <TableCell align="left" style={{ maxWidth: 80 }}>
                          <Typography className="tableFont">{estate}</Typography>
                        </TableCell>

                        {/* STATE */}

                        <TableCell align="left" style={{ maxWidth: 60 }}>
                          <Typography className="tableFont">{state}</Typography>
                        </TableCell>

                        {/* TITLE DATE */}
                        <TableCell align="left" style={{ minWidth: 70 }}>
                          <Typography className="tableFont">
                            {title_date ? title_date : 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* DIMENSION */}
                        <TableCell align="left" style={{ maxWidth: 80 }}>
                          <Typography className="tableFont">
                            Width: {width ? width : 'N/A'}
                          </Typography>
                          <Typography className="tableFont">
                            Depth: {depth ? depth : 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* LAND SIZE */}
                        <TableCell align="left" style={{ maxWidth: 50 }}>
                          <Typography className="tableFont" textAlign={'center'}>
                            {square_feet}
                          </Typography>
                        </TableCell>
                        {/*LAND PRICE */}
                        <TableCell align="left" style={{ minWidth: 50 }}>
                          <Typography align={'center'} className="tableFont">
                            {land_price ? fCurrency(land_price) : 'N/A'}
                          </Typography>
                        </TableCell>
                        {/* BUILD PRICE */}
                        <TableCell align="left" style={{ minWidth: 50 }}>
                          <Typography align={'center'} className="tableFont">
                            {build_price ? fCurrency(build_price) : 'N/A'}
                          </Typography>
                        </TableCell>
                        {/* TOTAL PRICE */}
                        <TableCell align="left" style={{ minWidth: 50 }}>
                          <Typography align={'center'} className="tableFont">
                            {price ? fCurrency(price) : 'N/A'}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          <Typography className="tableFont" textAlign={'center'}>
                            {description ? description : 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* INFO */}

                        <TableCell align="left" style={{ minWidth: 80, maxWidth: 80 }}>
                          <Typography className="tableFont">
                            Bed: {bedroom ? bedroom : 'N/A'}
                          </Typography>
                          <Typography className="tableFont">
                            Bath: {bathroom ? bathroom : 'N/A'}
                          </Typography>
                          <Typography className="tableFont">
                            Car: {garage ? garage : 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* BUILD SIZE */}

                        <TableCell align="left" style={{ minWidth: 50, maxWidth: 60 }}>
                          <Typography className="tableFont" textAlign={'center'}>
                            {lot_size ? lot_size : 'N/A'}
                          </Typography>
                        </TableCell>

                        {/* BODY CORP */}
                        <TableCell align="left" style={{ minWidth: 50, maxWidth: 60 }}>
                          <Typography className="tableFont" textAlign={'center'}>
                            {body_corp ? body_corp : 'N/A'}
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ maxWidth: 150 }}
                          onClick={(event) => {
                            if (self.role != 1) {
                              event.stopPropagation();
                              setpropertyID(id);
                              setviewModal(true);
                            }
                          }}
                        >
                          <Typography
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: 'bold',
                              fontSize: 12,
                              textAlign: 'center'
                            }}
                          >
                            {view_count}
                          </Typography>
                        </TableCell>

                        {/* ACTIONS */}
                        <TableCell style={{ position: 'relative', width: '200px' }}>
                          {status !== 'deleted' && (
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                              }}
                            >
                              <>
                                {permission.includes('delete') && (
                                  <LoadingButton
                                    startIcon={
                                      <Iconify
                                        color={pallete.primary.main}
                                        icon="ic:baseline-delete"
                                      />
                                    }
                                    style={{
                                      width: 85,
                                      justifyContent: 'flex-start',
                                      paddingLeft: 8
                                    }}
                                    variant="outlined"
                                    loading={selectedIndex == index && deleting}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      DeleteProperty(id, index, market_status);
                                    }}
                                  >
                                    {t('Property.buttons.0')}
                                  </LoadingButton>
                                )}
                              </>

                              {permission.includes('edit') && (
                                <Button
                                  startIcon={
                                    <Iconify color={pallete.primary.main} icon="tabler:edit" />
                                  }
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    seteditData(row);
                                    seteditproperty(true);
                                    openpropertyModal();
                                  }}
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  style={{
                                    width: 85,
                                    marginTop: 5,
                                    justifyContent: 'flex-start'
                                  }}
                                  component={RouterLink}
                                  to="#"
                                >
                                  {t('Property.buttons.1')}
                                </Button>
                              )}
                              {permission.includes('notes') && (
                                <Button
                                  startIcon={
                                    <Iconify color={pallete.primary.main} icon="gg:notes" />
                                  }
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();

                                    seteditData(row);
                                    setnotesModal(true);
                                  }}
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  component={RouterLink}
                                  to="#"
                                  style={{
                                    width: 85,
                                    justifyContent: 'flex-start',
                                    marginTop: 5
                                  }}
                                >
                                  {t('Property.buttons.2')}
                                </Button>
                              )}
                              {self?.id !== row?.realtor_id?.id && (
                                <Button
                                  size="small"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    //CHAT URL
                                    window.open(`${chatUrl}${self?.id}/${row?.realtor_id?.id}`);
                                  }}
                                  style={{
                                    width: 85,
                                    justifyContent: 'flex-start',
                                    marginTop: 5
                                  }}
                                  variant="outlined"
                                  component={RouterLink}
                                  to="#"
                                  startIcon={
                                    <Iconify
                                      color={pallete.primary.main}
                                      icon="material-symbols:chat-outline"
                                    />
                                  }
                                >
                                  {t('Agents.buttons.2')}
                                </Button>
                              )}
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isBusinessFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !searchLoading && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
