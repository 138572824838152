import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  MenuItem,
  CircularProgress
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AgentDropDown from 'src/layouts/dashboard/AgentsDropDown';
import palette from 'src/theme/palette';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImgsViewer from 'react-images-viewer';
import { api } from 'src/Api';
import Iconify from 'src/components/Iconify';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';
import pallete from '../../../theme/palette';
import { chatUrl } from 'src/Api';
import Label from 'src/components/Label';
import { fCurrency } from 'src/utils/formatNumber';

export default function PropertyDetails({
  handleClose,
  details,
  refresh,
  permission,
  offerpermission
}) {
  const self = JSON.parse(localStorage.getItem('user'));
  const { t } = useTranslation();
  const [companyPermission, setcompanyPermission] = useState([]);
  const TypeCheckArray = ['shop', 'land', 'acreage', 'rural'];

  function checkPermission() {
    let requirement = ['visible', 'add', 'edit', 'change agents'];
    let check = 'companies';

    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );

    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setcompanyPermission((permissions) => [
            ...permissions,
            items.permission_type_name.toLowerCase()
          ])
        : false
    );
  }

  const [propertyDetails, setpropertyDetails] = useState(details);
  const [propertyloading, setpropertyloading] = useState(false);
  function getProperty() {
    setpropertyDetails(details);
  }

  const [imagesMultiple, setimagesMultiple] = useState([]);
  const [fullImage, setfullImage] = useState(false);
  const [mainLength, setmainLength] = useState(0);

  function imagesArray() {
    setmainLength(imagesMultiple.length);
    imagesMultiple.length = 0;
    if (propertyDetails?.property_images?.length > 0) {
      propertyDetails?.property_images?.map((item, index) => {
        if (item.type == 'image') {
          setimagesMultiple((imagesMultiple) => [
            ...imagesMultiple,
            {
              src: item?.name
            }
          ]);
        }
      });
    }
  }
  useEffect(() => {
    imagesArray();
  }, []);

  useEffect(() => {
    console.log(details);
    getProperty();
    checkPermission();
  }, []);

  const [index, setindex] = useState(0);

  function gotoPrevious() {
    if (index >= 0 && index <= mainLength) {
      setindex(index - 1);
    }
  }
  function gotoNext() {
    if (index >= 0 && index < mainLength) {
      setindex(index + 1);
    }
  }

  function settingFullImage(index) {
    imagesArray();
    setindex(index);
    setfullImage(true);
  }

  const headingTypography = { fontSize: 14, fontWeight: 'bold', paddingRight: 1 };
  const headingDescription = { fontSize: 14 };
  const [showShifts, setshowShifts] = useState(false);
  const [showDocuments, setshowDocuments] = useState(false);

  function changeAgent(id) {
    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    formdata.append(
      'property_id',
      Encryption(propertyDetails?.id, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append('realtor_id', Encryption(id, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.property_realtor_change, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        refresh();
      })
      .catch((error) => console.log('error', error));
  }

  const button = {
    marginRight: 5
  };

  function ChangeStatusOLD(status, market_status) {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    formdata.append('from_id', Encryption(user?.id, process.env.REACT_APP_SECRET_KEY));
    formdata.append(
      'property_id',
      Encryption(propertyDetails?.id, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append('status', Encryption(status, process.env.REACT_APP_SECRET_KEY));
    formdata.append('market_status', Encryption(market_status, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.save_property_history, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        refresh();
      })
      .catch((error) => console.log('error', error));
  }

  function ChangeStatus(status) {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    //  formdata.append('from_id', Encryption(user?.id, process.env.REACT_APP_SECRET_KEY));
    formdata.append(
      'property_id',
      Encryption(propertyDetails?.id, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append('status', Encryption(status, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.update_status, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        refresh();
      })
      .catch((error) => console.log('error', error));
  }

  function getVideoId(url) {
    if (url.includes('watch')) {
      return url?.split('=').pop();
    } else {
      return url?.split('/').pop();
    }
  }

  const StatusArray = [
    { name: 'AVAILABLE', id: 1 },
    { name: 'ON HOLD', id: 1 },
    { name: 'PENDING', id: 1 }
  ];

  const [supportLetters, setsupportLetters] = useState(
    details.support_letters ? details.support_letters.split(',') : []
  );

  const [showSupport, setshowSupport] = useState(false);
  //  console.log(details.support_letters.split(','));
  return (
    <Box>
      <>
        {propertyloading ? (
          <Box
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              height: 500
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* STICKY HEADER PRICE AND TITLE */}
            <Box
              pt={2}
              pb={2}
              style={{
                backgroundColor: 'white',
                position: 'sticky',
                top: 0,
                borderBottom: '2px solid',
                borderColor: palette.primary.main,
                zIndex: 5,
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box px={2}>
                <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                  {propertyDetails?.title}
                </Typography>
                {propertyDetails?.on_call == 1 ? (
                  <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                    {t('PropertyDetail.on_call')}{' '}
                  </Typography>
                ) : (
                  <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                    Total Price: {fCurrency(propertyDetails?.price)}
                    {propertyDetails?.tenure && `/ ${propertyDetails?.tenure}`}
                  </Typography>
                )}
              </Box>
              <Box px={2} style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap' }}>
                <Button color="primary" variant="contained" onClick={handleClose} size={'small'}>
                  {t('PropertyDetail.buttons.0')}
                </Button>
              </Box>
            </Box>

            <Box px={4} py={2} className="property_Modal_Padding">
              {/* IMAGE SLIDER WHOLE SCREEN */}
              <ImgsViewer
                imgs={imagesMultiple}
                currImg={index}
                onClickPrev={gotoPrevious}
                onClickNext={gotoNext}
                isOpen={fullImage}
                onClose={() => setfullImage(false)}
              />

              {/* IMAGES ARRAY */}
              {propertyDetails?.property_images?.filter((item) => item.type == 'image').length >
                0 && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.0')}:
                    </Typography>
                    <Box my={1}>
                      <ImageList
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}
                        cols={5}
                        rowHeight={100}
                        className="justify"
                      >
                        {propertyDetails?.property_images.map((item, index) =>
                          item.type == 'image' ? (
                            <ImageListItem
                              style={{
                                borderRadius: 15,
                                overflow: 'hidden',
                                border: '2px solid',
                                borderColor: palette.primary.main
                                //           marginRight: 5
                              }}
                              onClick={() => settingFullImage(index)}
                              key={item?.index}
                              //    className="image_Margin"
                            >
                              <img
                                className="image_Width"
                                style={{ width: 90 }}
                                src={item?.name}
                                alt={'Business Image'}
                                loading="lazy"
                              />
                            </ImageListItem>
                          ) : (
                            ''
                          )
                        )}
                      </ImageList>

                      {/* <iframe
                        //    className="ndfHFb-c4YZDc-Wrql6b"
                        src="https://drive.google.com/file/d/1UqCrZywVTwNfTqZE4ANkd6PyaGGh9-dp/preview?rm=minimal"
                        width="90"
                        height="90"
                        frameborder="0"
                        seamless=""
                        sandbox="allow-same-origin allow-scripts"
                      ></iframe> */}
                    </Box>
                  </Box>
                  <Divider />
                </>
              )}

              {/* VIDEO */}
              {propertyDetails?.property_images?.filter(
                (item) => item.type == 'video' || item.type == 'youtube'
              ).length > 0 && (
                <Box
                  mt={0.5}
                  width="100%"
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography sx={headingTypography} component="div">
                    {t('PropertyDetail.headings.1')}:
                  </Typography>
                  {propertyDetails?.property_images?.map((item, index) => (
                    <Box style={{ marginTop: 1 }}>
                      {item?.type == 'youtube' && (
                        <iframe
                          className="video_property"
                          width="400"
                          height="200"
                          src={'https://www.youtube.com/embed/' + getVideoId(item?.name)}
                          frameborder="0"
                        ></iframe>
                      )}
                      {item?.type == 'video' && (
                        <video src={item?.name} width="400" height="200" autoPlay></video>
                      )}
                    </Box>
                  ))}

                  <Divider />
                </Box>
              )}
              {/* DESCRIPTION */}
              {propertyDetails?.description != null && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                    className="column"
                  >
                    <Typography sx={headingTypography} component="div">
                      {'Design'}:
                    </Typography>

                    <Typography
                      row
                      sx={{ ...headingDescription, textAlign: 'justify' }}
                      component="div"
                    >
                      {propertyDetails?.description || 'N/A'}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}

              <>
                <Box
                  py={1}
                  width="100%"
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                  className="column"
                >
                  <Typography sx={headingTypography} component="div">
                    {'Title Date'}:
                  </Typography>

                  <Typography
                    row
                    sx={{ ...headingDescription, textAlign: 'justify' }}
                    component="div"
                  >
                    {propertyDetails?.title_date || 'N/A'}
                  </Typography>
                </Box>
                <Divider />
              </>

              <>
                <Box
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                  className="column"
                >
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                    className="column"
                  >
                    <Typography sx={headingTypography} component="div">
                      {'Rental Info'}:
                    </Typography>
                    <Typography
                      row
                      sx={{ ...headingDescription, textAlign: 'justify' }}
                      component="div"
                    >
                      {propertyDetails?.rental_information || 'N/A'}
                    </Typography>
                  </Box>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                    className="column"
                  >
                    <Typography sx={headingTypography} component="div">
                      {'Body Corp'}:
                    </Typography>
                    <Typography
                      row
                      sx={{ ...headingDescription, textAlign: 'justify' }}
                      component="div"
                    >
                      {propertyDetails?.body_corp || 'N/A'}
                    </Typography>
                  </Box>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                    className="column"
                  ></Box>
                </Box>
                <Divider />
              </>

              {/* ADDRESS */}
              {propertyDetails?.address != null && (
                <>
                  <Box
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                    className="column"
                  >
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                      className="column"
                    >
                      <Typography sx={headingTypography} component="div">
                        {t('PropertyDetail.headings.3')}:
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.address}
                      </Typography>
                    </Box>
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                      className="column"
                    >
                      <Typography sx={headingTypography} component="div">
                        Suburb:
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.suburbs}
                      </Typography>
                    </Box>
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                      className="column"
                    >
                      <Typography sx={headingTypography} component="div">
                        Estate:
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.estate}
                      </Typography>
                    </Box>
                  </Box>

                  <Divider />
                </>
              )}
              {/* BEDS,BATHROOM AND GARAGES */}
              {!TypeCheckArray.includes(propertyDetails?.property_type?.name.toLowerCase()) && (
                <>
                  <Box
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Typography sx={headingTypography} component="div">
                        {t('PropertyDetail.headings.4')}:
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.bedroom}
                      </Typography>
                    </Box>
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Typography sx={headingTypography} component="div">
                        {t('PropertyDetail.headings.5')}:
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.bathroom}
                      </Typography>
                    </Box>
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Typography sx={headingTypography} component="div">
                        {'Cars'}:
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.garage}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                </>
              )}
              {/* AREA */}
              {(propertyDetails.square_feet != null || propertyDetails.square_feet != null) && (
                <>
                  <Box
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                    className="column"
                  >
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Typography sx={headingTypography} component="div">
                        Lot:
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.lot}
                      </Typography>
                    </Box>
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Typography sx={headingTypography} component="div">
                        Land Size:
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.square_feet}
                      </Typography>
                    </Box>
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Typography sx={headingTypography} component="div">
                        Build Size:
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.lot_size}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                </>
              )}
              {/* OPEN INSPECTIONS */}
              {/* {propertyDetails?.open_inspections?.length > 0 && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.22')}
                    </Typography>

                    <Box
                      onClick={() => {
                        setshowShifts(!showShifts);
                      }}
                    >
                      <Iconify
                        style={{ fontSize: 24, marginTop: 5 }}
                        icon={showShifts ? 'mdi:chevron-up' : 'mdi:chevron-down'}
                      />
                    </Box>
                  </Box>
                  {showShifts && (
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      {propertyDetails?.open_inspections?.map((item) => {
                        return (
                          <Typography sx={{ fontSize: 14, paddingBottom: 1 }} component="div">
                            {`${item.date}    ( ${item.start_time} )   ( ${item.end_time} )`}
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                  <Divider />
                </>
              )} */}
              {/* OFF PLAN PROJECT */}
              {/* {propertyDetails?.off_plan_project == 1 && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.9')}
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.off_plan_start &&
                        `${t('PropertyDetail.headings.16')} (${propertyDetails?.off_plan_start}) `}
                      {propertyDetails?.off_plan_end &&
                        `${t('PropertyDetail.headings.17')} (${propertyDetails?.off_plan_end}) `}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )} */}
              {/* PROPERTY TYPE & CATEGORY */}
              <>
                <Box
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                  className="column"
                >
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.11')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.property_type?.name}
                    </Typography>
                  </Box>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      Width:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.width || 'N/A'}
                    </Typography>
                  </Box>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      Depth:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.depth || 'N/A'}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </>
              {/* SALE TYPE */}
              {propertyDetails?.sale_type_method != null && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.18')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.sale_type_method?.name}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {/* CONSTRUCTION STATUS */}
              {propertyDetails?.construction_status != null && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.12')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.construction_status?.name}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {/* BUILD IN */}
              {propertyDetails?.year_of_build_check === 1 && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.19')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.year_of_build}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {/* EXCLUSIVE AUTHORITY */}
              {propertyDetails?.exclusive_authority != null && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.20')}:
                    </Typography>

                    <Typography row sx={headingDescription} component="div">
                      {propertyDetails?.exclusive_authority}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}

              {/* PRICE ROW */}
              {
                <>
                  <Box
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                    className="column"
                  >
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Typography sx={headingTypography} component="div">
                        Land Price:
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.land_price
                          ? fCurrency(propertyDetails?.land_price)
                          : 'N/A'}
                      </Typography>
                    </Box>
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Typography sx={headingTypography} component="div">
                        Build Price
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.build_price
                          ? fCurrency(propertyDetails?.build_price)
                          : 'N/A'}
                      </Typography>
                    </Box>
                    <Box
                      py={1}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Typography sx={headingTypography} component="div">
                        Total Price:
                      </Typography>

                      <Typography row sx={headingDescription} component="div">
                        {propertyDetails?.price ? fCurrency(propertyDetails?.price) : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                </>
              }
              {/* DOCUMENTS */}
              {propertyDetails?.documents.length > 0 && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.21')}
                    </Typography>

                    <Box
                      onClick={() => {
                        setshowDocuments(!showDocuments);
                      }}
                    >
                      <Iconify
                        style={{ fontSize: 24, marginTop: 5 }}
                        icon={showShifts ? 'mdi:chevron-up' : 'mdi:chevron-down'}
                      />
                    </Box>
                  </Box>
                  {showDocuments && (
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      {propertyDetails?.documents.map((item, index) => {
                        return (
                          <>
                            <Box
                              py={1}
                              width="100%"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Typography sx={headingDescription} component="div">
                                {item?.name}
                              </Typography>

                              <Box
                                onClick={() => {
                                  window.open(item?.attachment);
                                }}
                              >
                                <Iconify
                                  style={{ fontSize: 24, marginTop: 5, marginRight: 0 }}
                                  icon={'material-symbols:download'}
                                />
                              </Box>
                            </Box>
                            <Divider />
                          </>
                        );
                      })}
                    </Box>
                  )}
                  <Divider />
                </>
              )}
              {/* SUPPORT LETTERS */}
              {supportLetters.length > 0 && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {'Support Letters'}
                    </Typography>

                    <Box
                      onClick={() => {
                        setshowSupport(!showSupport);
                      }}
                    >
                      <Iconify
                        style={{ fontSize: 24, marginTop: 5 }}
                        icon={showSupport ? 'mdi:chevron-up' : 'mdi:chevron-down'}
                      />
                    </Box>
                  </Box>
                  {showSupport && (
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      {supportLetters?.map((item, index) => {
                        return (
                          <>
                            <Box
                              py={1}
                              width="100%"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Typography
                                onClick={() => {
                                  window.open(item);
                                }}
                                sx={headingDescription}
                                component="div"
                                fontWeight={'bold'}
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                {item}
                              </Typography>
                            </Box>
                            <Divider />
                          </>
                        );
                      })}
                    </Box>
                  )}
                  <Divider />
                </>
              )}
              {/* AGENT NAME */}
              <Box
                py={2}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                <Box
                  py={1}
                  mr={2}
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <Typography sx={headingTypography} component="div">
                    {t('PropertyDetail.headings.13')}:
                  </Typography>

                  <Typography
                    row
                    sx={headingDescription}
                    component="div"
                    style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                  >
                    {propertyDetails?.realtor_id?.name}
                  </Typography>
                </Box>
                {self?.id !== propertyDetails?.realtor_id?.id && (
                  <Button
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      window.open(`${chatUrl}${self?.id}/${propertyDetails?.realtor_id?.id}`);
                    }}
                    style={{
                      justifyContent: 'flex-start'
                    }}
                    variant="outlined"
                    component={RouterLink}
                    to="#"
                    startIcon={
                      <Iconify color={pallete.primary.main} icon="material-symbols:chat-outline" />
                    }
                  >
                    {t('Agents.buttons.2')}
                  </Button>
                )}
              </Box>
              <Divider />
              {/* CHANGE AGENT */}
              {permission?.includes('change agent') && (
                <>
                  <Box
                    py={1}
                    width="100%"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <Typography sx={headingTypography} component="div">
                      {t('PropertyDetail.headings.14')}:
                    </Typography>

                    <AgentDropDown
                      realtor_company={propertyDetails?.realtor_company}
                      placeholder={t('PropertyDetail.headings.23')}
                      selectedAgent={propertyDetails?.realtor_id?.id}
                      setFunction={(val) => {
                        changeAgent(val);
                      }}
                      Width={'40%'}
                      dontAppend
                      margin_
                    />
                  </Box>
                  <Divider />
                </>
              )}

              {/* STATUS CHANGE */}

              {permission.includes('change status') && (
                <>
                  <Box
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                    className="column"
                  >
                    <Box
                      py={2}
                      width="100%"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <Typography sx={{ ...headingTypography, width: 100 }} component="div">
                        {t('PropertyDetail.headings.15')}:
                      </Typography>

                      <Box>
                        {propertyDetails?.status.toLowerCase() !== 'hold' && (
                          <Button
                            className="margin"
                            style={button}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              //        ChangeStatus('AVAILABLE', 'AVALABLE');
                              ChangeStatus('HOLD');
                            }}
                            size={'small'}
                          >
                            HOLD
                          </Button>
                        )}
                        {propertyDetails?.status.toLowerCase() !== 'available' && (
                          <Button
                            className="margin"
                            style={button}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              //      ChangeStatus('AVAILABLE', 'AVALABLE');
                              ChangeStatus('AVAILABLE');
                            }}
                            size={'small'}
                          >
                            AVAILABLE
                          </Button>
                        )}
                        {propertyDetails?.status.toLowerCase() !== 'pending' && (
                          <Button
                            className="margin"
                            style={button}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              //    ChangeStatus('PENDING', 'AVALABLE');
                              ChangeStatus('PENDING');
                            }}
                            size={'small'}
                          >
                            PENDING
                          </Button>
                        )}
                        {propertyDetails?.status.toLowerCase() !== 'settled' && (
                          <Button
                            className="margin"
                            style={button}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              //    ChangeStatus('PENDING', 'AVALABLE');
                              ChangeStatus('SETTLED');
                            }}
                            size={'small'}
                          >
                            SETTLED
                          </Button>
                        )}
                        {propertyDetails?.status.toLowerCase() !== 'under construction' && (
                          <Button
                            className="margin"
                            style={button}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              //    ChangeStatus('PENDING', 'AVALABLE');
                              ChangeStatus('UNDER CONSTRUCTION');
                            }}
                            size={'small'}
                          >
                            UNDER CONSTRUCTION
                          </Button>
                        )}
                        {propertyDetails?.status.toLowerCase() !== 'contracting' && (
                          <Button
                            className="margin"
                            style={button}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              //    ChangeStatus('PENDING', 'AVALABLE');
                              ChangeStatus('CONTRACTING');
                            }}
                            size={'small'}
                          >
                            CONTRACTING
                          </Button>
                        )}
                        {propertyDetails?.status.toLowerCase() !== 'request' && (
                          <Button
                            className="margin"
                            style={button}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              //    ChangeStatus('PENDING', 'AVALABLE');
                              ChangeStatus('REQUEST');
                            }}
                            size={'small'}
                          >
                            REQUEST
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              <Box
                py={2}
                width="100%"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Typography sx={{ ...headingTypography, width: 100 }} component="div">
                  Status:
                </Typography>

                <Box>
                  <Label style={{ height: 31 }} variant="ghost" color="success">
                    {propertyDetails?.status.toUpperCase()}
                  </Label>
                </Box>
              </Box>
              <Divider />
            </Box>
            {propertyDetails?.lat && propertyDetails?.long && (
              <Box
                style={{
                  height: '400px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <iframe
                  style={{ width: '95%', height: '400px', alignSelf: 'center' }}
                  src={
                    'https://maps.google.com/maps?key=AIzaSyCfkkFnJ7ixENdtACx3-Q5Mewh3wftkCo8&q=' +
                    propertyDetails?.lat +
                    ',' +
                    propertyDetails?.long +
                    '&hl=es;z=14&output=embed'
                  }
                ></iframe>
              </Box>
            )}
          </>
        )}
      </>
    </Box>
  );
}
