import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Checkbox,
  Typography,
  Alert,
  MenuItem
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { api } from 'src/Api';

// ----------------------------------------------------------------------

export default function HelpModal({ handleClose, refresh }) {
  const [topic, settopic] = useState('');
  const [answer, setanswer] = useState('');
  const [category, setcategory] = useState('');
  const [loading, setloading] = useState(false);

  function AddHelp() {
    if (topic === '') {
      setalert({ visible: true, message: 'Help category is required' });
      return;
    }
    if (category === '') {
      setalert({ visible: true, message: 'Help question is required' });
      return;
    }
    if (answer === '') {
      setalert({ visible: true, message: 'Help answer  is required' });
      return;
    }

    var myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', 'Bearer ' + token);
    var formdata = new FormData();
    formdata.append('help_topic', category);
    formdata.append('help_category', topic);
    formdata.append('answer', answer);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.saveHelp, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        console.log(result);
        if (result.success === true) {
          refresh();
        } else {
          setalert({ visible: true, message: result.message });
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error', error);
      })
      .finally(() => setloading(false));
  }

  function getHelp() {
    const token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.getHelpCateogries, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let response = JSON.parse(result);
        setcategories(response.data);
      })
      .catch((error) => console.log('error', error));
  }
  useEffect(() => {
    if (topic !== '' || category !== '' || answer !== '') {
      setalert({ visible: false, message: '' });
    }
  }, [topic, category, answer]);
  const [alert, setalert] = useState({ visible: false, message: '' });

  const [categories, setcategories] = useState([]);
  useEffect(() => {
    getHelp();
  }, []);
  const handleChange = (event) => {
    settopic(event.target.value);
  };
  return (
    <Box style={{ padding: 20 }}>
      <Stack spacing={3}>
        <Typography py={1} align="center" variant="h5">
          Add Help Question
        </Typography>
        {alert.visible && (
          <>
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity="error"
            >
              {alert.message}
            </Alert>
          </>
        )}
        <TextField
          style={{ width: '100%', marginBottom: 10 }}
          id="outlined-select-currency"
          select
          fullWidth
          label="Category"
          value={topic}
          onChange={handleChange}
        >
          {categories.length > 0 &&
            categories.map((option) => (
              <MenuItem key={option.id} value={option.question}>
                {option.question}
              </MenuItem>
            ))}
        </TextField>
        {/* <TextField
          fullWidth
          label="Category"
          onChange={(e) => {
            settopic(e.target.value);
          }}
        /> */}
        <TextField
          fullWidth
          label="Question"
          onChange={(e) => {
            setcategory(e.target.value);
          }}
        />
        <TextField
          fullWidth
          label=" Answer"
          onChange={(e) => {
            setanswer(e.target.value);
          }}
        />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <LoadingButton
            onClick={() => AddHelp()}
            style={{ width: '49%' }}
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
          >
            Save
          </LoadingButton>
          <Button
            sx={{
              width: '49%'
            }}
            onClick={handleClose}
            color="error"
            variant="contained"
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
