import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  Alert,
  IconButton,
  Link,
  InputAdornment,
  Avatar,
  MenuItem,
  CircularProgress
} from '@mui/material';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import format from 'date-fns/format';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Label from '../../../components/Label';
import ImgsViewer from 'react-images-viewer';
import palette from 'src/theme/palette';
import { LoadingButton } from '@mui/lab';
import { api } from 'src/Api';

export default function PostDecription({ handleClose, details, permission, refresh }) {
  const [loading, setloading] = useState(false);
  const [imagesMultiple, setimagesMultiple] = useState([]);
  const [fullImage, setfullImage] = useState(false);
  const [mainLength, setmainLength] = useState(0);
  function imagesArray() {
    setmainLength(imagesMultiple.length);
    imagesMultiple.length = 0;
    if (details.images.length > 0) {
      details.images.map((item, index) => {
        setimagesMultiple((imagesMultiple) => [
          ...imagesMultiple,
          {
            src: item.post_image
          }
        ]);
      });
    }
  }
  useEffect(() => {
    imagesArray();
  }, []);

  const [index, setindex] = useState(0);
  function gotoPrevious() {
    if (index >= 0 && index <= mainLength) {
      setindex(index - 1);
    }
  }
  function gotoNext() {
    if (index >= 0 && index < mainLength) {
      setindex(index + 1);
    }
  }

  function settingFullImage(index) {
    imagesArray();
    setindex(index);
    setfullImage(true);
  }

  const user = details.user[0];
  const tags = JSON.parse(details?.tags).tagsArray;

  const [rejectAlert, setrejectAlert] = useState({ visible: false, message: '' });
  const [reason, setreason] = useState('');
  const [rejectLoading, setrejectLoading] = useState(false);

  const [approveloading, setapproveloading] = useState(false);

  function postApprove(status) {
    if (rejectAlert.visible === false && status == 'declined') {
      setrejectAlert({
        visible: true,
        message: 'Please provide reason for declining this offer.'
      });
      return;
    } else {
      const token = localStorage.getItem('token');
      var myHeaders = new Headers();
      myHeaders.append('Authorization', 'Bearer ' + token);

      var formdata = new FormData();

      formdata.append('id', details.id);
      formdata.append('status', status);
      status == 'declined' && reason.length > 0 && formdata.append('comment', reason);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      status == 'active' && setapproveloading(true);
      status == 'declined' && setrejectLoading(true);
      fetch(api.postApprove, requestOptions)
        .then((response) => response.text())
        .then((result_) => {
          console.log(result_);
          const result = JSON.parse(result_);
          if (result.success === true) {
            refresh();
          }
        })
        .catch((error) => console.log('error', error))
        .finally(() => {
          setapproveloading(false);
          setrejectLoading(false);
        });
    }
  }

  const [timelineLoading, settimelineLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [logs, setlogs] = useState([]);
  function getTimeline() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('event_type', 'post');
    formdata.append('entity_id', details?.id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_event_logs, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setlogs(result.data);
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        settimelineLoading(false);
      });
  }

  return (
    <Box>
      <ImgsViewer
        imgs={imagesMultiple}
        currImg={index}
        onClickPrev={gotoPrevious}
        onClickNext={gotoNext}
        isOpen={fullImage}
        onClose={() => setfullImage(false)}
      />
      <Box
        p={2}
        style={{
          borderBottom: '2px solid',
          borderBottomColor: palette.primary.main,
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box px={2}>
          <Typography
            row
            sx={{ fontSize: 16, fontWeight: 'bold', textTransform: 'capitalize' }}
            component="div"
          >
            {details.title}
          </Typography>
        </Box>
        <Box px={2} style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap' }}>
          <Label
            style={{ height: 30, marginRight: 5 }}
            variant="ghost"
            color={
              (details?.status === 'active' && 'success') ||
              (details?.status === 'pending' && 'primary') ||
              'error'
            }
          >
            {details.status === 'active'
              ? 'Approved'
              : details.status === 'pending'
              ? 'Pending'
              : details.status === 'declined'
              ? 'Declined'
              : 'Expired'}
          </Label>
          {details.status === 'pending' && (
            <>
              {permission.includes('decline') && (
                <LoadingButton
                  variant="contained"
                  size="small"
                  style={{ width: 40, marginRight: 5 }}
                  component={RouterLink}
                  color="error"
                  to="#"
                  onClick={() => postApprove('declined')}
                  loading={rejectLoading}
                >
                  Decline
                </LoadingButton>
              )}

              {permission.includes('approve') && (
                <LoadingButton
                  style={{ width: 40, marginRight: 5 }}
                  color="primary"
                  size="small"
                  variant="contained"
                  component={RouterLink}
                  to="#"
                  onClick={() => postApprove('active')}
                  loading={approveloading}
                >
                  Approve
                </LoadingButton>
              )}
            </>
          )}
          {details.status === 'active' && (
            <>
              {permission.includes('decline') && (
                <LoadingButton
                  variant="contained"
                  size="small"
                  style={{ width: 40, marginRight: 5 }}
                  component={RouterLink}
                  color="error"
                  to="#"
                  onClick={() => postApprove('declined')}
                  loading={rejectLoading}
                >
                  Decline
                </LoadingButton>
              )}
            </>
          )}
          <Button color="primary" variant="contained" onClick={handleClose} size={'small'}>
            Close
          </Button>
        </Box>
      </Box>
      {rejectAlert.visible && (
        <Box px={4} py={2}>
          <Alert
            action={
              <>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setrejectAlert({ visible: false, message: '' })}
                >
                  CANCEL
                </Button>
              </>
            }
            severity="primary"
          >
            {rejectAlert.message}
          </Alert>
          <Box>
            <TextField
              style={{ width: '100%', marginTop: 10 }}
              id="outlined-multiline-flexible"
              label="Comments"
              multiline
              color="primary"
              maxRows={4}
              value={reason}
              onChange={(e) => setreason(e.target.value)}
            />
          </Box>
        </Box>
      )}
      <Box p={2} display="flex" flexDirection="row" justifyContent="start">
        <Button
          style={{
            width: 135,
            marginRight: 5,
            color: 'black',
            backgroundColor: value == 0 ? palette.primary.main : 'silver'
          }}
          color="primary"
          variant="contained"
          onClick={() => {
            setValue(0);
          }}
        >
          View I Want
        </Button>
        <Button
          style={{
            width: 135,
            color: 'black',
            backgroundColor: value == 1 ? palette.primary.main : 'silver'
          }}
          color="primary"
          variant="contained"
          onClick={() => {
            setValue(1);
            settimelineLoading(true);
            getTimeline();
          }}
        >
          Timeline
        </Button>
      </Box>

      {value == 0 && (
        <>
          {details?.images?.length > 0 && (
            <Box my={2}>
              <ImageList
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
                cols={5}
                rowHeight={120}
              >
                {details.images.map((item, index) => (
                  <ImageListItem
                    style={{
                      borderRadius: 15,
                      overflow: 'hidden',
                      border: '2px solid',
                      borderColor: palette.primary.main,
                      marginRight: 5
                    }}
                    onClick={() => settingFullImage(index)}
                    key={item.index}
                  >
                    <img
                      style={{ width: 100 }}
                      src={item.post_image}
                      alt={'Business Image'}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          )}
          <Box p={4} align="center">
            <Box py={1} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                Owner:
              </Typography>
              <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                {user?.firstname + ' ' + user?.lastname}
              </Typography>
            </Box>
            <Divider />

            <Box py={1} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                Title:
              </Typography>
              <Typography
                row
                sx={{ fontSize: 16, fontWeight: 'bold', textTransform: 'capitalize' }}
                component="div"
              >
                {details.title}
              </Typography>
            </Box>
            <Divider />
            <Box py={1} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                Urgent:
              </Typography>
              <Label
                color={details.is_urgent == '0' ? 'error' : 'success'}
                key={index}
                style={{
                  height: 31,
                  marginTop: 2,
                  marginRight: 5,
                  borderWidth: 3
                }}
                variant="ghost"
              >
                {details.is_urgent == '0' ? 'No' : 'Yes'}
              </Label>
            </Box>
            <Divider />

            {details.tags !== '' && (
              <Box py={1} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                  Tags:
                </Typography>
                {tags.map((item, index) => {
                  return (
                    <Label
                      key={index}
                      style={{
                        height: 31,
                        marginTop: 2,
                        marginRight: 5,
                        backgroundColor: '#FDD96E',
                        borderWidth: 3,
                        color: 'black'
                      }}
                      variant="ghost"
                    >
                      {item}
                    </Label>
                  );
                })}
              </Box>
            )}
            {details.address.length > 0 && (
              <>
                <Divider />
                <Box py={1} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                    City / States:
                  </Typography>
                  {details.address.map((item, index) => {
                    return (
                      <Typography>
                        {item.name} {index == details.address.length - 1 ? '' : ','} &nbsp;
                      </Typography>
                    );
                  })}
                </Box>
              </>
            )}
            {details.description !== '' && (
              <>
                <Divider />
                <Box py={1} style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography pr={1} sx={{ fontSize: 16 }} component="div">
                    Description:
                  </Typography>
                  <Typography row sx={{ fontSize: 16, fontWeight: 'bold' }} component="div">
                    {details.description}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </>
      )}

      {value == 1 && (
        <>
          {timelineLoading ? (
            <Box
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                height: 250
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              py={2}
              px={10}
              sx={{
                height: 400,
                overflow: 'auto'
              }}
            >
              {logs?.map((item, index) => {
                return (
                  <Box py={0.5} flexDirection={'row'} display={'flex'}>
                    <Box width={'30%'} mr={3}>
                      <Typography fontSize={14} component={'div'}>
                        {' '}
                        {moment(item.created_at).format('DD/MM/YYYY')}
                      </Typography>
                    </Box>
                    <Box width={'70%'}>
                      <Highlighter
                        highlightStyle={{
                          backgroundColor: 'transparent',
                          fontWeight: 'bold'
                        }}
                        searchWords={[
                          'approved',
                          'pending',
                          'expired',
                          'declined',
                          'urgent',
                          'updated',
                          'seen',
                          'created'
                        ]}
                        autoEscape={true}
                        textToHighlight={item.comments}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
        </>
      )}

      {/* <Box px={4} pb={2} display="flex" justifyContent="flex-end" flexDirection="row">
        <Button style={{}} color="primary" variant="contained" onClick={handleClose}>
          Done
        </Button>
      </Box> */}
    </Box>
  );
}
