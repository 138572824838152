import { filter } from 'lodash';
import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import StatusDropDown from 'src/layouts/dashboard/StatusDropDown';
import OrderDetails from 'src/sections/@dashboard/products/PropertyDetails';
import PostDescription from 'src/sections/@dashboard/posts/PostDescription';
import { UserModal } from '../sections/@dashboard/user';
import { LoadingButton } from '@mui/lab';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Link,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';
import pallete from '../theme/palette';
import format from 'date-fns/format';
import { ReplyModal } from '../sections/@dashboard/request';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
  UserPassword
} from '../sections/@dashboard/user';
//
import { RejectOfferModal } from '../sections/@dashboard/products';
import ORDERLIST from '../_mocks_/user';
import { api } from '../Api';
import OfferDescription from '../sections/@dashboard/products/offerDescription';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'businessowner', label: 'Inbox', alignRight: false }

  // { id: 'description', label: 'description', alignRight: false },
  //   { id: 'action', label: 'Status', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,

      (_user) => _user.requesttype.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function FormatDate(date) {
  const myArray = date.split(' ');

  const formatedDate = format(Date.parse(myArray[0]), 'MM-dd-yyyy');
  return formatedDate;
}

export default function Reports() {
  const [permission, setpermission] = useState([]);
  const [businesspermission, setbusinesspermission] = useState([]);
  const [offerpermission, setofferpermission] = useState([]);
  const [postpermission, setpostpermission] = useState([]);
  const [documentPermission, setdocumentPermission] = useState([]);
  const requirement = ['visible', 'approve', 'decline'];
  const requirementreport = ['visible', 'reply'];
  const postrequirement = ['visible', 'approve', 'decline'];
  const documentrequirement = ['visible', 'upload'];

  function checkRender() {
    // BUSINESS AND OFFER PERMISSION
    let check = 'businesses';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setbusinesspermission((permissions) => [
            ...permissions,
            items.permission_type_name.toLowerCase()
          ])
        : false
    );
    let checkOffer = 'offers';
    const OfferPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == checkOffer
    );
    OfferPerm[0]?.permission_types.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setofferpermission((offerpermission) => [
            ...offerpermission,
            items.permission_type_name.toLowerCase()
          ])
        : false
    );

    // REPORT PERMISSION
    let checkreport = 'reports';
    const reportPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == checkreport
    );
    reportPerm[0]?.permission_types?.filter((items) =>
      requirementreport.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
    // I WANT PERMISSION
    let checkpost = 'i want';
    const postPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == checkpost
    );
    postPerm[0]?.permission_types?.filter((items) =>
      postrequirement.includes(items.permission_type_name.toLowerCase())
        ? setpostpermission((permissions) => [
            ...permissions,
            items.permission_type_name.toLowerCase()
          ])
        : false
    );
    //DOCUMENT PERMISSION
    let documentcheck = 'documents';
    const documentPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name.toLowerCase() == documentcheck
    );
    documentPerm[0]?.permission_types?.filter((items) =>
      documentrequirement.includes(items.permission_type_name.toLowerCase())
        ? setdocumentPermission((permissions) => [
            ...permissions,
            items.permission_type_name.toLowerCase()
          ])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);

  //  function checkRender() {}

  const [list, setlist] = useState([]);
  const [count, setcount] = useState(null);
  const [all, setall] = useState(true);
  const [business, setbusiness] = useState(false);
  const [profile, setprofile] = useState(false);
  const [offer, setoffer] = useState(false);
  const [iwant, setiwant] = useState(false);
  const [iwantpicture, setiwantpicture] = useState(false);
  const [businesspicture, setbusinesspicture] = useState(false);
  const [profilepicture, setprofilepicture] = useState(false);

  function getReports() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    search.length > 0 && formdata.append('search', search);
    status.length > 0 && status != 'All' && formdata.append('sort', status);

    if (!all) {
      business && formdata.append('business', 'business');
      profile && formdata.append('profile', 'profile');
      iwant && formdata.append('post', 'post');
      offer && formdata.append('offer', 'offer');
      profilepicture && formdata.append('profilepicture', 'profilepicture');
      iwantpicture && formdata.append('postpicture', 'postpicture');
      businesspicture && formdata.append('businesspicture', 'businesspicture');
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.getRequests, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        setcount(result.counts);
        if (result.success === true) {
          setlist(result.data);
        } else {
          setlist([]);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setsearchLoading(false);
      });
  }

  useEffect(() => {
    getReports();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isUserNotFound = list.length === 0;

  const style = {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  function MarkAsRead(id) {
    const token = localStorage.getItem('token');

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    formdata.append('id', id);
    formdata.append('status', 'read');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.markasread, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let response = JSON.parse(result);
        if (response.success === true) {
          getReports();
        }
      })
      .catch((error) => console.log('error', error));
  }

  const [isreply, setisreply] = useState(false);
  const [user, setuser] = useState(null);

  function OpenReply(row) {
    setuser(row);
    setisreply(true);
  }

  function CloseReply() {
    setisreply(false);
  }

  function CloseAndRefresh() {
    setisreply(false);
    getReports();
  }

  const [search, setsearch] = useState('');
  const [status, setstatus] = useState('');
  const [searchLoading, setsearchLoading] = useState(false);

  const Buttonstyle = {
    width: '23%',
    height: 50,
    marginTop: 10,
    color: 'black',
    boxShadow: 'none',
    fontSize: 15,
    marginRight: 10
  };

  function filterSetter(type) {
    if (type == 'all offers') {
      setall(!all);
      setprofile(false);
      setbusiness(false);
      setoffer(false);
      setprofilepicture(false);
      setiwant(false);
      setiwantpicture(false);
      setbusinesspicture(false);
    } else {
      setall(false);
    }
  }

  const [businessmodal, setbusinessmodal] = useState(false);
  const [offermodal, setoffermodal] = useState(false);
  const [postmodal, setpostmodal] = useState(false);
  const [usermodal, setusermodal] = useState(false);
  const [picturemodal, setpicturemodal] = useState(false);

  function closeBusiness() {
    setbusinessmodal(false);
  }

  function closeOffer() {
    setoffermodal(false);
  }

  function closePost() {
    setpostmodal(false);
  }

  function closeUser() {
    setusermodal(false);
  }
  function closePicture() {
    setpicturemodal(false);
  }

  const refresh = () => {
    closeBusiness();
    closeOffer();
    closePost();
    closeUser();
    closePicture();
    getReports();
  };

  const [image, setimage] = useState(null);

  const [modalData, setmodalData] = useState(null);
  const [deleteLoading, setdeleteLoading] = useState(false);

  function DeletePicture() {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('inkid', image.id);
    formdata.append('requestvalue', image.type);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.delete_report_pictures, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          refresh();
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setdeleteLoading(false);
      });
  }

  return (
    <Page title={`Reports | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* BUSINESS MODAL */}

        <Modal style={style} open={businessmodal} onClose={closeBusiness}>
          <Box sx={{ ...boxStyle, p: 0, maxWidth: '60%', minWidth: '50%', minHeight: '80%' }}>
            <OrderDetails
              handleClose={closeBusiness}
              details={modalData}
              refresh={refresh}
              closeandOpen={closeBusiness}
              permission={businesspermission}
              offerpermission={offerpermission}
            />
          </Box>
        </Modal>

        {/* OFFER MODAL */}

        <Modal
          style={style}
          open={offermodal}
          onClose={closeOffer}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, p: 0, maxWidth: '60%', minWidth: '50%' }}>
            <OfferDescription
              handleClose={closeOffer}
              details={modalData}
              permission={offerpermission}
              refresh={refresh}
            />
          </Box>
        </Modal>

        {/* Post Modal */}

        <Modal
          style={style}
          open={postmodal}
          onClose={closePost}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, p: 0, maxWidth: '60%', minWidth: '50%' }}>
            <PostDescription
              handleClose={closePost}
              details={modalData}
              permission={postpermission}
              refresh={refresh}
            />
          </Box>
        </Modal>

        {/* User Modal */}

        <Modal
          style={style}
          open={usermodal}
          onClose={closeUser}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, p: 0, maxWidth: '60%', minWidth: '50%' }}>
            <UserModal
              handleClose={(refresh) => {
                refresh && refresh();
                closeUser();
              }}
              details={modalData}
              permission={documentPermission}
              termination={true}
            />
          </Box>
        </Modal>

        {/* Reply Modal */}

        <Modal
          style={style}
          open={isreply}
          onClose={CloseReply}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={boxStyle}>
            <ReplyModal handleClose={CloseReply} user={user} refresh={CloseAndRefresh} />
          </Box>
        </Modal>

        {/* ANY PICTURE MODAL */}

        <Dialog open={picturemodal} onClose={closePicture}>
          <DialogTitle id="alert-dialog-title">{'Picture Review'}</DialogTitle>
          <DialogContent>
            {image && (
              <img
                style={{ width: 600, height: 400, objectFit: 'cover' }}
                src={image?.url}
                alt="image"
              />
            )}
          </DialogContent>
          <DialogActions>
            <LoadingButton
              style={{ backgroundColor: 'red' }}
              size="medium"
              variant="contained"
              loading={deleteLoading}
              onClick={() => {
                setdeleteLoading(true);
                DeletePicture();
              }}
            >
              Delete
            </LoadingButton>

            <Button variant="contained" onClick={closePicture} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Reports
          </Typography>
        </Stack>

        <Box
          pb={2}
          style={{
            flexDirection: 'row',
            display: 'flex',
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
        >
          <Button
            onClick={() => {
              filterSetter('all offers');
              setall(!all);
            }}
            variant="contained"
            component={RouterLink}
            to="#"
            style={{
              ...Buttonstyle,
              backgroundColor: all ? pallete.primary.main : 'silver'
            }}
          >
            All ({count?.count_all_request})
          </Button>
          <Button
            onClick={() => {
              filterSetter('business');
              setbusiness(!business);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: business ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Businesses ({count?.count_business_request})
          </Button>
          <Button
            onClick={() => {
              filterSetter('profile');
              setprofile(!profile);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: profile ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Profiles ({count?.count_profile_request})
          </Button>
          <Button
            onClick={() => {
              filterSetter('iwant');
              setiwant(!iwant);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: iwant ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            I wants ({count?.count_post_request})
          </Button>

          <Button
            onClick={() => {
              filterSetter('delete');
              setoffer(!offer);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: offer ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Offers ({count?.count_offer_request})
          </Button>

          <Button
            onClick={() => {
              filterSetter('profilepicture');
              setprofilepicture(!profilepicture);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: profilepicture ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Profile Picture ({count?.count_profilepicture_request})
          </Button>
          <Button
            onClick={() => {
              filterSetter('iwantpicture');
              setiwantpicture(!iwantpicture);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: iwantpicture ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            I Want Picture ({count?.count_postpicture_request})
          </Button>
          <Button
            onClick={() => {
              filterSetter('businesspicture');
              setbusinesspicture(!businesspicture);
            }}
            variant="contained"
            style={{
              ...Buttonstyle,
              backgroundColor: businesspicture ? pallete.primary.main : 'silver'
            }}
            component={RouterLink}
            to="#"
          >
            Business Picture ({count?.count_businesspicture_request})
          </Button>
        </Box>
        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between'
            }}
          >
            <Searchbar
              width={'50%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
            />
            <StatusDropDown
              width={'30%'}
              setFunction={(val) => {
                setstatus(val);
              }}
              data={[
                { id: 1, name: 'All' },
                { id: 2, name: 'Most Recent' },
                { id: 3, name: 'Most Reported' }
              ]}
            />
            <LoadingButton
              endIcon={<Iconify icon="material-symbols:search" />}
              style={{ width: '17%' }}
              variant="contained"
              loading={searchLoading}
              onClick={() => {
                setsearchLoading(true);
                getReports();
              }}
            >
              Search
            </LoadingButton>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, paddingTop: '20px' }}>
              <Divider />
              <Table>
                <TableBody>
                  {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      id,
                      status,
                      user,
                      requestvalue,
                      description,
                      attachment,
                      inkid,
                      ink_id,
                      reported_count
                    } = row;
                    const name = row.offer_name;

                    return (
                      <TableRow
                        onClick={() => {
                          status != 'read' && MarkAsRead(id);
                          setmodalData(inkid);
                          requestvalue == 'business' &&
                            businesspermission.includes('visible') &&
                            setbusinessmodal(true);
                          requestvalue == 'offer' &&
                            offerpermission.includes('visible') &&
                            setoffermodal(true);
                          requestvalue == 'post' &&
                            postpermission.includes('visible') &&
                            setpostmodal(true);
                          requestvalue == 'profile' && setusermodal(true);

                          if (requestvalue == 'postpicture' && postpermission.includes('visible')) {
                            let img = inkid?.images?.filter((item) => item.id.toString() == ink_id);
                            setimage({
                              id: ink_id,
                              url: img[0].post_image,
                              type: requestvalue
                            });
                            setpicturemodal(true);
                          }
                          if (requestvalue == 'profilepicture') {
                            setimage({
                              id: ink_id,
                              url: inkid.avatar,
                              type: requestvalue
                            });
                            setpicturemodal(true);
                          }
                          if (
                            requestvalue == 'businesspicture' &&
                            businesspermission.includes('visible')
                          ) {
                            let img = inkid?.BusinessImages?.filter(
                              (item) => item.id.toString() == ink_id
                            );
                            setimage({
                              id: ink_id,
                              url: img[0].image,
                              type: requestvalue
                            });
                            setpicturemodal(true);
                          }
                        }}
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        style={{
                          backgroundColor: status == 'active' && 'rgba(253,208,151,0.2)',
                          cursor: status == '0' ? 'pointer' : 'default'
                        }}
                      >
                        <TableCell
                          style={{ width: '100%' }}
                          component="th"
                          scope="row"
                          padding="normal"
                        >
                          <Stack direction="row" spacing={2} style={{ width: '100%' }}>
                            <Avatar alt={name} src={user?.avatar} />
                            <Box style={{ width: '100%' }}>
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                  }}
                                >
                                  {status !== 'read' && (
                                    <Iconify
                                      icon="akar-icons:circle-fill"
                                      style={{
                                        paddingRight: 5,
                                        fontSize: 20,
                                        color: pallete.primary.main
                                      }}
                                    />
                                  )}
                                  <Typography
                                    variant="heading1"
                                    noWrap
                                    style={{
                                      fontSize: 15,
                                      fontWeight: 'bold',
                                      textTransform: 'capitalize'
                                    }}
                                  >
                                    {(requestvalue == 'businesspicture' ||
                                      requestvalue == 'business' ||
                                      requestvalue == 'offer') &&
                                      `${inkid.user.firstname}  ${inkid.user.lastname}'s`}
                                    {(requestvalue == 'postpicture' || requestvalue == 'post') &&
                                      `${inkid.user[0].firstname}  ${inkid.user[0].lastname}'s`}
                                    {(requestvalue == 'profile' ||
                                      requestvalue == 'profilepicture') &&
                                      `${inkid.firstname}  ${inkid.lastname}'s`}
                                  </Typography>
                                  &nbsp;
                                  <Typography
                                    variant="heading1"
                                    noWrap
                                    style={{
                                      fontSize: 15,
                                      fontWeight: 'bold',
                                      textTransform: 'capitalize'
                                    }}
                                  >
                                    {requestvalue == 'profilepicture'
                                      ? 'Profile picture'
                                      : requestvalue == 'businesspicture'
                                      ? 'Business picture'
                                      : requestvalue == 'postpicture'
                                      ? 'I want picture'
                                      : requestvalue == 'post'
                                      ? 'I want '
                                      : requestvalue}
                                  </Typography>
                                </Box>
                                <Typography variant="subtitle2" noWrap style={{ paddingRight: 10 }}>
                                  {format(new Date(row.created_at), 'MM/dd/yyyy hh:mm aaa')}
                                </Typography>
                              </Box>

                              <Box
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  style={{
                                    fontSize: 15,
                                    textAlign: 'justify'
                                  }}
                                >
                                  Reported by: &nbsp;
                                </Typography>

                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  style={{
                                    textTransform: 'capitalize',
                                    fontSize: 15,
                                    textAlign: 'justify',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  {user.firstname} {user.lastname}
                                </Typography>
                              </Box>

                              <Box
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start'
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  style={{
                                    fontSize: 15,
                                    textAlign: 'justify'
                                  }}
                                >
                                  Total reports: &nbsp;
                                </Typography>

                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  style={{
                                    textTransform: 'capitalize',
                                    fontSize: 15,
                                    textAlign: 'justify',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  {reported_count}
                                </Typography>
                              </Box>

                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  wrap="true"
                                  style={{ width: '85%', textAlign: 'justify' }}
                                >
                                  {description}
                                </Typography>
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    alignSelf: 'flex-end'
                                  }}
                                >
                                  {attachment && (
                                    <Link underline="hover" onClick={() => window.open(attachment)}>
                                      <Iconify
                                        icon="gg:attachment"
                                        style={{
                                          marginRight: 10,
                                          fontSize: 25,
                                          color: status !== 'read' ? pallete.primary.main : 'grey'
                                        }}
                                      />
                                    </Link>
                                  )}
                                  {permission.includes('reply') && (
                                    <Button
                                      onClick={(event) => {
                                        OpenReply(row);
                                        event.stopPropagation();
                                      }}
                                      variant="contained"
                                      size="small"
                                      component={RouterLink}
                                      to="#"
                                    >
                                      Reply
                                    </Button>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
