// import React, { useState, useEffect } from 'react';
// import { TextField, Autocomplete, CircularProgress, Icon } from '@mui/material';
// import { api } from 'src/Api';
// import Iconify from 'src/components/Iconify';

// export default function TypesDropDown({ setFunction, Width, error, dontAppend, margin_ }) {
//   const [type, settype] = useState([]);
//   const [search, setsearch] = useState('');
//   const [loading, setloading] = useState();

//   function gettype() {
//     var formdata = new FormData();
//     if (search.length > 0) {
//       formdata.append('search', search);
//       setloading(true);
//     }
//     var requestOptions = {
//       method: 'POST',
//       body: formdata,
//       redirect: 'follow'
//     };

//     fetch(api.getTypes, requestOptions)
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.success == true) {
//           settype(result.data);
//         }
//       })
//       .catch((error) => console.log('error', error))
//       .finally(() => {
//       });
//   }

//   useEffect(() => {
//     gettype();
//   }, []);

//   return (
//     <Autocomplete
//       onChange={(event, value) => {
//         setFunction(value);
//       }}
//       clearIcon={
//         <Iconify
//           onClick={() => {
//             setFunction(null);
//           }}
//           icon={'material-symbols:close-rounded'}
//           sx={{ color: 'text.disabled', width: 20, height: 20 }}
//         />
//       }
//       disablePortal
//       id="combo-box-demo"
//       options={type?.map((option) => option.name)}
//       noOptionsText="No Type Available"
//       style={{ width: Width, marginTop: margin_ ? 0 : 5 ,  marginRight: margin_ ? 0 : 5}}
//       renderInput={(params) => <TextField {...params} label="Type" />}
//     />
//   );
// }

import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress, Icon } from '@mui/material';
import { api } from 'src/Api';
import Iconify from 'src/components/Iconify';
import { Decryption, Encryption } from 'src/functions/function';

export default function TypesDropDown({
  setFunction,
  Width,
  error,
  dontAppend,
  margin_,
  filter,
  placeholder,
  value
}) {
  const [type, settype] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setloading] = useState();
  const [selectedType, setselectedType] = useState(null);

  function gettype() {
    var formdata = new FormData();
    if (search.length > 0) {
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_KEY));
      setloading(true);
    }
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.getTypes, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          let decryptedData = JSON.parse(Decryption(result.data));
          settype(decryptedData);
          if (value) {
            setselectedType(decryptedData.find((item) => item.id === value));
            //    setselectedType(decryptedData.filter((item) => item.id == value));
          }
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {});
  }

  useEffect(() => {
    gettype();
  }, []);

  return (
    <Autocomplete
      onChange={(event, value) => {
        setFunction(value ? value.id : null);
      }}
      value={selectedType}
      clearIcon={
        <Iconify
          onClick={() => {
            setFunction(null);
          }}
          icon={'material-symbols:close-rounded'}
          sx={{ color: 'text.disabled', width: 20, height: 20 }}
        />
      }
      disablePortal
      id="combo-box-demo"
      options={type} // Pass the whole array of objects as options
      getOptionLabel={(option) => option?.name} // Provide a function to get the label for each option
      getOptionSelected={(option, value) => option.name === value.name} // Compare the name property to determine if an option is selected
      noOptionsText="No Type Available"
      style={{
        width: Width,
        marginTop: margin_ ? 0 : 5,
        marginRight: margin_ ? 0 : 5
      }}
      renderInput={(params) => <TextField {...params} label={placeholder || 'Type'} />}
    />
  );
}
