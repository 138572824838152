// // material
// import { alpha, styled } from '@mui/material/styles';
// import { Card, Typography } from '@mui/material';
// // utils
// import { fShortenNumber } from '../../../utils/formatNumber';
// // component
// import Iconify from '../../../components/Iconify';

// // ----------------------------------------------------------------------

// const RootStyle = styled(Card)(({ theme }) => ({
//   boxShadow: 'none',
//   textAlign: 'center',
//   padding: theme.spacing(5, 0),
//   color: theme.palette.info.darker,
//   backgroundColor: theme.palette.info.lighter
// }));

// const IconWrapperStyle = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(8),
//   height: theme.spacing(8),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(3),
//   color: theme.palette.info.dark,
//   backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
//     theme.palette.info.dark,
//     0.24
//   )} 100%)`
// }));

// // ----------------------------------------------------------------------

// const TOTAL = 1352831;

// export default function AppNewUsers() {
//   return (
//     <RootStyle>
//       <IconWrapperStyle>
//         <Iconify icon="ant-design:apple-filled" width={24} height={24} />
//       </IconWrapperStyle>
//       <Typography variant="h3">{fShortenNumber(TOTAL)}</Typography>
//       <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
//         New Users
//       </Typography>
//     </RootStyle>
//   );
// }

// material
import react, { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { Card, Typography, Box, Divider, Grid } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// component
import Iconify from '../../../components/Iconify';
import { api } from '../../../Api';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppTotalUsers({ total }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="carbon:user-avatar-filled" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.total_realtors)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Users
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="carbon:user-avatar-filled" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.pending_realtors)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Pending
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RootStyle>
          <Box px={10}>
            <IconWrapperStyle>
              <Iconify icon="carbon:user-avatar-filled" width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(total.approved_realtors)}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
              Total Accepted
            </Typography>
          </Box>
        </RootStyle>
      </Grid>
    </Grid>
  );
}
